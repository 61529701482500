import React from "react";
// Customizable Area Start
import Dialog from "@material-ui/core/Dialog";
import { Radio, Input, styled, Box } from "@material-ui/core";
import PaymentModalController, {
  configJSON,
  IPaymentMethod,
} from "./PaymentModalController.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import PromoCodePayment from "../../../blocks/Payments/src/PromoCodePayment.web";
import { loyaltyIcon, deamond, label } from "./assets";
import OtpPage from "../../otp-input-confirmation/src/Otp.web";
import { IOrder } from "../../OrderCreation/src/OrderCreationController.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import lodash from "lodash"
import Cfwallet44 from "../../../blocks/cfwallet44/src/Cfwallet44.web";

const getCurrencyString = (currency: string, amount: number | string) => {
  return `${currency.toUpperCase()} ${amount}`;
};

// Customizable Area End

// Customizable Area Start

// Customizable Area End
export default class PaymentModal extends PaymentModalController {
  // Customizable Area Start
  renderUsablePoints() {
    const { currency, totalAmount, points } = this.state;

    return (
      <Cfwallet44
        navigation={undefined}
        id={""}
        currency={currency}
        totalAmount={totalAmount}
        points={points}
        isRenderUsablePoints={true}
        redeemPointsHandler={() => this.redeemAllPoints()}
      />
    );
  }

  renderAfterRedeem() {
    const { points, totalAmount, currency } = this.state;

    return (
      <Cfwallet44
        navigation={undefined}
        id={""}
        currency={currency}
        totalAmount={totalAmount}
        points={points}
        isRenderUsablePoints={false}
        usePointsHandler={() => this.useAmount()}
      />
    );
  }

  renderSecondPayments() {
    const { fromUnpaid } = this.props;
    const {
      activeTab,
      currency,
      remainBalance,
      customPaymentAmount,
      selectedSecondPaymentMethod,
      paymentMethods
    } = this.state;

    const paymentType = this.getSecondaryPaymentMethod()?.name;

    const amountPayableString = `Amount payable from the ${String(
      paymentType ?? "second payment method"
    ).toLowerCase()} is ${getCurrencyString(
      currency,
      (remainBalance - Number(customPaymentAmount)).toFixed(2)
    )}`;

      let secondPaymentMethods = (
        JSON.parse(JSON.stringify(paymentMethods)) as IPaymentMethod[]
      );

      // second enable payment methods
      const secondEnablePaymentMethods = paymentMethods.map((payment) => payment.name);

      secondPaymentMethods = secondPaymentMethods.map((item) => {
        if (secondEnablePaymentMethods.includes(item.name)) {
          item.enableSecond = true;
          return item;
        } else {
          return item;
        }
      });

      // filter first selected payment method in second payment methods
      secondPaymentMethods = secondPaymentMethods.filter(item => item.id !== this.state.activeTab);

      secondPaymentMethods = secondPaymentMethods.filter((item) => {
        const condition1 = fromUnpaid ? item.name !== "Pay Later" : true;
        return (
          this.getPaymentMethodId(item.name || "") !== activeTab && condition1
        );
      });

    return (
      <div className={"remainingPaymentContainer"} data-test-id="secondPaymentMethods">
        <div className={"payWithOther payWithOtherTitle"}>
          {configJSON.label_selectSecondPaymentMethod}
        </div>
        {secondPaymentMethods.map((item: IPaymentMethod) => {
          const isSelected = selectedSecondPaymentMethod === item.name;
          let classNames = 'otherPayment'
          if (!item.enableSecond)
            classNames += " " + 'disableSecondPayMethod';
          return (
            <div
              className={classNames}
              onClick={() =>
                item.enableSecond && this.onSelectSecondPayments(item.name)
              }
            >
              <Radio
                checked={isSelected}
                icon={<span style={webStyle.icon} />}
                checkedIcon={<span style={webStyle.checkedIcon} />}
              />
              <div className={"payMethodTitle"}>{item.name}</div>
            </div>
          );
        })}

        <div className={"payWithOther payWithamountPayableString"}>
          {amountPayableString}
        </div>
      </div>
    );
  }

  renderAfterUse() {
    const { currency, secondPaymentEnabled, walletBalance, remainBalance } =
      this.state;

    return (
      <div>
        <div className={"receivedAmountContainer"}>
          <div>
            <img src={label} />
          </div>
          <div>
            {`You are using received amount ${currency} ${secondPaymentEnabled ? walletBalance : remainBalance
              }`}
          </div>
        </div>
        {secondPaymentEnabled && this.renderSecondPayments()}
      </div>
    );
  }

  renderRedeem() {
    const { isRedeemAllPoints, isUsed } = this.state;

    if (!isRedeemAllPoints) return this.renderUsablePoints();
    else if (!isUsed) return this.renderAfterRedeem();
    else return this.renderAfterUse();
  }

  isPayButtonEnabled(withWallet?: boolean) {
    const { walletBalance, points, skipRedeemPoints } = this.state;
    if(!lodash.isFinite(Number(this.state.customPaymentAmount))){
      return false;
    }
    if (withWallet && !walletBalance)
      return points > 0 ? !skipRedeemPoints : false;
    return true;
  }

  renderRedeemOrSecondPayment(withWallet: boolean) {
    const {
      points,
      remainBalance,
      walletBalance,
      skipRedeemPoints,
      customPaymentAmount,
    } = this.state;

    if (withWallet && !skipRedeemPoints) {
      if (points > 0) return this.renderBothRedeemAndSecondPayment();
      else return walletBalance > 0 && this.renderAfterUse();
    } else {
      if (
        (!withWallet || walletBalance > 0) &&
        Number(customPaymentAmount) < remainBalance
      ) {
        return this.renderSecondPayments();
      }
    }
  }

  renderBothRedeemAndSecondPayment() {
    const {
      walletBalance,
      customPaymentAmount,
      remainBalance,
      isUsed
    } = this.state;
    const isPaybleMoreThenWallet = Number(remainBalance) < walletBalance;
    const balanceCondition = Number(customPaymentAmount) < remainBalance;
    return (
      <>
        {this.renderRedeem()}
        {(!isPaybleMoreThenWallet || balanceCondition && !isUsed) && this.renderSecondPayments()}
      </>
    )
  }

  renderAmountToPay(withWallet: boolean) {
    const {
      points,
      activeTab,
      remainBalance,
      walletBalance,
      skipRedeemPoints,
      customPaymentAmount,
    } = this.state;

    return (
      (withWallet
        ? walletBalance > 0 || (points > 0 && !skipRedeemPoints)
        : true) && (
        <div>
          <div className={"amountToPayTitle"}>Amount to Pay</div>
          <div className={"totalTextContainer"}>
            {renderBaseOnConditions(
              this.state.isRemainingBalanceLoading,
              <CircularProgress />,
              <Box display={"flex"} flexDirection={"column"}>
            <Input
              data-test-id='input-amount'
              value={customPaymentAmount}
              placeholder='Amount to pay'
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                this.onChangeAmountToPay(
                  activeTab,
                  event.target.value as number,
                  walletBalance,
                  remainBalance
                )
              }
              onKeyPress={(event) =>
                this.onKeyPressInput(
                  event?.key,
                  event?.preventDefault,
                  Boolean(event?.isDefaultPrevented)
                )
              }
              className={this.state.remainBalance > Number(this.state.customPaymentAmount) ?
                "amountTextContainerWithPayAll" :
                 "amountTextContainer"
              }
              startAdornment={<div style={{ marginRight: "12px" }} className={"amountTextContainerSar"}>SAR</div>}
              disableUnderline={true}
            />
            {!lodash.isFinite(Number(this.state.customPaymentAmount)) && <span className="errorMsgAmount">{"Please enter a valid number."}</span>}
              </Box>
            )}
            {this.state.remainBalance >
              Number(this.state.customPaymentAmount) && (
                <div
                  className={"payAllButton"}
                  data-test-id='payAll'
                  onClick={this.handlePayAll}
                >
                  {configJSON.label_payAll}
                </div>
              )}
          </div>
        </div>
      )
    );
  }

  renderCash(withWallet: boolean) {
    const { skipRedeemPoints, points, walletBalance } = this.state;

    const isDisable = !this.isPayButtonEnabled(withWallet);
    const notSufficientBalance =
      withWallet && !walletBalance && (points > 0 ? skipRedeemPoints : true);

    let classNames = 'payBtn' + " ";
    classNames += isDisable ? ' disablePayButton' : "";
    classNames += this.state.loading ? ' disablePayButton' : "";
    classNames += this.state.isRemainingBalanceLoading ? ' disablePayButton' : "";
    classNames += this.state.isPayButtonClicked ? ' disablePayButton' : "";
    return (
      <div className={"amountToPayContainer"}>
        {this.renderAmountToPay(withWallet)}
        {this.renderRedeemOrSecondPayment(withWallet)}
        {notSufficientBalance && configJSON.label_notSufficientBalanceLabel}
        <div
          className={classNames}
          data-test-id='btn-cash-payment'
          onClick={() => !this.state.isPayButtonClicked && !this.state.isRemainingBalanceLoading && !isDisable && !this.state.loading && this.handlePay()}
        >
          {!this.state.loading && !this.state.isPayButtonClicked ? (
            configJSON.label_pay
          ) : (
            <CircularProgress
              style={{ width: 16, height: 16, color: "#204B9C" }}
            />
          )}
        </div>
      </div>
    );
  }

  renderPayLater() {
    const isDisable = !this.isPayButtonEnabled();

    let classNames = 'payBtn' + " ";
    classNames += isDisable ? 'disablePayButton' : "";
    classNames += this.state.loading ? ' disablePayButton' : "";
    classNames += this.state.isRemainingBalanceLoading ? ' disablePayButton' : "";
    classNames += this.state.isPayButtonClicked ? ' disablePayButton' : "";

    return (
      <div className={"amountToPayContainer"}>
        <div
          className={classNames}
          data-test-id='btn-pay-later'
          onClick={() => !this.state.isPayButtonClicked && !this.state.isRemainingBalanceLoading && !isDisable && !this.state.loading && this.handlePay()}
        >
          {!this.state.loading && !this.state.isPayButtonClicked ? configJSON.label_pay : <CircularProgress style={{ width: 16, height: 16, color: "#204B9C" }} />}
        </div>
      </div>
    );
  }

  renderSideBar() {
    const { fromUnpaid, order, isMainPayment } = this.props || {};
    const { activeTab, paymentMethods, currency, walletBalance } =
      this.state || {};

    const isQuickDrop = Boolean(order?.is_quick_drop);

    const tabs =
    paymentMethods?.filter((paymentMethod) => {
      const condition1 = ["Payment Link", "Pay Later"].includes(paymentMethod.name);
      const condition2 = Boolean(fromUnpaid) && !isMainPayment
        ? paymentMethod.name != "Pay Later"
        : true;
      return isQuickDrop ? condition1 : condition2;
    }) || [];

    return (
      <div className={"sideBarContainer"}>
        {tabs.map((item) => {
          const { name, id } = item;
          const icon = item.attributes?.icon?.data?.attributes?.image;
          const isActive = id === activeTab;

          const currencyString = `(${getCurrencyString(
            currency,
            walletBalance
          )})`;

          const walletString = name === "Wallet" ? currencyString : "";

          let classNames = 'sideBarItemContainer' + " ";
          if (isActive) classNames += 'activeSideBarItem';

          return (
            <div
              className={classNames}
              key={"sidebar-payment-tabs-item" + name}
              data-test-id={"payment-section-" + name}
              onClick={() => this.onChangeTab(id)}
            >
              <img className={"sideBarIconContainer"} src={icon}/>
              <div className={"sideBarItemText"}>
                {`${name} ${walletString}`}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  displayRemainingPayableAmountForUnpaidOrer = (order: IOrder | undefined, remainBalance: number) => {
    return order ? `SAR ${remainBalance}` : ""
  }

  renderBasedOnPaymentMethod = (activeTabTitle: string) => {
    if(activeTabTitle === "Wallet") {
      return this.renderCash(true);
    } else if(activeTabTitle === "Pay Later") {
      return this.renderPayLater();
    } else {
      return this.renderCash(false);
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      open,
      order,
      totalItems,
      taxPercentage,
      paymentClicked,
    } = this.props || {};

    const {
      currency,
      errorMessage,
      remainBalance,
      successMessage,
      errorSnackbarOpen,
      paymentMethods
    } = this.state || {};
   
    const activeTabItem = paymentMethods?.find(
      (item) => item.id === this.state.activeTab
    );
    const activeTabTitle = activeTabItem ? activeTabItem.name : "";
    // Customizable Area End
    return (
      // Customizable Area Start
      <Dialog
        maxWidth='xl'
        open={open}
        data-test-id='paymentModal'
        PaperProps={{
          style: {
            borderRadius: 12, 
          },
        }}
      >
        <StyledWrapper>
          <div className={"paymentModalMainContainer"}>
            <div className={"headerContainer"}>
              {configJSON.label_paymentMethod}
            </div>
            <div className={"mainContentContainer"}>
              <div className={"detailSectionContainer"}>
                { paymentMethods.length === 0 &&
                  <div className={"noPaymentMethod"}>
                     No payment found
                  </div>
                }
                { paymentMethods.length > 0 &&
                <div className={"detailSectionInnerContainer"}>
                  {this.renderSideBar()}
                  {this.state.showOtpScreen ? (
                    <OtpPage
                      onSuccess={this.onOtpSuccess}
                      show={this.state.showOtpScreen}
                      customer_id={this.props.customerId}
                      totalAmount={this.state.remainBalance + ""}
                      onError={this.handleOtpError}
                    />
                  ) : (
                    <>
                      {this.renderBasedOnPaymentMethod(activeTabTitle)}
                    </>
                  )}
                </div>
                }
              </div>
              <div className={"billingPartMainContainer"}>
                <div className={"billingSectionContainer"}>
                  <PromoCodePayment
                    order={this.props.order}
                    onApply={this.props.onPromoCodeApply}
                    cleaningOrder={this.props.cleaningOrder}
                  />
                </div>
                <div className={"billingSectionContainer"}>
                  <div className={"billingTitle"}>Billing</div>
                  <div className={"billingDetailItemContainer"}>
                    <div>{configJSON.label_totalItems}</div>
                    <div>{totalItems}</div>
                  </div>
                  <div className={"billingDetailItemContainer"}>
                    <div>{configJSON.label_subtotal}</div>
                    <div>{order && `SAR ${order?.sub_total}`}</div>
                  </div>
                  {order && Number(order?.applied_discount) > 0 && (
                    <div className={"billingDetailItemContainer"}>
                      <div>{configJSON.label_promoCodeDiscount}</div>
                      <div data-test-id='applied-discount'>
                        {`- ${getCurrencyString(
                          currency,
                          order?.applied_discount
                        )}`}
                      </div>
                    </div>
                  )}
                  {order?.service_charge && (
                    <div className={"billingDetailItemContainer"}>
                      <div>
                        {configJSON.label_service_charge}
                      </div>
                      <div>{order ? `SAR ${order?.service_charge}` : ""}</div>
                    </div>
                  )}
                  {  Number(remainBalance) > 0 && (Number(remainBalance) !== Number(order?.total))  && (
                    <div className={"billingDetailItemContainer"}>
                      <div>
                        {configJSON.remaining_payable_amount}
                      </div> &nbsp;&nbsp;
                      <div>{this.displayRemainingPayableAmountForUnpaidOrer(order, remainBalance)}</div>
                    </div>
                  )}
                  <div className={"billingDetailItemContainer"}>
                    <div>
                      {configJSON.label_tax} ({taxPercentage})
                    </div>
                    <div>{order ? `SAR ${order?.total_tax}` : ""}</div>
                  </div>
                  <div className={"billingDetailSeperator"} />
                  <div className={"billingSubTotalContainer"}>
                    <div>{configJSON.label_total}</div>
                    <div>
                      {remainBalance ? `SAR ${remainBalance}` : "SAR 0.00"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => !this.state.loading && this.handleClose()}
              className={"modalFooterContainer"}
            >
              {configJSON.label_backToModify}
            </div>
          </div>
          <CustomSnackbar
            severity='error'
            open={errorSnackbarOpen}
            errorMessage={errorMessage}
            data-test-id='customErrorSnackbar'
            onClose={this.handleSnackbarClose}
          />
          {paymentClicked && (
            <CustomSnackbar
              open={!!successMessage}
              errorMessage={successMessage}
              data-test-id='successSnackBar'
              onClose={this.handleSuccessSnackbarClose}
              severity='success'
            />
          )}
        </StyledWrapper>
      </Dialog>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  icon: {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: "50%",
    border: "1px solid #64748B",
    marginRight: "10px",
  },
  checkedIcon: {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: "50%",
    border: "6px solid #204B9C",
    color: "#204b9c",
    marginRight: "10px",
  },
}
const StyledWrapper = styled('div')({
  "& .errorMsgAmount":{
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px",
    color: "#FF0000"
  },
  "& .paymentModalMainContainer": {
    padding: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .headerContainer": {
    color: "#1A1A1A",
    fontSize: 24,
    fontFamily: "Montserrat",
    fontWeight: 600,
    marginBottom: 32,
  },
  "& .mainContentContainer": {
    display: "flex",
    flex: 1,
  },
  "& .modalFooterContainer": {
    color: "#4D4D4D",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 600,
    marginTop: 32,
    cursor: "pointer",
  },
  "& .detailSectionContainer": {
    width: 703,
    marginRight: 24,
    borderRadius: 12,
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    padding: 24,
  },
  "& .noPaymentMethod": {
    display: "flex",
    alignItems: "center", 
    justifyContent: "center",
    height: "100%"
  },
  "& .billingPartMainContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .billingSectionContainer": {
    padding: 24,
    background: "#FFF",
    borderRadius: 12,
    width: "100%",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  },
  "& .detailSectionInnerContainer": {
    display: "flex",
    borderRadius: 12,
    border: "1px solid #ECECEC",
    background: "#FFF",
  },
  "& .sideBarContainer": {
    width: 294,
    borderRight: "1px solid #ECECEC",
  },
  "& .sideBarItemContainer": {
    padding: 24,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#000",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ECECEC",
    },
  },
  "& .activeSideBarItem": {
    backgroundColor: "rgba(32, 75, 156, 0.1)",
    color: "#204B9C",
    borderTopLeftRadius: 8,
  },
  "& .sideBarIconContainer": {
    marginRight: 20,
    height: 24,
    width: 24
  },
  "& .sideBarItemText": {
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  "& .amountToPayContainer": {
    width: 361,
    padding: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .amountToPayTitle": {
    color: "#000",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 600,
    marginBottom: 12,
  },
  "& .payAllButton": {
    background: "#F1F5F9",
    color: "#204B9C",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    padding: "10px",
    width: "119px",
    height: "48px",
    textAlign: "center",
    paddingTop: "14px",
    borderRadius: "8px",
    cursor: "pointer",
  },
  "& .payBtn": {
    display: "flex",
    padding: 16,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFF",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 700,
    cursor: "pointer",
    borderRadius: 8,
    background: "#204B9C",
    marginTop: 32,
  },
  "& .redeemBtn": {
    display: "flex",
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
    color: "#204B9C",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 600,
    cursor: "pointer",
    borderRadius: 8,
    background: "#FFF",
  },
  "& .billingTitle": {
    color: "#1A1A1A",
    fontSize: 20,
    fontFamily: "Montserrat",
    fontWeight: 600,
    marginBottom: 24,
  },
  "& .billingDetailItemContainer": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: "#1A1A1A",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 400,
    marginBottom: 16,
  },
  "& .billingDetailSeperator": {
    width: "100%",
    height: 1,
    background: "#ECECEC",
    marginBottom: 24,
    marginTop: 24,
  },
  "& .billingSubTotalContainer": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: "#1A1A1A",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  "& .orangePointsBoxContainer": {
    padding: 16,
    borderRadius: 8,
    background: "#EC4E20",
    color: "white",
  },
  "& .orangePointTitleContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  "& .orangePointText": {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 24,
  },
  "& .orangePointBtn": {
    padding: "10px 16px",
    background: "white",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 600,
    color: "#204B9C",
    width: 110,
    textAlign: "center",
  },
  "& .orangeCongratsBoxContainer": {
    padding: "24px 50px",
    borderRadius: 8,
    background: "#EC4E20",
    color: "white",
    fontSize: 13,
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .orangeCongratsTitle": {
    fontSize: 16,
    fontWeight: 600,
  },
  "& .amountTextContainer": {
    display: "flex",
    width: 235,
    height: 48,
    padding: "12px 20px 12px 10px",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "#4D4D4D",
    borderRadius: 12,
    border: "1px solid #ECECEC",
  },
  "& .amountTextContainerWithPayAll": {
    display: "flex",
    width: 200,
    height: 48,
    padding: "12px 20px 12px 10px",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "#4D4D4D",
    borderRadius: 12,
    border: "1px solid #ECECEC",
  },
  "& .usablePointsContainer": {
    width: "296px",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#EC4E20",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
  },
  "& .flexCenterBetween": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .usablePoints": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
  },
  "& .points": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
  },
  "& .pointsMessage": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: 12,
    marginBottom: 24,
  },
  "& .diamond": {
    width: "92px",
    height: "86px",
    flexShrink: 0,
    opacity: 0.1,
    position: "absolute",
  },
  "& .absolute": {
    position: "absolute",
  },
  "& .afterRedeemContainer": {
    width: "296px",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#EC4E20",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  "& .congrats": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: 8,
  },
  "& .afterRedeemMessagesContainer": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: 500,
    marginBottom: 12,
  },
  "& .iconPointContainer": {
    display: "flex",
    gap: 8,
  },
  "& .pointsRedeemMessage": {
    marginBottom: 12,
  },
  "& .totalAmountContainer": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24,
  },
  "& .receivedAmountContainer": {
    display: "flex",
    gap: 12,
    color: "#204B9C",
    fontSize: 16,
    fontWeight: 600,
  },
  "& .totalTextContainer": {
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
    gap: 14,
  },
  "& .payWithOther": {
    marginTop: 16,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#000",
  },
  "& .otherPayment": {
    cursor: "pointer",
    display: "flex",
    gap: "16px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
    paddingRight: "16px",
  },
  "& .remainingPaymentContainer": {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginTop: 16,
  },
  "& .disablePayButton": {
    backgroundColor: "#ECECEC !important",
    cursor: "not-allowed !important",
  },
  "& .disableSecondPayMethod": {
    opacity: 0.8,
    cursor: "not-allowed",
  },
  "& .promoCodeErrorText": {
    marginBottom:30
   },
   "& .payOptionIcon": {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: "50%",
    border: "1px solid #64748B",
    marginRight: "10px",
  },
  "& .payOptioncheckedIcon": {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: "50%",
    border: "1px solid #64748B",
    marginRight: "10px",
  },
  "& .redeemBtnWrap": {
    display: "flex", 
    justifyContent: 'center'
  },
  "& .promoCode": {
    fontFamily: "Montserrat !important",
    fontSize: "14px",
    fontWeight: 600,
  },
  "& .promoCodeProductName": {
    fontFamily: "Montserrat !important",
    fontSize: "12px",
    fontWeight: 500,
    color: "#4D4D4D",
  },
  "& .verificationBox": {
    padding:'32px',
   },
   

  '@media (max-width: 1200px)': {
    "& .sideBarItemContainer": {
      padding: "16px"
    },
    "& .sideBarItemText": {
      fontSize: 13,
      fontWeight: 500,
    },
    "& .sideBarContainer": {
      width: 165,
    },
    "& .sideBarIconContainer": {
      marginRight: 12,
    },
    "& .amountToPayContainer": {
      width: 320,
      padding:'22px 12px 22px 16px',
    },
    "& .headerContainer": {
      marginBottom: 16,
      fontSize: '19px',
    },
    "& .paymentModalMainContainer": {
      padding: 23,
      width: 900,
    },
    "& .detailSectionContainer": {
      width: '509px',
      padding: '12px',
      marginRight: '16px',
    },
    "& .payWithOther": {
      fontSize:'14px',
    },
    "& .payAllButton": {
      fontSize: 16,
      fontWeight: 600,
      padding: "6px 10px 6px 10px",
      maxWidth: "100px",
      color:"#204B9C",
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    "& .billingSectionContainer": {
      padding: 12,
      width: 331,
    },
    "& .promoCodeCls": {
      fontSize: "16px !important",
    },

    "& .applyPromoCodeCls": {
      fontSize: "13px !important",
      fontFamily: "Montserrat !important",
      fontWeight: 500,
    },
    "& .applyCls": {
      borderRadius: '8px !important',
      maxWidth: '96px !important',
      width: '100% !important',
      marginLeft: '12px !important',
      lineHeight: '20px !important',
      fontWeight: '500 !important',
    },
    "& .billingTitle": {
      fontSize: 16,
      marginBottom: 16,
    },
    "& .billingDetailItemContainer": {
      fontSize: 14,
      marginBottom: 8,
      lineHeight:'22px',
    },
    "& .billingSubTotalContainer": {
      fontSize: 14,
      fontWeight: 700,
    },
    "& .billingDetailSeperator": {
      width: "100%",
      height: 1,
      background: "#ECECEC",
      marginBottom: 16,
      marginTop: 16,
    },
    "& .payWithOtherTitle": {
      fontSize:14,
    },
    "& .remainingPaymentContainer": {
      marginTop: 0,
    },
    "& .payMethodTitle": {
      fontSize:13,
      fontWeight: 500,
    },
    "& .payWithamountPayableString": {
      fontSize:13,
      fontWeight: 500,
    },
    "& .promoCodeErrorText": {
     fontSize:'12 !important',
     fontWeight:'500 !important',
     marginBottom:13
    },
    "& .usablePoints": {
      fontSize:'13 !important',
      fontWeight:'600 !important',
     },
    
     "& .pointsMessage": {
      color: "#FFF",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 400,
    },
    "& .usablePointsContainer": {
      width: "296px",
      flexShrink: 0,
      borderRadius: "8px",
      background: "#EC4E20",
      padding: "16px 30px",
      display: "flex",
      flexDirection: "column",
    },
    "& .redeemBtn": {
      display: "flex",
      padding: '6px 16px',
      justifyContent: "center",
      alignItems: "center",
      color: "#204B9C",
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 600,
      cursor: "pointer",
      borderRadius: 8,
      background: "#FFF",
      width: 'max-content',
    },
    "& .amountTextContainerSar": {
      fontSize: 14,
      fontWeight: 700,
    },
    "& .promoCodeckickBtn": {
      fontSize: 14,
      fontWeight: 600,
    },
    "& .promoCode": {
      fontSize: '13px'
    },
    "& .promoCodeProductName": {
      fontFamily: "Montserrat !important",
      fontSize: "12px",
      fontWeight: 400,
      color: "#4D4D4D",
    },
    "& .PromoSavings": {
      fontFamily: "Montserrat !important",
      fontSize: "12px",
      fontWeight: 400,
      color: "##4D4D4D",
    },
    "& .promoSavingsValue": {
      fontFamily: "Montserrat !important",
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
    },
    "& .promoButton": {
      borderRadius:'6px !important',
      maxWidth:'96px',
      maxHeight:'32px',
    },
    "& .verificationBox": {
      padding:'12px !important',
     },
    
  },
});
// Customizable Area End
