import React from "react";

// Customizable Area Start
import { Box, Typography, Checkbox } from "@material-ui/core";
import {
  backgroundImageLogin,
  PasswordInVisible,
  PasswordVisible,
  logo
} from "./assets";
import {
  BackgroundImage,
  BackgroundPaper,
  AvatarImg,
  DynamicHeading,
  DynamicSubHeading,
} from "../../../components/src/customComponents/DynamicContent.web";
import CustomInput from "../../../components/src/customComponents/CostumInput.web";
import CostomButton from "../../../components/src/customComponents/CostomButton.web";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { styled } from "@material-ui/core/styles";
import CustomComponentLanguage from "../../../components/src/customComponents/CustomComponentLanguage.web";

export const configJSON = require("./config");

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";

export default class EmailAccountLoginPage extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <BackgroundImage imageUrl={backgroundImageLogin}>
        <BackgroundPaper>
          <CustomComponentLanguage navigation={undefined} id={""} />
          <Box display="flex" justifyContent="center" paddingTop="0.2vw" paddingBottom="3vw">
            <img src={logo} width="33%" data-test-id={configJSON.logoTest}/>
          </Box>
          <DynamicHeading headingName={configJSON.heading} />

          <DynamicSubHeading headingName={this.state.dynamicLoginText} />

          <p style={{ ...webStyle.labelheadingTop, ...webStyle.labelheading }}>
            {configJSON.labelheading}
          </p>
          <NewField>
            <CustomInput
              type='text'
              name='employeeID'
              placeholder={configJSON.idPlaceHolder}
              data-test-id={configJSON.employeeIDTestId}
              value={this.state.employeeID}
              onChange={this.handleInputChange}
              onFocus={this.hideError}
              onKeyDown={this.clearRememberMe}
            />
          </NewField>
          <Box style={webStyle.errorMessage}>
            {this.state.errors.employeeID ? (
              <small style={webStyle.errorStyle}>
                {this.state.errors.employeeID}
              </small>
            ) : (
              <small style={{ visibility: "hidden" }}>""</small>
            )}
          </Box>
          <p style={webStyle.labelheading}>{configJSON.placeHolderPassword}</p>
          <NewField>
            <Box style={webStyle.passwordIcon}>
              <CustomInput
                type={this.state.enablePasswordField ? "password" : "text"}
                name='pwd'
                placeholder={this.passwordPlaceholder}
                value={this.state.pwd}
                data-test-id='password'
                onChange={this.handleInputChange}
                onFocus={this.hideError}
                onKeyDown={this.clearRememberMe}
              />
              <Box
                style={{
                  position: "absolute",
                  ...webStyle.togglePasswordImage,
                }}
              >
                {
                  <img
                    data-test-id='passVisible'
                    style={webStyle.logoSwitch}
                    onClick={this.handleClickShowPassword}
                    src={
                      this.state.enablePasswordField
                        ? PasswordInVisible 
                        : PasswordVisible
                    }
                  />
                }
              </Box>
            </Box>
          </NewField>
          <Box style={webStyle.errorMessage}>
            {this.state.errors.pwd ? (
              <small style={webStyle.errorStyle}>{this.state.errors.pwd}</small>
            ) : (
              <small style={{ visibility: "hidden" }}>""</small>
            )}
          </Box>
          <Box style={webStyle.rememberLink}>
            <Box style={webStyle.checkBox}>
              <Checkbox
                checked={this.state.rememberMe}
                onChange={this.handleRememberMe}
                color='primary'
                data-test-id={configJSON.rememberMeTestId}
                style={webStyle.buttonProps}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Typography style={webStyle.rememberme}>
                {configJSON.labelrememberme}
              </Typography>
            </Box>
            <p
              onClick={() => this.handleNavigate("ForgotPassword")}
              data-test-id='forgotPass'
              style={{ ...webStyle.textdec, ...webStyle.forgetPassword,cursor:"pointer" }}
            >
              {configJSON.labelforgetPassword}
            </p>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={4000}
            onClose={this.handleClose}
          >
            <Alert severity='error'>{this.state.snackbarMessage}</Alert>
          </Snackbar>
          <Box style={webStyle.buttonAdd}>
            <CostomButton
              data-test-id={configJSON.loginBtnTestId}
              style={{
                textTransform: "none",
                ...webStyle.buttonStyle,
              }}
              onClick={this.handleSigninSubmit}
            >
              {this.state.loadingSignIn ? (
                <CustomCircularProgress color='primary' />
              ) : (
                configJSON.labellogin
              )}
            </CostomButton>
          </Box>
        </BackgroundPaper>
      </BackgroundImage>
    );
  }
}
const webStyle = {
  labelheading: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "0.972vw",
    marginBottom: "0.256vw",
  },
  buttonProps: {
    fontSize: "small",
    padding: "0",
  },
  buttonStyle: {
    width: "100%",
    height: "3.472vw",
    borderRadius: "0.556vw",
    alignText: "center",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "1.667vw",
    backgroundColor: "#204B9C",
  },
  rememberLink: {
    display: "flex",
    // marginTop: "0.556vw",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rememberme: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1vw",
    marginLeft: "0.3vw",
  },
  forgetPassword: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "0.972vw",
    color: "#EC4E20",
  },
  buttonAdd: {
    display: "flex",
    paddingTop: "1.533vw",
    paddingBottom: "1.533vw",
  },
  checkBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  passwordIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  errorStyle: {
    padding: "5px",
    color: "red",
    fontFamily: "Montserrat",
    fontSize: " 0.833vw",
  },
  logoSwitch: {
    height: "1.667vw",
    width: "1.667vw",
  },
  labelheadingTop: {
    paddingTop: "1.556vw",
  },
  errorMessage: {
    marginTop: "0.2vw",
  },
  labelSecond: {
    paddingTop: "1.667vw",
  },
  togglePasswordImage: {
    paddingRight: "1vw",
  },
  textdec: {
    textDecoration: "none",
  },

  menuItemColor: {
    color: "#ECECEC",
  },
};
const NewField = styled("span")({
  "& input::placeholder": {
    fontSize: "14px",
    fontWeight: 500,
  },

  "@media (max-width: 1200px)": {
    "& input::placeholder": {
      fontSize: "12px",
      fontWeight: 400,
    },
  },
});
const CustomCircularProgress = styled(CircularProgress)({
  width: "34px !important",
  height: "34px !important",

  "@media (max-width: 1200px)": {
    width: "25px !important",
    height: "25px !important",
  },
});
// Customizable Area End
