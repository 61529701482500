Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.putApiMethod = 'PUT'
exports.test = 'test'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.customerSearchEndPoint = "account_block/customers/auto_complete";
exports.bussinessCustomerSearchEndPoint = "bx_block_account_groups/business_account_customers/auto_complete";
exports.searchCustomerApiEndPoint =
  "/account_block/web_customers/search_customer_account";
exports.BussinessSearchCustomerApiEndPoint =
  "/bx_block_account_groups/business_account_customers/search_customer_account"
exports.createNewOrderEndPoint = "/bx_block_order_management/orders/new_order";
exports.bussinessCreateNewOrderEndPoint = "/bx_block_order_management/b2b_orders/new_order_cart";
exports.updateOrderEndPoint = "/bx_block_order_management/orders/"
exports.bussinessUpdateOrderEndPoint = "/bx_block_order_management/b2b_orders/b2b_update"
exports.generateOrderInvoiceEndPoint = "bx_block_order_management/orders/generate_order_invoice?id=";
exports.rawOrderInvoiceData = "bx_block_order_management/orders/generate_row_data_order_invoice?id=";
exports.checkContractAvailablity = "/bx_block_account_groups/business_account_customers/check_contract_availability";
exports.b2bConfirmOrderApi = "/bx_block_order_management/b2b_orders/order_conform";

exports.Strings = {
  customer_phone_already_register: "This phone number already belongs to a customer",
  service: "Service",
  select_service: "Select Service",
  cart_empty_text: "Your Cart is Empty"
}
exports.clearAllText = "Clear All"

exports.errorTitle = 'Error'
exports.save = "Save"
exports.test = "Test"
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.noCustomerFound = "No customers found";
exports.mobileNumber = "Mobile Number";
exports.setLoaderFalse = "setLoaderFalse";
exports.setCustomerPhone = "Customer phone retain";
exports.EditOrderIdPass = "Quick Drop Edit Order";

exports.upchargePriceTest = "upcharge-price-item"
exports.shoppingCartTest = "shopping-cart-test"
// Customizable Area End
