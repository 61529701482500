import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ClickAwayListener,
  Box,
  Radio,
  TextField,
  Typography,
  Button,
  Divider,
  Popover,
  styled,
  InputAdornment,
  Popper,
  Paper,
  ListSubheader,
  CircularProgress,
} from "@material-ui/core";
import { StyledCheckBox } from "./CustomTheme.web";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { searchIcon } from "./commonAssets/assets";

interface StoreGroupData {
  id: number;
  name: string;
}

interface SuggestionData {
  store_group: string[];
  area: string[];
  store: string[];
}

interface AreaStoreData {
  id: number;
  name: string;
  areas: {
    id: number;
    name: string;
    stores: {
      id: string;
      name: string;
    }[];
  }[];
}
interface Props {
  storeGroupData: StoreGroupData[];
  handleSelectGroupOptions: Function;
  handleSelectAreaOptions: Function;
  handleSelectStoreOptions: Function;
  storeGroupPageNo: number
  handleLoadMoreData: Function;
  handleLoadMoreArea: Function;
  handleLoadMoreGroupData: Function
  handleClearAll: Function;
  currentSelectedGroupId: string;
  recordsToShowArea: number;
  selectedGroupName: string;
  currentSelectedAreaId: string[];
  currentSelectedStoreId: string[];
  currentSelectedStoreIdByArea: Record<number, string[]>;
  areaData: {
    id: number;
    name: string;
    stores: {
      id: string;
      type: string;
      attributes: {
        id: number;
        store_name: string;
        name_translation: string;
      };
    }[];
  }[];
  allAreaList: {
    id: number;
    name: string;
    store_managements: {
      data: {
        id: string;
        type: string;
      }[];
    };
  }[];
  recordsToShowByGroup: {
    [groupId: string]: number;
  };
  storeCountByArea: {
    [groupId: string]: number;
  }
  allStoreListData: {
    id: string;
    type: string;
    attributes: {
      id: number;
      store_name: string;
      name_translation: string;
    };
  }[];
  onSearch: Function;
  searchResult: SuggestionData;
  onSelectSuggestion: Function;
  isStoreGroupDropdownLoading: boolean;
  selectedSearchName: string;
  isLoadMoreGroupLoading: boolean
  totalPages: number
}

const StoreGroupDropdown = ({
  storeGroupData,
  handleSelectGroupOptions,
  currentSelectedGroupId,
  areaData,
  handleSelectAreaOptions,
  currentSelectedAreaId,
  handleSelectStoreOptions,
  currentSelectedStoreId,
  onSearch,
  searchResult,
  recordsToShowByGroup,
  allStoreListData,
  handleLoadMoreData,
  currentSelectedStoreIdByArea,
  selectedGroupName,
  handleClearAll,
  handleLoadMoreArea,
  recordsToShowArea,
  allAreaList,
  onSelectSuggestion,
  isStoreGroupDropdownLoading,
  selectedSearchName,
  storeCountByArea,
  handleLoadMoreGroupData,
  storeGroupPageNo,
  isLoadMoreGroupLoading,
  totalPages
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorElSerach, setAnchorElSearch] = useState<HTMLElement | null>(
    null
  );
  const [searchTxt, setSearchTxt] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   
    setSearchTxt(event.target.value);
    if(event.target.value === "") {
      setAnchorElSearch(null);
    }else{
      setAnchorElSearch(event.currentTarget);
    }
    onSearch(event.target.value);
  };

  const handleCloseSuggestionPopover = () => {
    setAnchorElSearch(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "store-group-dropdown" : undefined;

  const convertValue = (value: string): string => {
    const mappings: { [key: string]: string } = {
      store_group: "Groups",
      store: "Stores",
      area: "Areas",
    };
    return mappings[value] || value;
  };

  const fixedCategories: (keyof SuggestionData)[] = ["store_group", "area", "store"];

  const RenderSuggestionDropdown = () => {
    const isEmptyData = Object.values(searchResult).every(
      (options) => options.length === 0
    );
    return (
      <ClickAwayListener onClickAway={handleCloseSuggestionPopover}>
        <StylePopper
          open={Boolean(anchorElSerach)}
          anchorEl={anchorElSerach}
          placement='bottom-start'
          style={{ zIndex: 100 }}
        >
          <Paper>
            <List
              style={{
                maxHeight: "200px",
                overflow: "scroll",
                width: "400px",
                border: "1px solid lightgray"
              }}
            >
              {isEmptyData ? (
                <Typography
                  variant='body1'
                  color='textSecondary'
                  align='center'
                >
                  No data found
                </Typography>
              ) : (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "10px",
                  }}
                >
                  {fixedCategories.map((category) => (
                    <StyledListSubheader
                      key={category}
                      style={{ textAlign: "center" }}
                    >
                      {convertValue(category)}
                    </StyledListSubheader>
                  ))}

                  {Array.from({
                    length: Math.max(
                      ...fixedCategories.map(
                        (cat) => searchResult[cat]?.length || 0
                      )
                    ),
                  }).map((_, index) => (
                    <React.Fragment key={index}>
                      {fixedCategories.map((category) => (
                        <StyledListItem
                          button
                          onClick={() => {
                            const option = searchResult[category]?.[index];
                            if (option) {
                              onSelectSuggestion(option, category);
                              setSearchTxt(option);
                              handleCloseSuggestionPopover();
                            }
                          }}
                          key={`${category}-${index}`}
                        >
                          {searchResult[category]?.[index] || ""}{" "}
                        </StyledListItem>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </List>
          </Paper>
        </StylePopper>
      </ClickAwayListener>
    );
  };

  return (
    <div>
      <StyleButton
        aria-describedby={id}
        variant='outlined'
        onClick={handleClick}
        data-test-id='open_dropdown'
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "capitalize",
          }}
        >
          {currentSelectedGroupId ? selectedGroupName : "No Group Selected"}
        </Typography>
        <div>
          <ArrowDropDownIcon />
        </div>
      </StyleButton>
      <Popover
        id={id}
        open={open}
        style={{ zIndex: 99 }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ width: 500, padding: 10 }}>
          <TextField
            fullWidth
            onChange={handleChange}
            placeholder='Type to Search'
            variant='outlined'
            size='small'
            style={{ marginBottom: 16 }}
            value={searchTxt}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"}>
                  <img src={searchIcon} className='searchicnon' />
                </InputAdornment>
              ),
            }}
          />

          {RenderSuggestionDropdown()}

          {isStoreGroupDropdownLoading ? (
            <StyleLoaderDiv>
              <CircularProgress style={{ width: 40, height: 40 }} />
            </StyleLoaderDiv>
          ) : (
            <Grid container spacing={2}>
              {/* Store Groups Column */}
              <Grid item xs={4}>
                <List style={{ paddingTop: 0 }}>
                  <StyleTypography variant='subtitle1'>
                    Store Groups
                  </StyleTypography>
                  <StyleScrollDiv>
                    {storeGroupData.map((group, index) => (
                      <ListItem
                        key={index}
                        button
                        data-test-id={`store_group_${group.id}`}
                        onClick={() => handleSelectGroupOptions(group)}
                        selected={
                          String(currentSelectedGroupId) === String(group.id)
                        }
                        style={{ padding: 0 }}
                      >
                        <ListItemIcon style={{ minWidth: "40px" }}>
                          <Radio
                            checked={
                              String(currentSelectedGroupId) ===
                              String(group.id)
                            }
                            color='primary'
                          />
                        </ListItemIcon>
                        <StyleListItemText primary={group.name} />
                      </ListItem>
                    ))}
                      {storeGroupPageNo !== totalPages && <Button
                        fullWidth
                        style={{
                          textTransform: "capitalize",
                          marginTop: "15px",
                          width: "100%",
                        }}
                        variant='outlined'
                        onClick={() => handleLoadMoreGroupData(storeGroupPageNo+1)}
                        disabled= {isLoadMoreGroupLoading}
                        data-test-id={`load_more_group`}
                      >
                       {isLoadMoreGroupLoading ? <CircularProgress style={{ width: 20, height: 20 }} />: "Load More"}
                      </Button>}
                  </StyleScrollDiv>
                </List>
              </Grid>
              <Grid item xs={1}>
                <Divider orientation='vertical' variant='middle' />
              </Grid>
              {/* Sub Regions Column */}
              {currentSelectedGroupId ? (
                <Grid item xs={7}>
                  <StyleTypography variant='subtitle1'>
                    Sub Regions &gt; Areas &gt; Stores
                  </StyleTypography>
                  <List style={{ paddingTop: 0, paddingLeft: 0 }}>
                    <ListItem
                      key={-1}
                      button
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        paddingTop: 0,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Box
                          data-test-id={`area_selectall`}
                          onClick={() =>
                            handleSelectAreaOptions({
                              id: "-1",
                              name: "Select All",
                            })
                          }
                        >
                          <ListItemIcon style={{ minWidth: "30px" }}>
                            <StyledCheckBox
                              checked={
                                currentSelectedAreaId.length ===
                                allAreaList.length
                              }
                            />
                          </ListItemIcon>
                        </Box>
                        <StyleListItemText primary={"Select All"} />
                      </div>
                      <StyleClearDiv
                        onClick={() => {
                          setSearchTxt("");
                          handleClearAll()
                        }}
                        data-test-id={"clear_all"}
                      >
                        Clear
                      </StyleClearDiv>
                    </ListItem>
                    <StyleScrollDiv>
                      {areaData.map((area, index) => (
                        <>
                          {/* Area List */}
                          <ListItem
                            style={{
                              paddingTop: 0,
                            }}
                            key={index}
                            button
                            onClick={() => handleSelectAreaOptions(area)}
                            data-test-id={`area_${area.id}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <StyledCheckBox
                                    checked={currentSelectedAreaId.includes(
                                      String(area.id)
                                    )}
                                  />
                                </ListItemIcon>
                                <StyleListItemTextArea primary={area.name} />
                              </div>
                              <div>
                                <ArrowDropDownIcon />
                              </div>
                            </div>
                          </ListItem>
                          {/* Store List */}

                          {currentSelectedAreaId.includes(String(area.id)) && (
                            <>
                              {area.stores.map((store, index) => (
                                <ListItem
                                  style={{
                                    paddingTop: 0,
                                  }}
                                  key={index}
                                  button
                                  data-test-id={`store_${store?.id}`}
                                  onClick={() =>
                                    handleSelectStoreOptions(
                                      store?.attributes,
                                      area.id
                                    )
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "30px",
                                    }}
                                  >
                                    <ListItemIcon style={{ minWidth: "30px" }}>
                                      <StyledCheckBox
                                        checked={
                                          currentSelectedStoreIdByArea[
                                            area.id
                                          ]?.includes(
                                            store.attributes.id as any
                                          ) || false
                                        }
                                      />
                                    </ListItemIcon>
                                    {currentSelectedStoreIdByArea[
                                      area.id
                                    ]?.includes(store.attributes.id as any) ? (
                                      <StyleListItemTextWithSelected
                                        primary={store?.attributes?.store_name}
                                      />
                                    ) : (
                                      <StyleListItemText
                                        primary={store?.attributes?.store_name}
                                      />
                                    )}
                                  </div>
                                </ListItem>
                              ))}
                              {recordsToShowByGroup[area.id] <
                                storeCountByArea[area.id] && (
                                <Button
                                  fullWidth
                                  style={{
                                    textTransform: "capitalize",
                                    marginBottom: "15px",
                                    marginLeft: "45px",
                                    width: "60%",
                                  }}
                                  variant='outlined'
                                  onClick={() => handleLoadMoreData(area.id)}
                                  data-test-id={`load_more_${area.id}`}
                                >
                                  Load More Store
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      ))}
                      {allAreaList.length >= Number(recordsToShowArea) && (
                        <Button
                          fullWidth
                          style={{
                            textTransform: "capitalize",
                            borderColor: "#D7471D",
                            color: "#D7471D",
                          }}
                          variant='outlined'
                          onClick={() => handleLoadMoreArea()}
                          data-test-id={`load_more_area`}
                        >
                          Load More Area
                        </Button>
                      )}
                    </StyleScrollDiv>
                  </List>
                </Grid>
              ) : (
                <Box sx={{ alignContent: "center" }}>
                  <Typography
                    style={{
                      color: "#D7471D",
                      fontSize: "20px",
                      marginLeft: "45px",
                    }}
                  >
                    {" "}
                    No Group Selected
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default StoreGroupDropdown;

const StyleTypography = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  color: "#000000",
  marginBottom: "10px",
});

const StyleListItemText = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#0F172A",
  },
});

const StyleListItemTextArea = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#D7471D",
  },
});

const StyleListItemTextWithSelected = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#204B9C",
  },
});

const StyleClearDiv = styled(Box)({
  color: "#DC2626",
  fontSize: 16,
  fontWeight: 500,
  textDecoration: "underline",
  cursor: "pointer",
});

const StyledListSubheader = styled(ListSubheader)({
  color: "black",
  fontSize: "16px",
  lineHeight: "unset",
  marginTop: "5px",
  marginBottom: "3px",
  position: "unset",
});

const StyledListItem = styled(ListItem)({
  paddingTop: "4px",
  paddingBottom: "4px",
  fontSize: "14px",
});
const StyleButton = styled(Button)({
  display: "flex",
  justifyContent: "space-between",
  width: "275px",
  height: "54px",
  borderRadius: "8px",
  "@media only screen and (max-width: 1024px)": {
    height: "38px",
  },
});

const StyleScrollDiv = styled("div")({
  overflowY: "scroll",
  overflowX: "hidden",
  maxHeight: "275px",

  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },

  scrollbarWidth: "thin",
  scrollbarColor: "#888 #f1f1f1",
});

const StyleLoaderDiv = styled("div")({
  padding: "15px",
  display: "flex",
  justifyContent: "center",
});

const StylePopper = styled(Popper)({
  transform: "translate3d(670px, 136px, 0px)"
})