import React, { FunctionComponent, useEffect } from "react";
import { Popover, Box, Button, TextField, Checkbox, styled ,Slider} from "@material-ui/core";
import AutoComplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { colors } from "../../blocks/utilities/src/Colors";
import moment, { Moment } from "moment";
import { isArray, toString } from "lodash";
import {MultiSelectWithSearchBox} from "./customComponents/CustomSelect.web";

interface Stats {
  maxQuantity: number;
  minQuantity: number;
  maxTotalPieces: number;
  minTotalPieces: number;
}

export interface IFilter {
  title: string;
  type:
  | "text"
  | "select"
  | "multipleSelect"
  | "dropdown"
  | "custom"
  | "autocompolete"
  | "dateselect"
  | "timeselect"
  | "yearselect"
  | "rangeslider"
  | "plantCapacity"
  | "datepicker"
  | "doubleDatepicker"
  | "searchableMultiSelect";
  options?: { label: string; value: string}[];
  customRender?: Function;
  value?: string | string[];
  apiKey?: string;
  onClear?: Function;
  datevalue?: { from?: string; to?: string };
  timeRange?: {from?: string; to?:string};
  customSpecificRange?: {
    fromLabel: string
    toLabel: string
    isSpecificOptionOnly: boolean
  }
}
type FilterPopoverProps = {
  anchor?: HTMLDivElement | undefined;
  filters: IFilter[];
  onFilterChange: (filters: IFilter[]) => void;
  onClose: Function;
  onClearFilter?: Function | any;
  onChangeFilterSection?: Function | any;
  onAutoCompleteChange?: Function | any
  rangeValue1?: Stats
  refreshFilterState?: Function;
};

const FilterPopover: FunctionComponent<FilterPopoverProps> = ({
  filters,
  onFilterChange,
  anchor,
  onClose,
  onClearFilter,
  onChangeFilterSection,
  onAutoCompleteChange,
  rangeValue1,
  refreshFilterState
}) => {
  const [selectedTitle, setSelectedTitle] = React.useState<string>("");
  const [selectedOptionTitle, setSelectedOptionTitle] = React.useState<string>("");
  const [currentFilters, setCurrentFilters] = React.useState<IFilter[]>([]);
  const [isOpenFromDate, setIsOpenFromDate] = React.useState(false);
  const [isOpenToDate, setIsOpenToDate] = React.useState(false);
  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);
  const [value, setValue] = React.useState<number[]>([]);

  useEffect(() => {
    const selectedFilter = filters.find(
      (filter) => filter.title === selectedTitle
    );

    setCurrentFilters([...filters]);
    if (filters.length > 0 && !selectedFilter) {
      setSelectedTitle(filters[0].title);
    }
  }, [filters]);

  const handleClose = () => {
    setCurrentFilters([...filters]);
    refreshFilterState && refreshFilterState([...filters]);
    onClose();
  };

  const handleClearAll = () => {
    const isExtendedTimeFilter = currentFilters.some(filterItem => ["timeselect", "yearselect"].includes(filterItem.type))
    const newFilters = filters.map((filter) => ({ 
      ...filter, 
      value: isExtendedTimeFilter && filter.type === "dateselect" ? "today": "", 
      datevalue:{from:"",to:""}, 
      timeRange: {from: "", to: ""} 
    }));
    onFilterChange(newFilters);
    handleClose();
    onClearFilter && onClearFilter()
  };

  const handleClear = () => {
    const isExtendedTimeFilter = currentFilters.some(filterItem => ["timeselect", "yearselect"].includes(filterItem.type))

    const newFilters = currentFilters.map((filter) => {
      if (filter.title === selectedTitle) {
        if (filter.type === "custom" && filter.onClear) {
          filter.onClear(filter.title);
          return filter;
        } else if (filter.type === "dateselect"){
          return {
              ...filter,
            value: filter.customSpecificRange?.isSpecificOptionOnly ? "specific" : "",
              datevalue: {
                from: "",
                to: ""
              }
            }
        } else if (filter.type === "timeselect"){
          return {
            ...filter,
            timeRange: {
              from: "",
              to: ""
            }
          }
        } else if (filter.type === "yearselect"){
          return {
            ...filter,
            datevalue: {
              from: "",
              to: ""
            },
            value: "current_year"
          }
        } else if (filter.type === "plantCapacity"){
          return {
            ...filter,
            value: ""
          };
        } else {
          return {
            ...filter,
            value: "",
            options:
              filter.type === "select" || "multipleSelect"
                ? filter.options
                : [],
          };
        }
      }
      return filter;
    });
    setCurrentFilters(newFilters);
    refreshFilterState && refreshFilterState(newFilters);
  };

  const renderFilterContent = () => {
    const filter =
      currentFilters.find((filter) => filter.title === selectedTitle) ||
      filters[0];
    if (!filter) return null;
    if (filter.type === "select") {
      return renderSelectionFilter(filter);
    } else if (filter.type === "autocompolete") {
      return renderAutoCompleteFilter(filter);
    } else if (filter.type === "custom") {
      return filter.customRender && filter.customRender();
    } else if (filter.type === "multipleSelect") {
      return renderMultipleSelectFilter(filter);
    } else if (filter.type === "dateselect") {
      return renderDateFilter(filter);
    } else if (filter.type === "timeselect") {
      return renderTimeFilter(filter);
    } else if (filter.type === "yearselect") {
      return renderDateFilter(filter)
    } else if (filter.type === "rangeslider") {
      return renderRangeFilter(filter)
    } else if (filter.type === "plantCapacity") {
      return renderPlantCapacityFilter(filter)
    } else if (filter.type === "datepicker") {
      return renderDatePickerFilter(filter)
    } else if (filter.type === "doubleDatepicker") {
      return renderDoubleDatePickerFilter(filter)
    } else if (filter.type === "searchableMultiSelect") {
      return renderSearchableMultiSelect(filter)
    }
    return renderTextFilter(filter);
  };

  const renderSelectionFilter = (filter: IFilter) => {
    if (!filter.options) return null;
    return filter.options.map((option) => (
      <Box
        data-test-id='viewButton'
        sx={{
          ...webStyle.popoverButton,
          width: 240,
          ...(option.value === filter.value
            ? {
              backgroundColor: "#204B9C",
              color: "#FFFFFF",
            }
            : { color: "black" }),
        }}
        onClick={() => {
          const newFilters = currentFilters.map((filterItem) => {
            const isDateYearFilter = checkIsDateYearFilter()
            if (filterItem.title === selectedTitle) {
              return {
                ...filterItem,
                value: option.value,
              };
            }
            if (isDateYearFilter && filterItem.type !== filter.type) {
              return {
                ...filterItem,
                datevalue:{
                  from: "",
                  to: ""
                },
                value: ""
              }
            }
            return filterItem;
          });
          setCurrentFilters(newFilters);
          refreshFilterState && refreshFilterState(newFilters);
        }}
      >
        {option.label}
      </Box>
    ));
  };

  const renderMultipleSelectFilter = (filter: IFilter) => {
    if (!filter.options) return null;
    return (
      <div style={{ overflow: "auto", maxHeight: "350px", minWidth: "200px" }}>
        {filter.options.map((option) => (
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              margin: "8px",
            }}
          >
            <Checkbox
              data-test-id={`checkbox-${option.value}`}
              checked={filter.value?.includes(option.value)}
              onChange={(e) => {
                const newFilters = currentFilters.map((filter) => {
                  if (filter.title === selectedTitle) {
                    if (e.target.checked) {
                      return {
                        ...filter,
                        value: [...(filter.value || []), option.value],
                      };
                    } else {
                      return {
                        ...filter,
                        value: (filter.value as string[]).filter?.(
                          (value) => value !== option.value
                        ),
                      };
                    }
                  }
                  return filter;
                });
                setCurrentFilters(newFilters);
                refreshFilterState && refreshFilterState(newFilters);
              }}
            />
            <div>{option.label}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderAutoCompleteFilter = (filter: IFilter) => {
    return (
      <div
        style={{
          marginLeft: "24px",
          marginRight: "24px",
        }}
      >
        <>
          <div style={webStyle.textLabel}>{filter.title}</div>
          <AutoComplete
            data-test-id={"autoCompleteDropdown"}
            freeSolo
            value={filter.value}
            disableClearable
            options={filter.options?.map((option) => option.label) || []}
            onChange={(e, value) => {
              const changedFilters = currentFilters.map((filter: IFilter) => {
                if (filter.title === selectedTitle) {
                  return {
                    ...filter,
                    value: value,
                  };
                }
                return filter;
              });
              setCurrentFilters(changedFilters);
              onAutoCompleteChange && onAutoCompleteChange(filter.title,value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                key={filter.title}
                value={filter.value}
                style={webStyle.textInput}
                variant='outlined'
                type={"text"}
                placeholder='Type to Select'
                onChange={(e) => {
                  const newFilters = currentFilters.map((filter) => {
                    if (filter.title === selectedTitle) {
                      return {
                        ...filter,
                        value: e.target.value,
                      };
                    }
                    return filter;
                  });
                  setCurrentFilters(newFilters);
                  onAutoCompleteChange && onAutoCompleteChange(filter.title, e.target.value)
                }}
              />
            )}
          />
        </>
      </div>
    );
  };

  const renderTextFilter = (filter: IFilter) => {
    return (
      <div
        style={{
          marginLeft: "24px",
          marginRight: "24px",
        }}
      >
        <>
          <div style={webStyle.textLabel}>{filter.title}</div>
          <TextField
            key={filter.title}
            value={filter.value}
            style={webStyle.textInput}
            variant='outlined'
            type={"text"}
            placeholder='Type to Filter'
            onChange={(e) => {
              const newFilters = currentFilters.map((filter) => {
                if (filter.title === selectedTitle) {
                  return {
                    ...filter,
                    value: e.target.value,
                  };
                }
                return filter;
              });
              setCurrentFilters(newFilters);
              refreshFilterState && refreshFilterState(newFilters);
            }}
          />
        </>
      </div>
    );
  };

  const renderDateField = (filter: IFilter) => {

    const openFromDate = (isFrom:boolean)=>{
      if(isFrom){
        setIsOpenFromDate(!isOpenFromDate)
      }else{
        setIsOpenToDate(!isOpenToDate)
      }
    }

    const handleFromDateChange = (date: Date | null) => {
      setFromDate(date);
      setToDate(null);
      updateFilters(date, toDate);
    };
  
    const handleToDateChange = (date: Date | null) => {
      setToDate(date);
      updateFilters(fromDate, date);
    };
  

    const updateFilters = (from: Date | null, to: Date | null) => {
      const newFilters = filters.map((filter) => {
        if (filter.title === "Date" || filter.title === "Due") {
          return {
            ...filter,
            datevalue: {
              from: from ? from.toISOString() : "",
              to: to ? to.toISOString() : "",
            },
            value: "specific",
          };
        }
        return filter;
      });
      setCurrentFilters(newFilters);
      refreshFilterState && refreshFilterState(newFilters);
    };

    const maxFromDate = filter.datevalue?.to ? new Date(filter.datevalue.to) : undefined

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={webStyle.datetTextLabel}>{filter.customSpecificRange?.fromLabel || "From"}</div>
      {
        filter.title === 'Due' ? 
        <StyledDatePicker
        open={isOpenFromDate}
        onClick={() => openFromDate(true)}
        autoOk
        onClose={() => openFromDate(true)}
        disableToolbar
        variant='inline'
        format='DD/MM/yyyy'
        placeholder="DD/MM/YYYY"
        data-test-id = "from-date"
        value={filter.datevalue?.from}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        keyboardIcon={<></>}
        onChange={handleFromDateChange}
      /> :
       <StyledDatePicker
        open={isOpenFromDate}
        onClick={() => openFromDate(true)}
        autoOk
        onClose={() => openFromDate(true)}
        disableToolbar
        variant='inline'
        format='DD/MM/yyyy'
        placeholder="DD/MM/YYYY"
        data-test-id = "from-date"
        value={filter.datevalue?.from || null}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        keyboardIcon={<></>}
        onChange={handleFromDateChange}
        maxDate={filter.customSpecificRange ? maxFromDate : new Date()}
      />
      }
      <div style={webStyle.datetTextLabel}>{filter.customSpecificRange?.toLabel || "To"}</div>
      {
        filter.title === 'Due' ?
        <StyledDatePicker
        open={isOpenToDate}
        onClick={() => openFromDate(false)}
        autoOk
        onClose={() => openFromDate(false)}
        disableToolbar
        variant='inline'
        format='DD/MM/yyyy'
        placeholder="DD/MM/YYYY"
        data-test-id = "to-date"
        value={filter.datevalue?.to}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        keyboardIcon={<></>}
        onChange={handleToDateChange}
        minDate={filter.datevalue?.from || ""}
      />        
        :
       <StyledDatePicker
        open={isOpenToDate}
        onClick={() => openFromDate(false)}
        autoOk
        onClose={() => openFromDate(false)}
        disableToolbar
        variant='inline'
        format='DD/MM/yyyy'
        placeholder="DD/MM/YYYY"
        data-test-id = "to-date"
        value={filter.datevalue?.to || null}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        keyboardIcon={<></>}
        onChange={handleToDateChange}
        minDate={filter.datevalue?.from || ""}
        maxDate={filter.customSpecificRange ? undefined : new Date()}
      />}
    </MuiPickersUtilsProvider>
    )
  }

  const renderDateFilter = (filter: IFilter) => {
    const renderCallBack = filter.type === "yearselect" ? renderSpecificYearRange : renderDateField

    return (
      <div
        style={{
          marginLeft: "24px",
          marginRight: "24px",
        }}
      >
          {renderSelectionFilter(filter)}
          <Box>
              {filter.value === "specific" && renderCallBack(filter)}
          </Box>
      </div>
    );
  };

  const renderRangeFilter = (filter: IFilter) => {
    if(!filter.options) return
    return (
      <div
        style={{
          marginLeft: "40px",
          marginRight: "45px",
        }}
      >
         <div style={webStyle.textLabel}>{filter.options[0].label}</div>

          <Box>
             {renderRangeField(filter)}
          </Box>
      </div>
    );
  };

  const handleDateInput = (varDate: Date) => {
    const updated = currentFilters.map((item: IFilter) => {
      if (item.title === "Date") {
        item.value = moment(varDate).format("YYYY/MM/DD");
      }
      return item;
    });
    setCurrentFilters(updated);
    refreshFilterState && refreshFilterState(updated);
  };

  const renderDatePickerFilter = (filter: IFilter) => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePickerWrapper>
          <div className='pr-24'>
            <div className='w-190'>
              <FilterLabel>Select Date</FilterLabel>
              <StyledSingleDatePicker
                autoOk
                maxDate={new Date()}
                format='DD/MM/YYYY'
                placeholder='DD/MM/YYYY'
                value={filter.value ? filter.value : null}
                onChange={handleDateInput}
              />
            </div>
          </div>
        </DatePickerWrapper>
      </MuiPickersUtilsProvider>
    )
  };

  const onChangeDobleDate = (key: string, date: Date | null) => {
    const updated = currentFilters.map((item: IFilter) => {
      if (item.title === "Date") {
        if (key === "startdate") {
          item.value = JSON.parse(
            JSON.stringify([date, isArray(item.value) ? item.value[1] : null])
          );
        } else {
          item.value = JSON.parse(
            JSON.stringify([isArray(item.value) ? item.value[0] : null, date])
          );
        }
      }
      return item;
    });
    setCurrentFilters(updated);
    refreshFilterState && refreshFilterState(updated);
  };

  const renderDoubleDatePickerFilter = (filter: IFilter) => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePickerWrapper>
          <div className='pr-24'>
            <div className='w-190'>
              <FilterLabel>Start Date</FilterLabel>
              <StyledSingleDatePicker
                data-test-id={"filter-startDate"}
                autoOk
                format='DD/MM/YYYY'
                placeholder='DD/MM/YYYY'
                value={isArray(filter.value) ? filter.value[0] : null}
                onChange={(date) => onChangeDobleDate("startdate", date)}
              />
            </div>
          </div>
          <div className='pr-24'>
            <div className='w-190'>
              <FilterLabel>End Date</FilterLabel>
              <StyledSingleDatePicker
                data-test-id={"filter-endDate"}
                autoOk
                format='DD/MM/YYYY'
                placeholder='DD/MM/YYYY'
                value={isArray(filter.value) ? filter.value[1] : null}
                onChange={(date) => onChangeDobleDate("enddate", date)}
              />
            </div>
          </div>
        </DatePickerWrapper>
      </MuiPickersUtilsProvider>
    );
  };

  const renderPlantCapacityFilter = (filter: IFilter) => {
    const value = JSON.parse((filter?.value as string) || "{}");

    return (
      <div
        style={{
          marginLeft: "24px",
          marginRight: "24px",
        }}
      >
        <div>
          <div
            style={{ fontWeight: 600, fontSize: "14px", lineHeight: "22px" }}
          >
            Plant Capacity in Kg
          </div>
          <TextField
            inputProps={{ "data-test-id": "capacityKgInput" }}
            key={"capacity_in_kg"}
            value={value.kg || ""}
            style={{ marginTop: "4px", width: "190px" }}
            variant='outlined'
            type={"text"}
            placeholder='0.00'
            onChange={(event) => {
              const changedFilters = currentFilters.map((filter: IFilter) => {
                if (filter.title === selectedTitle) {
                  return {
                    ...filter,
                    value: JSON.stringify({
                      ...value,
                      kg: event.target.value,
                    }),
                  };
                }
                return filter;
              });
              setCurrentFilters(changedFilters);
              refreshFilterState && refreshFilterState(changedFilters);
            }}
          />
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            or Select Range
          </div>
          <div>
            {value.kgRange
              ? `${value.kgRange[0]} - ${value.kgRange[1]}`
              : "0-100"}
          </div>
          <div>
            <Slider
              value={value.kgRange || [0, 100]}
              onChange={(event, newValue) => {
                const changedFilters = currentFilters.map((filter: IFilter) => {
                  if (filter.title === selectedTitle) {
                    return {
                      ...filter,
                      value: JSON.stringify({
                        ...value,
                        kgRange: newValue,
                      }),
                    };
                  }
                  return filter;
                });
                setCurrentFilters(changedFilters);
                refreshFilterState && refreshFilterState(changedFilters);
              }}
              min={0}
              max={100}
              aria-labelledby='range-slider'
            />
          </div>
          <div
            style={{
              height: 1,
              backgroundColor: "#D9D9D9",
              marginTop: 32,
              marginBottom: 32,
            }}
          />
          <div
            style={{ fontWeight: 600, fontSize: "14px", lineHeight: "22px" }}
          >
            Plant Capacity in Pieces
          </div>
          <TextField
            inputProps={{ "data-test-id": "capacityPicesInput" }}
            key={"capacity_in_pieces"}
            value={value.capacity_in_pieces || ""}
            style={{ marginTop: "4px", width: "190px" }}
            variant='outlined'
            type={"text"}
            placeholder='0.00'
            onChange={(event) => {
              const changedFilters = currentFilters.map((filter: IFilter) => {
                if (filter.title === selectedTitle) {
                  return {
                    ...filter,
                    value: JSON.stringify({
                      ...value,
                      capacity_in_pieces: event.target.value,
                    }),
                  };
                }
                return filter;
              });
              setCurrentFilters(changedFilters);
              refreshFilterState && refreshFilterState(changedFilters);
            }}
          />
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            or Select Range
          </div>
          <div>
            {value.piecesRange
              ? `${value.piecesRange[0]} - ${value.piecesRange?.[1]}`
              : "0-10000"}
          </div>
          <div>
            <Slider
              min={0}
              max={10000}
              value={value.piecesRange || [0, 10000]}
              onChange={(event, newValue) => {
                const changedFilters = currentFilters.map((filter: IFilter) => {
                  if (filter.title === selectedTitle) {
                    return {
                      ...filter,
                      value: JSON.stringify({
                        ...value,
                        piecesRange: newValue,
                      }),
                    };
                  }
                  return filter;
                });
                setCurrentFilters(changedFilters);
                refreshFilterState && refreshFilterState(changedFilters);
              }}
              aria-labelledby='range-slider'
            />
          </div>
        </div>
      </div>
    );
  }

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number[] | number,
  ) => {
    setValue(newValue as number[]);
    if(isArray(newValue)){
      const newFilters = currentFilters.map((filter) => {
        if (filter.title === selectedTitle) {
          return {
            ...filter,
            value: newValue.map(String)
          };
        }
        return filter;
      });
      setCurrentFilters(newFilters)
      refreshFilterState && refreshFilterState(newFilters);
    }
  };
  
  const renderRangeField = (filter: IFilter) => {
    const MaxVal = filter.apiKey === "quantity" ? rangeValue1?.maxQuantity : rangeValue1?.maxTotalPieces
    const MinVal = filter.apiKey === "quantity" ? rangeValue1?.minQuantity : rangeValue1?.minTotalPieces
    const rangeVal = [rangeValue1 ? MinVal : 0,rangeValue1 ? MaxVal : 0]
    const val1 = isArray(filter.value) ? filter.value.map(Number)[0] : rangeVal[0]
    const val2 = isArray(filter.value) ? filter.value.map(Number)[1] : rangeVal[1]
    return (
      <Box sx={{ width: "117px" }}>
      <RangeStyled>{val1 } - {val2}</RangeStyled>
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={isArray(filter.value) ? filter.value.map(Number): rangeVal as number[]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={rangeValue1 ? MinVal : 0}
        max={rangeValue1 ? MaxVal : 0}
        aria-labelledby='range-slider'
        style={{color :"#204B9C"}}
      />
    </Box>
    )
  }

  const renderSpecificYearRange = (filter: IFilter) => {
    const handleFromYearChange = (date: Moment | null) => {
      updateYearFilter(date ? date.format() : "", toString(filter.datevalue?.to));
    };
  
    const handleToYearChange = (date: Moment | null) => {
      updateYearFilter(toString(filter.datevalue?.from), date ? date.format() : "")
    };

    const updateYearFilter = (fromValue: string, toValue: string) => {
      const newEndYear = fromValue && toValue && moment(fromValue).year() >= moment(toValue).year() ? "" : toValue
      const newFilters = currentFilters.map((filter) => {
        if (filter.title === "Year") {
          return {
            ...filter,
            datevalue: {
              from: fromValue,
              to: newEndYear,
            },
            value: "specific",
          };
        }
        return filter;
      });
      setCurrentFilters(newFilters);
      refreshFilterState && refreshFilterState(newFilters);
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={webStyle.datetTextLabel}>From</div>
        <DatePicker
          autoOk
          disableToolbar
          views={["year"]}
          format='YYYY'
          data-test-id = "from-year-picker"
          value={filter.datevalue?.from || null}
          onChange={handleFromYearChange}
          maxDate={new Date()}
          TextFieldComponent={(params) => <StyledTextField {...params} variant="outlined" />}
        />
        <div style={webStyle.datetTextLabel}>To</div>
        <DatePicker
          autoOk
          disableToolbar
          views={["year"]}
          format='YYYY'
          data-test-id = "to-year-picker"
          value={filter.datevalue?.to || null}
          onChange={handleToYearChange}
          minDate={filter.datevalue?.from ? moment(filter.datevalue.from).add(1, "year"): undefined}
          maxDate={new Date()}
          TextFieldComponent={(params) => <StyledTextField {...params} variant="outlined" />}
        />
    </MuiPickersUtilsProvider>
    )
  }

  const checkIsDateYearFilter = () => currentFilters.some(filterItem => filterItem.type === "yearselect")
    

  const renderTimeFilter = (filter: IFilter) => {
    const handleFromTimeChange = (time: Moment | null) => {
      const newFromTime = time ? time.startOf("hour").format() : ""
      updateFilters(newFromTime, toString(filter.timeRange?.to));
    };
  
    const handleToTimeChange = (time: Moment | null) => {
      const newToTime = time ? time.endOf("hour").format() : ""
      updateFilters(toString(filter.timeRange?.from), newToTime);
    };

    const updateFilters = (fromTime: string, toTime: string) => {
      let finalToTime = toTime;
      if (fromTime && toTime ) {
        if ((!moment(toTime).isAfter(fromTime))) {
          finalToTime = ""
        }
      }
      const newFilters = currentFilters.map((filterItem) => {
        if (filterItem.type === "timeselect") {
          return {
            ...filterItem,
            timeRange: {
              from: fromTime,
              to: finalToTime
            },
          };
        }
        return filterItem;
      });
      setCurrentFilters(newFilters);
      refreshFilterState && refreshFilterState(newFilters);
    };

    return (
      <div
        style={webStyle.wrapper}
      >
          <Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={webStyle.datetTextLabel}>From</div>
            <TimePicker
              views={['hours']}
              data-test-id = "from-time-picker"
              value={filter.timeRange?.from ? moment(filter.timeRange.from) : null}
              onChange={handleFromTimeChange}
              TextFieldComponent={(params) => <StyledTextField {...params} variant="outlined"/>}
            />
            <div style={webStyle.datetTextLabel}>To</div>
            <TimePicker
              views={['hours']}
              data-test-id = "to-time-picker"
              value={filter.timeRange?.to ? moment(filter.timeRange.to) : null}
              onChange={handleToTimeChange}
              TextFieldComponent={(params) => <StyledTextField {...params} variant="outlined"/>}
            />
          </MuiPickersUtilsProvider>
          </Box>
      </div>
    );
  }

  const renderSearchableMultiSelect = (filter: IFilter) => {
    return (
      <MultiSelectWithSearchBox
       options={filter.options || []}
       onSearch={onAutoCompleteChange}
       value={filter.value as string[]}
       placeholder={filter.title}
       onSelectOptions={(newValues) => setCurrentFilters(
        prev => prev.map(filterItem => (
          filterItem === filter ? ({...filterItem, value: newValues})
          : filterItem
       )))}
       onScrollList={() => {}}
      />
    )
  }

  return (
    <>
      <Popover
        data-test-id="filterPopover"
        anchorReference='anchorEl'
        open={!!anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={anchor}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
          <div style={webStyle.filterHeaderContainer}>
            <div style={webStyle.filterHeaderText}>Filters</div>
            <div
              data-test-id={"clear"}
              onClick={handleClear}
              style={{ ...webStyle.clearButton }}
            >
              Clear
            </div>
          </div>

          <div style={webStyle.filterLine} />
          <div style={{ ...webStyle.popoverContainer, boxShadow: "none" }}>
            <div>
              {filters.map((filter) => {
                return (
                  <Box
                    data-test-id='viewButton'
                    key={filter.title}
                    sx={{
                     ...(filter.type==="rangeslider"  ? webStyle.popoverButton2 : webStyle.popoverButton),
                      color: "black",
                      ...(filter.title === selectedTitle
                        ? {
                          backgroundColor: "#204B9C",
                          color: "#FFFFFF",
                        }
                        : {}),
                    }}
                    onClick={() => {
                      setSelectedTitle(filter.title);
                      onChangeFilterSection && onChangeFilterSection(filter.title)
                    }}
                  >
                    {filter.title}
                  </Box>
                );
              })}
            </div>
            <div>{renderFilterContent()}</div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "32px",
              marginBottom: "32px",
              justifyContent: "center",
              marginTop: "24px",
            }}
          >
            <PopOverButtonWrapper>
            <Button
              data-test-id={"CancelButton"}
              data-testid={"CancelButton"}
              variant='outlined'
              onClick={handleClearAll}
              size='medium'
              style={{ textTransform: "none" }}
              className="clearButton"
            >
              Clear All
            </Button>
            </PopOverButtonWrapper>
            <PopOverButtonWrapper>
            <Button
              variant='contained'
              data-test-id={"SaveButton"}
              data-testid={"SaveButton"}
              onClick={() => {
                onFilterChange(currentFilters);
                handleClose();
              }}
              size='medium'
              style={{ textTransform: "none" }}
              color='secondary'
              className="saveButton"
            >
              Apply
            </Button>
            </PopOverButtonWrapper>
          </div>
        </div>
      </Popover>
    </>
  );
};
export default FilterPopover;

const webStyle = {
  wrapper: {
    marginLeft: "24px",
    marginRight: "24px",
  },
  clearAllButton: {
    fontWeight: 600,
    color: "#DC2626",
    width: "126px",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    borderColor: "#DC2626",
    "@media (max-width: 1366px)": {
      width: "110px !important",
      height: "36px",
      background:"green"
    },
    "@media only screen and (max-width: 1024px)": {
      width: "100px !important",
      height: "28px",
      background:"red"
    },
  },
  clearButton: {
    color: "#DC2626",
    fontSize: 16,
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
  },
  saveButton: {
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: "#204B9D",
    height: "44px",
    width: "126px",
    borderRadius: "8px",
  },
  popoverButton: {
    cursor: "pointer",
    width: 240,
    height: "33px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "24px",
    paddingTop: "8px",
    color: "#4D4D4D",
  },
  popoverButton2: {
    cursor: "pointer",
    width: "190px",
    height: "40px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "24px",
    paddingTop: "11px",
    color: "#4D4D4D",
  },
  filterHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    paddingBottom: "16px",
  },
  filterHeaderText: {
    color: "#204B9C",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
  },
  filterLine: {
    marginLeft: "24px",
    backgroundColor: "black",
    marginRight: "24px",
    width: "90%",
    height: "1px",
    marginBottom: "8px",
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
  },
  textLabel: {
    fontFamily: "Montserrat",
    color: "#000",
    lineHeight: "22px",
    fontWeight: 600,
    fontSize: "14px",
  },
  textInput: {
    marginTop: "4px",
    height: "44px",
    width: "190px",
    fontFamily: "Montserrat !important",
    marginBottom: "8px",
  },
  datetTextLabel: {
    fontFamily: "Montserrat",
    lineHeight: "22px",
    fontWeight: 600,
    fontSize: "14px",
    marginTop:"24px"
  },
};

const StyledDatePicker = styled(KeyboardDatePicker)({
  "& > div:hover": {
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  "& > div": {
    borderRadius: 8,
    border: "2px solid #ECECEC",
    boxShadow: "0px 0px 1px #ECECEC",
  },
  "& input, & .MuiInputBase-input::placeholder": {
    opacity: 1,
    fontSize: 14,
    color: colors().darkliver,
    fontFamily: "Montserrat",
    padding:'14.9px 14px',
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px',
        padding:'14.9px 14px'
    },
  },
  "& .MuiInput-underline::after, & .MuiInput-underline::before, & p": {
    display: "none",
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'14.9px 14px'
    },
    "& div button": {
      padding:"2px 1px"
    }
  },
});

const PopOverButtonWrapper = styled("div")({
  "& .clearButton":{
    fontWeight: 600,
    color: "#DC2626",
    width: "126px",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    borderColor: "#DC2626",
    "@media (max-width: 1366px)": {
      width: "110px !important",
      height: "36px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "110px !important",
      height: "36px",
    },
  },
  "& .saveButton": {
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: "#204B9D",
    height: "44px",
    width: "126px",
    borderRadius: "8px",
    "@media (max-width: 1366px)": {
      width: "110px !important",
      height: "36px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "100px !important",
      height: "36px",
    },
  }
})

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.4,
    "& .MuiInputBase-input": {
      padding: "10px 16px",
      height: 24
    }
  }
})

const RangeStyled = styled('div')({
  fontSize: "14px",
  paddingTop: "15px",
  paddingBottom: "10px"
  
})

const DatePickerWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  "& .pr-24": {
    padding: "0 24px",
  },
  "& .w-190": {
    width: "190px",
  },
});

const FilterLabel = styled("div")({
  color: "rgb(0, 0, 0)",
  margin: "0 0 4px 0",
  fontWeight: 600,
  fontSize: 14,
});

const StyledSingleDatePicker = styled(DatePicker)({
  "& .MuiInput-root": {
    height: "100%",
  },
  "& .MuiInputBase-input": {
    border: "1px solid #d3cdcd",
    padding: "17px 10px",
    borderRadius: "8px",
  },
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    borderBottom: "none !important",
  },
});