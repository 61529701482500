import React from "react";

import OrdersController, {
  Props,
  configJSON,
  // Customizable Area Start
  Option,
  // Customizable Area End
} from "./OrdersController.web";

// Customizable Area Start

import {
  Paper,
  TableRow as MuiTableRow,
  TableHead,
  TableContainer,
  TableCell as MuiTableCell,
  TableBody,
  Table,
  Box,
  Select,
  MenuItem,
  FormControl,
  styled,
  Snackbar,
  TablePagination,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
  MenuProps,
  Radio,
  StyledComponentProps,
  CircularProgress,
  Portal,
  Grid,
  Typography,
  TextareaAutosize,
  Button
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import '../../../blocks/categoriessubcategories/src/PreferenceList.css'
import {
  withStyles
} from "@material-ui/core/styles";
import FilterIcon from "@material-ui/icons/FilterList";
import DropdownRadio from "../../../components/src/customComponents/DropdownRadio.web";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import FilterPopover,  { IFilter }  from "../../../components/src/FilterPopover";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import SortingTableCell from "../../../components/src/SortingTableCell";

import {
  editIcon,
  printIcon,
  circleIcon,
  iconUnpaidGray,
  iconPrinterGray,
  radio_blue,
  radio_white,
} from "./assets";

import {
  CustomTabs,
  CustomTab,
} from "../../../components/src/customComponents/CustomTags.web";

import OrderDetailsPage from "./OrderDetails.web";
import PrintModal from "./PrintModal.web";

import {
  SubmitButton,
  CancelButton,
  ButtonContainer,
  RoundedDialog,
} from "../../../components/src/CustomTheme.web";

import { BottomContainer } from "../../promocodes/src/PromoCodeCreate.web";
import BarcodesScanner from "../../BarcodesScanner/src/BarcodesScanner.web";
import { showNotes } from "../../advancedsearch/src/AdvancedSearch.web";
import PaymentModal from "../../Payments/src/PaymentModal.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IOrderData } from "./OrderDetailsController.web";
const Strings = configJSON.ListingPage.Strings;
const CheckTrue = require("../../../components/src/check_true.svg");
const CheckFalse = require("../../../components/src/check_false.svg");
import { colors } from "../../utilities/src/Colors";
import {
  TableRow as TableRownew,
  TableCell as TableCellnew,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import ConfirmationDialog from "../../../components/src/customComponents/ConfirmationDialog.web";
import { IOrder } from "../../OrderCreation/src/OrderCreation2Controller.web";
import { IconBluePrint, creditNoteIcon } from "../../../blocks/cfplantdashboard2/src/assets";
import { CreditNoteInput, MainWrapper, PriceGrid, StyledDialogWrapper, StyledSelectAdjustmentReason, TextLabel } from "../../../blocks/cfplantdashboard2/src/Cfplantdashboard2.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { CustomMenuProps } from "../../ProductDescription/src/HomeCleaningSettingsList.web";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
// Customizable Area End

export default class Orders extends OrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getOrderDetails(rowData: IOrderData): string | React.ReactNode {
    if (rowData.attributes?.order_items.length === 0) return "--";
    if (rowData.attributes?.is_quick_drop)
      return rowData.attributes?.order_items[0]?.qty;
    else
      return (
        <div>
          {rowData.attributes?.order_items.map((orderItem: any, index: number) => {
            return (
              <>
                {rowData.type == "home_cleaning_order" ?
                  <div key={orderItem.id + index} style={{display:"flex",flexWrap:"wrap"}}>
                    {orderItem.attributes.catalogue.name} <img src={circleIcon} alt='product' /> {orderItem.attributes.catalogue.second_name} (
                    {orderItem.attributes.product_type.name || "--"}) x {orderItem.attributes.quantity}
                  </div> :
                  <div key={orderItem.name + index} style={{display:"flex",flexWrap:"wrap"}}>
                   <span>{orderItem.name}</span> <img src={circleIcon} alt='circle' /> <span>{orderItem.second_name}</span> (
                    {orderItem.service_short_name || "--"}) x {orderItem.qty}
                    <div style={{ color: "#0F172A", fontSize: "12px",fontWeight: "400", textTransform: "lowercase"}}>
                      {Number(orderItem?.height) !== 0 && Number(orderItem?.width) !== 0  && (<>length * width: <div> {orderItem.height} m * {orderItem.width} m </div></>)}
                      {Number(orderItem?.weight) !== 0 && (<>weight : {orderItem.weight} Kg</>)}
                    </div>
                  </div>}
              </>
            );
          })}
          {
            renderBaseOnConditions(
              this.state.permissionStatus.viewOrderPermission,
              <div style={{ paddingTop: 12 }}>
                <div
                  data-test-id={`detail${rowData.id}`}
                  className="detailsBtn"
                  onClick={() => this.handleOrderDetail(rowData)}
                >
                  Details
                </div>
              </div>,
              <></>
            )
          }
        </div>
      );
  }

  renderOrderNumber = (orderNumber: string, orderType: string) => {
    return(
      <>
      <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column", gap: "2"}}>
        <div>{orderNumber}</div>
        <div style={{paddingTop: "12px"}}>{orderType === "B2bOrder" && <StatusButton>B2b</StatusButton>}</div>
      </div>
      </>
    )
  }

  renderOrderUnpaidDialog() {
    return (
      <RoundedDialog
        data-test-id='unpaidOrderModal'
        open={this.state.unpaidDialogPopup}
      >
        <DialogContent>
          <Snackbar
            data-test-id='message-unpaid'
            autoHideDuration={5000}
            key={"unpaid-order-error-snackbar"}
            open={this.state.unPaidOrderError.length > 0}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.hideUnpaidOrderError.bind(this)}
          >
            <Alert
              onClose={this.hideUnpaidOrderError.bind(this)}
              severity={"error"}
            >
              {this.state.unPaidOrderError}
            </Alert>
          </Snackbar>
          <img className="mainIcon" src={iconUnpaidGray} />
          <h3>{Strings.orderUnPaid}</h3>
          <p style={webStyle.unPaidDialogPara}>
            {Strings.orderUnpaidDiaglogPara}
          </p>
          <ButtonContainer justifyContent={"center"}>
            <CancelButton data-test-id="unpaidOrderModalClose" onClick={this.closeUnpaidDialog}>Cancel</CancelButton>
            <SubmitButton
              data-test-id='unpaidOrderModalPay'
              onClick={this.handleDeliverPaymentModalOpen}
            >
              Pay Now
            </SubmitButton>
          </ButtonContainer>
        </DialogContent>
      </RoundedDialog>
    );
  }

  ordersTableHeaderRender(sortingData: ISortingData) {
    return (
      <RowContainer header>
        <TableRownew
          className='table-cols'
          columns = {this.getTableRowColumns('header')}
        >
          {
            this.getConditionBased(
              this.state.showCheckBoxAndButton,
              <TableCellnew
                header
                className='align-item-center'
                content={
                  <img
                    style={{ cursor: "pointer" }}
                    data-test-id='selectAll'
                    onClick={() => this.handleSelectAll()}
                    src={this.getConditionBased(this.state.selectAllChecked,CheckTrue,CheckFalse) as string}
                    alt={this.getConditionBased(this.state.selectAllChecked,"checked","unchecked") as string}
                  />
                }
              />,
              <></>
            )
          }
          {
            this.getConditionBased(
              this.state.selectedDropdownvalue === "rewash",
              <TableCellnew
                header
                className='align-item-center'
                content={
                  <img
                    style={{ opacity: 0 }}
                    data-test-id='selectAll'
                    src={this.getConditionBased(this.state.selectAllChecked,CheckTrue,CheckFalse) as string}
                    alt={this.getConditionBased(this.state.selectAllChecked,"checked","unchecked") as string}
                  />
                }
              />,
              <></>
            )
          }
          <TableCellnew
            header
            sortingData={sortingData}
            content={Strings.orderId}
            className='align-item-center'
            sortingIcons={[
              IconSortingNormal,
              IconSortingUp,
              IconSortingDown,
            ]}
            sortingKey={'order_number'}
            sortingDisabled={false}
            {...this.sortingProps}
          />
          <TableCellnew
            header
            sortingData={sortingData}
            content={Strings.placedDate}
            className='align-item-center'
            sortingIcons={[
              IconSortingNormal,
              IconSortingUp,
              IconSortingDown,
            ]}
            sortingKey={'created_at'}
            sortingDisabled={false}
            {...this.sortingProps}
          />
          <TableCellnew
            header
            sortingData={sortingData}
            content={Strings.customer}
            className='align-item-center'
            sortingIcons={[
              IconSortingNormal,
              IconSortingUp,
              IconSortingDown,
            ]}
            sortingKey={'customer'}
            sortingDisabled={false}
            {...this.sortingProps}
          />
          <TableCellnew
            header
            sortingData={sortingData}
            content={Strings.contact}
            className='align-item-center'
            sortingIcons={[
              IconSortingNormal,
              IconSortingUp,
              IconSortingDown,
            ]}
            sortingKey={'full_phone_number'}
            sortingDisabled={false}
            {...this.sortingProps}
          />
          <TableCellnew
            header
            content={Strings.order}
            className='align-item-center'
            sortingDisabled
            {...this.sortingProps}
          />
          <TableCellnew
            header
            content={Strings.pcs}
            className='align-item-center'
            sortingDisabled
            {...this.sortingProps}
            style={{minWidth:"50px"}}
          />
          <TableCellnew
            header
            content={Strings.notes}
            className='align-item-center'
            sortingDisabled
            {...this.sortingProps}
          />
          <TableCellnew
            header
            content={Strings.paid}
            className='align-item-center'
            sortingDisabled
            {...this.sortingProps}
          />
          <TableCellnew
            header
            sortingData={sortingData}
            content={Strings.total}
            className='align-item-center'
            sortingIcons={[
              IconSortingNormal,
              IconSortingUp,
              IconSortingDown,
            ]}
            sortingKey={'total'}
            sortingDisabled={false}
            {...this.sortingProps}
          />
          <TableCellnew
            header
            sortingData={sortingData}
            content={Strings.status}
            className='align-item-center'
            sortingIcons={[
              IconSortingNormal,
              IconSortingUp,
              IconSortingDown,
            ]}
            sortingKey={'internal_status'}
            sortingDisabled={false}
            {...this.sortingProps}
          />
        </TableRownew>
      </RowContainer>
    )
  }

  sendToDriverTableHeaderRender(sortingData: ISortingData, rows: IOrderData[]) {
    const hiddenOrders = rows.filter(
      (item: IOrderData) => item.attributes.is_quick_drop
    ).length;

    const condition1 =
      rows.length - hiddenOrders === this.state.selectedOrders.length;
    const isAllSelected = condition1 && this.state.selectedOrders.length > 0;

    return (
      <RowContainer header>
        <TableRownew
          className='table-cols'
          columns={'0.5fr 1fr 0.7fr 1fr 1fr 1fr'}
        >
          <TableCellnew
            header
            content={
              <SelectAllContainer>
                <StyledImageCheckbox
                  src={this.getConditionBased(isAllSelected,CheckTrue,CheckFalse) as string}
                  data-test-id='field-selectAll'
                  onClick={() => this.onSelectAllSendToDriver(rows)}
                />
                <div>All</div>
              </SelectAllContainer>
            }
            className='align-item-center'
            type='left'
            sortingDisabled
            {...this.sortingProps}
          />
          <TableCellnew
            header
            content='ID'
            type='middle'
            sortingDisabled
            {...this.sortingProps}
          />

          <TableCellnew
            header
            content='Pieces'
            sortingDisabled
            {...this.sortingProps}
          />

          <TableCellnew
            header
            content='Order#'
            type='middle'
            sortingDisabled
            {...this.sortingProps}
          />

          <TableCellnew
            header
            content='Customer'
            sortingIcons={[
              IconSortingNormal,
              IconSortingUp,
              IconSortingDown,
            ]}
            sortingKey={'customer'}
            sortingDisabled={false}
            {...this.sortingProps}
          />

          <TableCellnew
            header
            content='Notes'
            sortingDisabled
            {...this.sortingProps}
          />
        </TableRownew>
      </RowContainer>
    )
  }

  renderSendToDriverTableBody(rows: IOrderData[]) {
    return (
      <RowContainer>
        {rows.map((rowData: IOrderData, index: number) => {
          if (rowData.attributes.is_quick_drop) return <></>;

          return (
            <TableRownew
              key={index + "TableRow"}
              className='table-rows'
              columns={"0.5fr 1fr 0.7fr 1fr 1fr 1fr"}
            >
              <TableCellnew
                type='left'
                content={
                  <div style={{paddingLeft: 9}}> 
                    <StyledImageCheckbox
                      src={this.getConditionBased(this.checkIsOrderSelect(rowData),CheckTrue,CheckFalse) as string}
                      data-test-id={`singleCheckbox${index}`}
                      onClick={() => this.onSelectOrder(rowData)} 
                    />
                  </div>
                }
              />

              <TableCellnew
                type='middle'
                content={this.renderOrderNumber(rowData.attributes?.order_number, rowData.attributes?.order_type)}
              />

              <TableCellnew
                type='middle'
                content={rowData.attributes?.total_pieces}
              />

              <TableCellnew
                type='middle'
                content={this.getOrderDetails(rowData)}
              />

              <TableCellnew
                type='middle'
                content={rowData.attributes?.customer?.full_name || "--"}
              />

              <TableCellnew
                type='right'
                content={showNotes(rowData.attributes?.notes)}
              />
            </TableRownew>
          );
        })}
      </RowContainer>
    )
  }

  
  showDateCreatedRender = (created_at: string,rowData: IOrderData) => {
    if(created_at){
      return (
        <div>
          <div>
            {this.handleMomentDateFormat(created_at, "DD/MM/YYYY")}
          </div>
          <div>
            {this.handleMomentDateFormat(created_at, "h:mm A")}
          </div>
          <div>
            {rowData.attributes.order_created_by.first_name}
          </div>
        </div>
        
      )
    }
  }

  showPaidDetails = (rowData: IOrderData) => {
    const isPaymentRecievedBy = rowData.attributes.paid_at && rowData.attributes.payment_received_by
    const isPaidAt = rowData.attributes.paid_at ? true : false
   return (
    <div>
    <div>
      {(rowData.attributes?.[rowData.attributes?.paid ? 'paid' : 'order_transactions'] || [])
      .map((object) => object.name)
      .join(", ") || "---"}</div>
      <div>
        {rowData.attributes.paid_at && this.handleMomentDateFormat(rowData.attributes.paid_at, "DD/MM/YYYY")}
    </div>
    <div>{rowData.attributes.paid_at && this.handleMomentDateFormat(rowData.attributes.paid_at, "h:mm A")}</div>    
    {
    renderBaseOnConditions(
        isPaidAt,
        <div>{isPaymentRecievedBy  ? rowData.attributes.payment_received_by : rowData.attributes.order_created_by.first_name}</div>,
        <></>
      )
    }
  </div>
   )
  }

  renderOrderTableBody(rows: IOrderData[]) {
    return (
      <RowContainer>
        {rows.map((rowData: IOrderData, index: number) => {
          const isEditable = !Boolean(rowData.attributes.send_to_zatca);
          const { paid_at, internal_status, is_quick_drop } = rowData.attributes;
          const payStatusData = { color: '#DC2626', label: 'Unpaid', component: <CustomRadioUnpaid
          checked
        /> }
          if(!is_quick_drop && paid_at) {
            payStatusData.color = '#059669'
            payStatusData.label = 'Paid'
            payStatusData.component = <CustomRadioPaid
            checked
          />
          }
          return (
            <React.Fragment key={"orders-mangement" + index}>
              <TableRownew
                className='table-rows'
                columns={this.getTableRowColumns()}
                data-test-id ="table-rows"
              >
                {
                  this.getConditionBased(
                    this.state.showCheckBoxAndButton,
                    <TableCellnew 
                      type='none'
                      className='pl-0 align-self-center'
                      content={
                        <>         
                        <img
                          style={{ cursor: "pointer", border: "1px solid #64748B",
                            borderRadius: "6px" }}
                          data-test-id={`singleCheckbox${index}`}
                          onClick={() =>
                            this.handleSingleCheck(rowData.id)
                          }
                          alt={this.getConditionBased(rowData.checked as boolean,"checked","unchecked") as string}
                          src={this.getConditionBased(rowData.checked as boolean,CheckTrue,CheckFalse) as string}
                        />
                        </>
                      }
                    />,
                    <></>
                  )
                }
                {
                  this.getConditionBased(
                    this.state.selectedDropdownvalue === "rewash",
                    <TableCellnew
                      type='none'
                      className='pl-0 align-self-center'
                      content={
                        <img
                          style={{ cursor: "pointer" }}
                          data-test-id={`singleRadioButton${index}`}
                          onClick={() =>
                            this.handleSelectOrderToRewash(rowData.id)
                          }
                          src={this.getConditionBased(rowData.checked as boolean,radio_blue,radio_white) as string}
                          alt={this.getConditionBased(rowData.checked as boolean,'checked','unchecked') as string}
                        />
                      }
                    />,
                    <></>
                  )
                }
                <TableCellnew
                  content={<Box className="orderIdColumn">
                    <Box style={{ wordBreak: "break-all" }}>
                      {rowData.attributes?.order_number}
                    </Box>
                    {rowData.attributes?.order_type === "B2bOrder" && 
                    <Box style={{ wordBreak: "break-all", textAlign: "center"}}>
                      <StatusButton>B2b</StatusButton>
                    </Box>
                    }
                    {
                      configJSON.payBtnTabs.includes(internal_status) && (
                        <StatusDiv>
                          {payStatusData.component}
                          <Box className="labelPaidUnpaid" style={{
                            color: payStatusData.color
                          }}>{payStatusData.label}</Box>
                          </StatusDiv>
                      )
                    }
                  </Box>}
                  type={'left'}
                />
                <TableCellnew
                  dataid={configJSON.placedDateTest}
                  style={{ wordBreak: "break-word", minWidth:"80px" }}
                  content={
                    this.showDateCreatedRender(rowData.attributes.placed_at as string,rowData)
                  }
                  type={'middle'}
                />
                <TableCellnew
                  style={{ wordBreak: "break-all", wordSpacing:"100vw", minWidth:'100px' }}
                  content={rowData.attributes?.customer?.full_name || "--"}
                  type={'middle'}
                />
                <TableCellnew
                  style={{ wordBreak: "break-all"}}
                  content={rowData.attributes?.customer?.full_phone_number || "--"}
                  type={'middle'}
                  className="phoneNumber"
                />
                <TableCellnew
                  content={this.getOrderDetails(rowData)}
                  type={'middle'}
                />
                <TableCellnew
                  style={{ wordBreak: "break-all", minWidth:"100px"}}
                  content={rowData.attributes?.total_pieces}
                  type={'middle'}
                  className="pcsCell"
                />
                <TableCellnew
                  style={{ wordBreak: "break-all" }}
                  content={showNotes(rowData.attributes?.notes)}
                  type={'middle'}
                />
                <TableCellnew
                  content={
                    this.showPaidDetails(rowData)
                  }
                  type={'middle'}
                />
                <TableCellnew
                  type='middle'
                  content={
                    <div className="columnContainer">
                      <div className="total">
                        {rowData.attributes?.currency}{" "}
                        {rowData.attributes?.total || "--"}
                      </div>
                      {this.getStatusOfOrder(rowData.attributes?.internal_status) != "" &&
                        <div style={webStyle.ButtonGroup as React.CSSProperties}>
                          {rowData.attributes?.internal_status == 'ready' && <StatusButton
                            data-test-id={`statusBtn-rewash`}
                            onClick={() => this.handleStatusChange({ ...rowData, attributes: { ...rowData.attributes, internal_status: 'delivered' } })}
                          >
                            {this.getStatusOfOrder('delivered')}
                          </StatusButton>}
                          {
                            renderBaseOnConditions(
                              rowData.attributes?.internal_status == "cleaning",
                              renderBaseOnConditions(
                                rowData.attributes?.status_plant == "dispatched" &&
                                rowData.attributes.status == "placed",
                                <StatusButton
                                  data-test-id={`statusBtn-${rowData.attributes?.id}`}
                                  onClick={() => this.handleStatusChange(rowData)}
                                >
                                  {this.getStatusOfOrder("cleaning")}
                                </StatusButton>,
                                <></>
                              ),
                              <StatusButton
                                  data-test-id={`statusBtn-${rowData.attributes?.id}`}
                                  onClick={() => this.handleStatusChange(rowData)}
                                >
                                  {this.getStatusOfOrder(rowData.attributes?.internal_status)}
                              </StatusButton>
                            )
                          }
                        </div>
                      }
                    </div>
                  }
                />
                <TableCellnew
                  type="right"
                  content={
                    <div className="columnContainer">
                      <div style={webStyle.StatusSpan as React.CSSProperties} className="order">
                        {this.showStatusOnOrder(rowData)}
                      </div>
                      <div className="statusColumnAction">
                        { paid_at === null && !is_quick_drop && configJSON.payBtnTabs.includes(internal_status) && <StatusButton data-test-id="pay-btn" onClick={() => { this.handleDirectPaymentModalVisible(rowData)}}>Pay</StatusButton>}
                        {renderBaseOnConditions(this.showEditOrderButton(rowData) && isEditable && this.state.permissionStatus.editOrderPermission,
                          <img
                           width={26}
                           height={26}
                            src={editIcon}
                            alt={"edit"}
                            data-test-id={`EditIcon-${rowData.id}`}
                            style={{
                              cursor: "pointer",
                              width: "26px",
                            }}
                            onClick={() =>
                              this.handleEditClick(
                                rowData
                              )
                            }
                          />, <></>
                        )}
                        {
                          renderBaseOnConditions(
                            this.showPrintButton(),
                            <img
                              width={26}
                              height={26}
                              src={printIcon}
                              alt={"print"}
                              style={{
                                cursor: "pointer",
                                width: "26px",
                              }}
                              data-test-id='btn-print'
                              onClick={() =>
                                this.openPrinterDialog(rowData)
                              }
                            />,
                            <></>
                          )
                        }
                        {this.getCreditNoteButton(rowData)}
                      </div>
                    </div>
                  }
                />
              </TableRownew>
            </React.Fragment>
          );
        })}
      </RowContainer>
    )
  }

  getCreditNoteButton(item: IOrderData) {
    if (
      !item?.attributes?.credit_note
      ||
      !item?.attributes?.paid_at
    ) return <></>;
    const isCreditOrder = item.attributes.credit_order_at
    const { permissionStatus } = this.state;
    return (
      <>
      {renderBaseOnConditions(
        isCreditOrder !== null , 
        <>
        {permissionStatus.viewCreditOrderAccess && <div>
        <div className='credit-note-show-button'>
         <button
          onClick={() => this.toggleCreditNoteDialog(item)}
          data-test-id={"credit-note-button-" + item.id}
        >
          <img src={creditNoteIcon} /><span style={{marginTop: "3px"}}>{configJSON.credit_note_view_button}</span>
        </button>
      </div>
      </div>}
      </>
      ,
      <>
     {permissionStatus.createCreditNoteAccess &&  <div>
        <div className={'credit-note-button'}>
         <button
          onClick={() => this.toggleCreditNoteDialog(item)}
          data-test-id={"credit-note-button-" + item.id}
        >
          <img src={creditNoteIcon} /><span style={{marginTop: "3px"}}>{configJSON.credit_note_button}</span>
        </button>
      </div>
      </div>}
      
      </>)}
      </>
    );
  }


  renderCreditNoteDialog() {
    const {
      addNote,
      creditNoteOrder,
      isCreditNoteDialogOpen,
      isConfirmationChecked,
      creditNoteReceipt,
      openCrediNoteModalLoader,
      hidden
    } = this.state;

    const {
      adjustment_reason, adjustment_note,
      order_number,
      total_tax,
      sub_total,
      currency, total,
    } = creditNoteOrder?.attributes || {};
    
    return (
      <StyledDialogWrapper
        fullWidth
        maxWidth={"md"}
        data-test-id='dialog'
        className='mainDialog'
        open={isCreditNoteDialogOpen}
      >
        <Typography className='headerTitle' variant='h4' align='center'>
          {configJSON.stringsCreditNotePage_CreateCreditNoteHeader}
        </Typography>
        <CustomLoader loading={this.state.openCrediNoteModalLoader} />
        <DialogContent className='dialogContent'>
          <Box className="credit-note-modal-header">
            <Typography className='orderId'> Order ID : {this.renderCreditOrderNumber(order_number)}</Typography>
            {renderBaseOnConditions(
              hidden && !openCrediNoteModalLoader,
              <Typography className='orderId'> Reference ID : {order_number} </Typography>,
              <></>
            )}
          </Box>
          <TextLabel>
            {configJSON.stringsCreditNotePage_AdjustMentReason}
          </TextLabel>
          <StyledSelectAdjustmentReason
            variant='outlined'
            id='adjustmentReasonId'
            name='adjustmentReasonId'
            value={adjustment_reason}
            MenuProps={CustomMenuProps}
            data-test-id='adjustmentReasonId'
          >
            <MenuItem value={adjustment_reason || ""}>
              {adjustment_reason}
            </MenuItem>
          </StyledSelectAdjustmentReason>

          <br></br>
          <br></br>
          <Grid>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography className='tableHeading' variant='h4'>
                {configJSON.stringsCreditNotePage_table_heading}
              </Typography>
              <button
                  disabled={!creditNoteReceipt}
                  className='printBtn'
                  data-test-id='btn-print-credit-note'
                  onClick={() => this.hanldeClickPrintCreditNote()}
              >
                <img src={IconBluePrint} />
                {configJSON.stringsListPage_print}
              </button>
            </Box>
            

            <br></br>
            {this.renderOrderDetailTable()}
          </Grid>

          <br></br>
          <br></br>

          <PriceGrid
            container
            direction='column'
            justifyContent='flex-end'
            alignItems='flex-end'
            className="total_price"
          >
            <Grid>
              Total : {currency} {sub_total}
            </Grid>
            <Grid> Tax : {currency} {total_tax}</Grid>
            <Grid>
              Total inc. Tax: {currency} {total}
            </Grid>
          </PriceGrid>

          <Typography className='adjustmentDetails' variant='h6'>
            {configJSON.stringsCreditNotePage_AdjustMentDetails}
          </Typography>
          <Typography className='addedNewUpcharge' variant='h6'>{adjustment_note}</Typography>
          <TextareaAutosize minRows={2}
            value={addNote}
            className='textAreaStyles'
            onChange={this.handleAddNote}
            data-test-id='creditNotTextInput'
            disabled={this.state.creditNotePresent}
            placeholder={configJSON.stringsCreditNotePage_CreditNote_Add_Note}
          />
        </DialogContent>

        <div className='confirm-text-container'>
          <img
            alt='checkbox'
            data-test-id='confirmationBox'
            onClick={this.handleConfirmationCheckBox}
            src={isConfirmationChecked ? CheckTrue : CheckFalse}
          />
          <span className='confirmationText'>
            {configJSON.stringsCreditNotePage_ConfirmationText}
          </span>
        </div>

        <Box className='groupButton'>
          <Grid container direction='row' spacing={1}>
            <Grid item xs={12} md={6}>
              <Button disableRipple
                className='cancelButton'
                data-test-id='cancel-btn-customer-preference'
                onClick={() => this.toggleCreditNoteDialog()}
              >
                {configJSON.stringsCreditNotePage_CancelButton}
              </Button>
            </Grid>
            {this.state.hidden === false && (
              <Grid item xs={12} md={6}>
                <Button disableRipple
                  className='addButton'
                  onClick={() => this.createCreditNoteApiCall()}
                  disabled={!this.state.isConfirmationChecked || this.state.creditLoader}
                  data-test-id='create-button-customer-preference'
                >
                 {this.state.creditLoader ?
                  <CircularProgress style={{ width: 24, height: 24, color: "white" }} />
                  :
                  configJSON.stringsCreditNotePage_CreateCreditNoteButton
                }
                  
                </Button>
              </Grid>
             )} 
          </Grid>
        </Box>
      </StyledDialogWrapper>
    );
  }


  renderCreditOrderNumber = (order_number: string | undefined) => {
    const { hidden, creditNoteOrderNumber, openCrediNoteModalLoader } = this.state;
    if(openCrediNoteModalLoader) {
      return "";
    } else {
      if(!hidden) {
        return order_number;
      } else {
        return creditNoteOrderNumber;
      }
    }
  };

  renderOrderDetailTable() {
    const { customer, currency, created_at, total, tax_percentage } =
      this.state.creditNoteOrder?.attributes || {};

    return (
      <>
      <MainWrapper>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <MuiTableRow className='tableheaderRow'
                style={{ color: colors().background, background: "#F8FAFC" }}
              >
                <MuiTableCell className="tableHeader">Customer Name</MuiTableCell>
                <MuiTableCell className="tableHeader">Order Creation Date</MuiTableCell>
                <MuiTableCell className="tableHeader">Total Order Amount</MuiTableCell>
                <MuiTableCell className="tableHeader">Tax</MuiTableCell>
                <MuiTableCell className="tableHeader">Adjustment Amount</MuiTableCell>
                <MuiTableCell className="tableHeader">Refund Amount</MuiTableCell>
              </MuiTableRow>
            </TableHead>
            <TableBody data-test-id='tblBody'>
              <MuiTableRow>
                <MuiTableCell>
                  {customer?.full_name}
                </MuiTableCell>
                <MuiTableCell>
                  {this.getMomentFormat(String(created_at))}
                </MuiTableCell>
                <MuiTableCell>{total}</MuiTableCell>
                <MuiTableCell>
                  <CreditNoteInput
                    value={`${tax_percentage} %`}
                    className='tax' readOnly
                  />
                </MuiTableCell>
                <MuiTableCell>
                  <CreditNoteInput
                    value={currency + " " + total}
                    className='amount'
                    readOnly
                  />
                </MuiTableCell>
                <MuiTableCell>{currency + " " + total}</MuiTableCell>
              </MuiTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MainWrapper>
      </>
    );
  }

  renderTableContainer(isSendToDriver: boolean, rows: IOrderData[], sortingData: ISortingData, isDrawerOpen: boolean) {
    return (
      <OrderTable minWidth={1280} minWidth1024={887} minWidth1280={1109} minWidth1366={1184}>
        {!isSendToDriver && this.ordersTableHeaderRender(sortingData)}
        <div>
        {!isSendToDriver && rows.length > 0 && this.renderOrderTableBody(rows)}
        </div>
        {isSendToDriver && this.sendToDriverTableHeaderRender(sortingData, rows)}
        <SendToDriverBody>
        {isSendToDriver && rows.length > 0 && this.renderSendToDriverTableBody(rows)}
        </SendToDriverBody>
        {rows.length === 0 && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: 601,
              fontSize: 16,
            }}
          >
            No records found!!
          </div>)}
      </OrderTable>
    )
  }

  renderTableSubInfoHeader(isSendToDriver: boolean, rows: IOrderData[]) {
    // if(this.state.isLoadingOrderList) {
    //     <Box sx={{display: isSendToDriver ? "none" : "block", marginBottom:"24px"}}>
    //       <Skeleton animation="wave" variant="rect" width={"full"} height={51} />

    return (
      rows.length > 0 && (
        <Box
          sx={{
            ...webStyle.summary, flexDirection: { xs: 'column', sm: 'unset' },
            display: isSendToDriver ? "none" : "flex"
          }}
        >
          <div className={"summaryLabel"}>
            <span>
              {Strings.orders}{" "}
              <span
                data-test-id='orderValue'
                style={webStyle.summaryValue}
              >
                : {this.state.orderMetaData.orders}
              </span>
            </span>
          </div>
          <div className={"summaryLabel"}>
            <span>
              {Strings.pieces}{" "}
              <span style={webStyle.summaryValue}>
                : {this.state.orderMetaData.pieces}
              </span>
            </span>
          </div>
          <div className={"summaryLabel"}>
            <span>
              {Strings.value}{" "}
              <span style={webStyle.summaryValue}>
                : {this.state.orderMetaData.currency}{" "}
                {this.state.orderMetaData.total}
              </span>
            </span>
          </div>
          <div className={"summaryLabel"}>
            <span>
              {Strings.unpaidAmount}{" "}
              <span
                data-test-id='unpaidValue'
                style={webStyle.summaryValue}
              >
                : {this.state.orderMetaData.currency}{" "}
                {this.state.orderMetaData.unpaid_amount}
              </span>
            </span>
          </div>
        </Box>
      ) 
    )
  }

  renderAfterPermissionComponent() {
    return (
      <div style={{ minWidth: "200px", margin: "0 12px" }}>
        <AutocompleteSelect
          placeholder="Select branch"
          fieldName={"branch"}
          disableClear
          onChange={(_item: React.ChangeEvent, value: { id: string; option: string }) => {
            this.handleStoreId(value);
            this.handleTabChange(1);
          }}
          handleScroll={this.handelStoreDropdownScroll.bind(this)}
          onBlur={() => this.debouncedStoreSearch("")}
          debouncedFunction={this.debouncedStoreSearch}
          value={this.state.storeId}
          options={this.state.storeList}
          listBoxStyle={{maxHeight: 310}}
        />
      </div>
    )
  }

  renderCustomSnackbar = () => this.state.errorMessage || this.state.snackbarMessage ?
    <Portal>
      <CustomSnackbar
        data-test-id="custom-error-message"
        errorMessage={this.state.errorMessage || this.state.snackbarMessage}
        open
        severity={(this.state.snackBarSeverity as "success") || "error"}
        onClose={this.closeCustomSnackbar}
      />
    </Portal>
      : null
  
  renderDriverReceiptDialog = () => {
    const {driverReceipt, showDriverReceiptConfirmation} = this.state
    if (showDriverReceiptConfirmation) return (
      <ConfirmationDialog 
        title="Print Driver Receipt" 
        open
        displayError={!driverReceipt}
        handleClose={() => this.handleDriverReceiptDialog()}
        customMessage={driverReceipt ? "Do you want the print the driver receipt?" : "Unable to get driver receipt for printing"}
        confirmText="OK"
        handleConfirm={() => this.handleDriverReceiptDialog(Boolean(driverReceipt))}
        dynamic={Boolean(driverReceipt)}
      />
    )
    if (this.state.driverReceipt && !this.state.showDriverReceiptConfirmation) return (
      <PrintModal
        onSubmit={this.handlePrintDriverReceipt}
        onCancel={() => this.handleDriverReceiptDialog()}
        isSendToDriver
      />
    )

    return null;
  }

  renderButtonLoadingText = () => {
    if(this.state.isButtonLoading) {
      return <CircularProgress style={{ width: 16, height: 16, color: "white" }}/>
    } else {
      return "Submit"
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortingData } = this.state;
    const rows = [...this.state.ordersList];
    const CustomMenuProps: Partial<MenuProps> = {
      PaperProps: {
        style: { maxHeight: 250, maxWidth:"100%" },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };
    const { tabId, isDrawerOpen } = this.state;

    const isSendToDriver =
      this.state.selectedDropdownvalue === "Send to driver";

    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={webStyle.mainWrapperOrder}>
          <StyledWrapper className="pageWrapper">
            <CustomLoader loading={this.state.isScanningOrder} />
            {this.renderOrderUnpaidDialog()}
            <Snackbar
              open={this.state.sendToDriverError.length > 0}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              data-test-id='snackbar'
              key='send-driver-error'
              onClose={this.closeAlertError}
            >
              <Alert
                onClose={() => this.closeAlertError()}
                severity={"error"}
              >
                {this.state.sendToDriverError}
              </Alert>
            </Snackbar>
            { this.renderDriverReceiptDialog() }
            {this.state.orderToPrint && (
              <PrintModal
                onSubmit={this.handleSubmitPrintDialog}
                onCancel={() => this.closePrinterDialog()}
              />
            )}
            <Box className="pageWrapper">
              <Box>
                {this.renderCustomSnackbar()}
                <Box textAlign="end">
                  <BarcodesScanner 
                    openDialog={!this.state.orderDetailPopup} 
                    navigation={this.props.navigation} 
                    id=""
                    onSubmit={this.scanOrder}
                    loading={this.state.isScanningOrder}
                  />
                </Box>
                
                <StyledAppBar position='static' style={{ margin: "0",display:'none' }}>
                  <Tabs
                    data-test-id={configJSON.tabOrderTypeID}
                    value={this.state.selectedTab}
                    onChange={this.handleTabSeletion}
                    centered
                    className="typeTabs"
                  >
                    {[configJSON.plantCleaningTxt].map(
                      (data) => {
                        return (
                          <CustomTypeTab
                            label={data}
                            value={data}
                            key={data}
                          />
                        );
                      }
                    )}
                  </Tabs>
                </StyledAppBar>
              </Box>
              <TabWrapper>
                <div className="pageHeaderOrder">
                  {!isSendToDriver
                    ? [
                      configJSON.OrdersTab.orders,
                      configJSON.OrdersTab.store,
                      configJSON.OrdersTab.cleaning,
                      configJSON.OrdersTab.ready,
                      configJSON.OrdersTab.delivered,
                      configJSON.OrdersTab.deadstock,
                      configJSON.OrdersTab.cancelled
                    ][tabId]
                    : `${Strings.sendToDriver}`}
                </div>

                {!isSendToDriver && (
                  <TabContainer isDrawerOpen={this.state.isDrawerOpen}>
                    <Paper style={webStyle.tabbingSection}>
                      <CustomTabs
                        className={"CustomTabsStyle"}
                        data-test-id='tabGroup'
                        value={this.state.tabId}
                        onChange={(_event: React.ChangeEvent, value: number) => {
                          this.handleTabChange(value);
                        }}
                        variant={this.handleStatusScroll(this.state.isDrawerOpen)}
                        scrollButtons="on"
                        indicatorColor='primary'
                        textColor='primary'
                        aria-label='icon label tabs example'
                        TabIndicatorProps={{ style: { display: "none" } }}
                      >
                        <CustomTab
                          className={"customTabStyle"}
                          label='Coming to Store'
                          value={0}
                        />
                        <CustomTab value={1} data-test-id="storeTab" className={"customTabStyle"} label='Store' />
                        <CustomTab value={2} className={"customTabStyle"} label='Cleaning' />
                        <CustomTab value={3} className={"customTabStyle"} label='Ready' />
                        <CustomTab value={4} className={"customTabStyle"} label='Delivered' />
                        <CustomTab value={5} className={"customTabStyle"} label='Deadstock' />
                        <CustomTab value={6} className={"customTabStyle"} label='Cancelled' />
                      </CustomTabs>
                    </Paper>
                  </TabContainer>
                )}
                <div className={"head2-3rd-section"}>
                  <div className={"actionSelect"}>
                    <FormControl
                      variant='outlined'
                      style={webStyle.formControl}
                    >
                      <StyledSelect
                        value={this.state.selectedDropdownvalue}
                        renderValue={() =>
                          this.state.dropDownList.find(
                            (option: Option) =>
                              option.value === this.state.selectedDropdownvalue
                          )?.label || "Action"
                        }
                        data-test-id='action'
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          this.handleDropDownSelect(event);
                        }}
                        displayEmpty={true}
                        variant='outlined'
                        MenuProps={CustomMenuProps}
                      >
                        <StyledMenuItem value=''>
                          <em>Action</em>
                        </StyledMenuItem>
                        {this.state.dropDownList.map(
                          (option) =>
                            (option.tabId === this.state.tabId && option.permission) && (
                              <StyledMenuItem value={option.value}>
                                {option.label}
                              </StyledMenuItem>
                            )
                        )}
                      </StyledSelect>
                    </FormControl>
                  </div>
                  <div style={webStyle.headerButtonPartAccountOrder}>
                    <FilterBtn
                      data-test-id='filterButton'
                      onClick={(event) => {
                        this.openFilterPopover(event);
                        this.handleCallSectionListAPI()
                        this.handleCallPaymentListAPI()
                      }}
                      className={sortStringCondition(
                        this.state.isAppliedFilter,
                        "active-border",
                        ""
                      )}
                    >
                      <FilterIcon color={this.handleReturnColorType()} />
                      {renderBaseOnConditions(
                        this.state.isAppliedFilter,
                        <div className="filterActiveIcon"></div>,
                        <></>
                      )}
                    </FilterBtn>
                    <FilterPopover
                      onClearFilter={() => {
                        this.clearFilterPopOver();
                      }}
                      onAutoCompleteChange={(title: string, value: string) => {
                        this.filterAutoCompleteSuggestionListByTitle(title, value);
                      }}
                      onClose={() => {
                        this.closeFilterPopover();
                      }}
                      anchor={this.state.filterAnchor}
                      onFilterChange={this.handleFilterChange}
                      refreshFilterState={(filters: IFilter[]) =>
                        this.handleSetStateFilter(filters)
                      }
                      filters={this.state.filters}
                    />
                  </div>
                </div>
              </TabWrapper>
              {this.renderTableSubInfoHeader(isSendToDriver, rows)}
              
              <Box style={{overflowY: "auto",flex: "1 1 0"}}>
              {this.renderTableContainer(isSendToDriver, rows, sortingData, isDrawerOpen)}
              </Box>

              {rows.length > 0 && (
                <TablePagination
                  data-test-id='pagination'
                  rowsPerPageOptions={[10, 20, 30]}
                  component='div'
                  count={this.state.orderMetaData?.total_count || 1}
                  rowsPerPage={this.state.pageSize}
                  page={this.state.page - 1}
                  onRowsPerPageChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    this.handleTableRowsPerPage(event)
                  }}
                  onPageChange={(event, page) => {
                    this.handlePageChange(page + 1);
                  }}
                />
              )}
            </Box>

            {isSendToDriver && (
              <div
                className="sendToDriverActions"
              >
                <FormControl variant='outlined' style={webStyle.formControl} className="selectBox">
                  <StyledSelect
                    renderValue={(value) =>
                      this.state.driverList.find(
                        (option: Option) =>
                          option.value === this.state.selectedDriver
                      )?.label || "Send To"
                    }
                    data-test-id='sendTo'
                    className={"sentToDropdwon"}
                    displayEmpty={true}
                    style={webStyle.inputField}
                    variant='outlined'
                    onChange={(event) =>
                      this.handleSelectedDriver(event)
                    }
                    MenuProps={CustomMenuProps}
                  >
                    <StyledMenuItem value=''>
                      <em>Send To</em>
                    </StyledMenuItem>
                    {this.state.driverList.map((option) => (
                      <StyledMenuItem value={option.value} key={option.value}>
                        {option.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>

                <BottomContainer>
                  <button
                    data-test-id='btn-cancel'
                    className='cancel'
                    onClick={() => this.handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    data-test-id='btn-submit'
                    className='add'
                    onClick={() => this.onClickSendToDriverSubmit()}
                  >
                    { this.renderButtonLoadingText() }
                  </button>
                </BottomContainer>
              </div>
            )}
            {this.checkSelectDropdownValue() && (
              <div
                style={{
                  width: 254,
                  marginLeft: 48,
                  paddingTop: 32,
                }}
              >
                <BottomContainer style={{ paddingTop: 23, paddingBottom: 48 }}>
                  <button
                    data-test-id='btn-cancel-clean'
                    className='cancel'
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    data-test-id='btn-submit-clean'
                    className='add'
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onClickSendToCleanSubmit()}
                  >
                    {this.renderButtonText()}
                  </button>
                </BottomContainer>
                <br />
              </div>
            )}
            {this.renderCreditNoteDialog()}
          </StyledWrapper>

        {this.state.orderDetailPopup && (
          <OrderDetailsPage
            onRefetchOrderList={() => this.getOrdersList()}
            onClose={this.onCloseDetailsScreen.bind(this)}
            storeId={String(this.state.storeId)}
            onMoveToStore={() => this.handleTabChange(1)}
            orderData={this.state.orderData}
            isNewOrderDetailsModal={this.state.selectedTab === configJSON.plantCleaningTxt ? false : true}
          />
        )}
         {renderBaseOnConditions(
            this.state.paymentModalVisible,
              <PaymentModal
              fromUnpaid={true}
              data-test-id='paymentmodal'
              onPromoCodeApply={() => this.getOrder()}
              open={this.state.paymentModalVisible}
              handleClose={this.handlePaymentModalClose}
              totalItems={this.state.order?.attributes?.order_items.length || 0}
              taxPercentage={5}
              onPaymentDone={this.handlePaymentDone}
              customerId={this.state.customerId}
              order={this.state.order?.attributes}
              paymentClicked={this.state.paymentClicked}
            />,
            <></>
          )}

          {renderBaseOnConditions(
            this.state.showDirectPaymentModal,
            <PaymentModal
              fromUnpaid={true}
              data-test-id='paymentmodal1'
              onPromoCodeApply={() => this.getOrderDetail()}
              open={this.state.showDirectPaymentModal}
              handleClose={this.handleSinglePaymentClose}
              totalItems={this.state.selectedOrder?.attributes?.order_items.length || 0}
              taxPercentage={this.formatTaxPercentage()}
              onPaymentDone={this.handleSinglePaymentDone}
              customerId={String(this.state.selectedOrder?.attributes.account_id)}
              order={this.state.selectedOrder?.attributes as unknown as IOrder}
              paymentClicked={this.state.ispaymentClicked}
          />,
            <></>
          )}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  table: {
    minWidth: 700,
  },
  mainWrapperOrder: {
    fontFamily: "Montserrat",
  },
  headerWrapperOrder: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },

  headerButtonPartAccountOrder: {
    display: "flex",
  },
  
  summary: {
    display: "flex",
    padding: "16px 32px",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "12px",
    background: "#EC4E20",
    marginBottom: "24px",
    justifyContent: "space-between"
  },
  summaryLabel: {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    display: "inlineBlock",
  },
  summaryValue: {
    fontWeight: 600,
  },
  detailBtn: {
    color: "#204B9C",
    textDecoration: "underline",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "12px",
    cursor: "pointer",
  },
  cellText: {
    fontSize: "12px",
    fontWeight: 500,
    paddingTop: "16px",
  },
  customTab: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    margin: "2px",
  },
  tabbingSection: {
    backgroundColor: "#f8fafc",
    borderRadius: "40px",
    margin: "0 auto",
    boxShadow: "none",
  },
  inputField: {
    width: "100%",
    height: "56px",
    fontFamily: "Montserrat !important",
  },
  formControl: {
    width: "100%",
    borderRadius: "8px",
    fontFamily: "Montserrat",
  },
  unPaidDialogPara: {
    maxWidth: "428px",
    margin: "auto"
  },
  IconImgGroup: {
    display: "flex",
    justifyContent: "center",
    gap: 16,
    alignItems:"center"
  },
  ButtonGroup: {
    display: "flex",
    gap: 16,
    flexWrap: "wrap"
  },
  StatusSpan: {
    textTransform: "capitalize" as const,
  }
};

export const TabWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0",
  // marginBottom:"22px",
  flexWrap: "wrap" as const,
  position: "sticky",
  top: "0px",
  background: "white",
  zIndex: 1,
  "@media only screen and (max-width: 1024px)": {
    marginLeft:"-2rem"
  },
  "& .pageHeaderOrder": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    "@media only screen and (max-width: 1280px)": {
      fontSize: "21px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "17px",
    }
  },
});

export const StyledImageCheckbox = styled("img")({
  padding: 0,
  cursor: "pointer",
  border: "1px solid #64748B",
  borderRadius: "6px"
});

export const StyledMenuItem = styled(MenuItem)({
  "&.MuiListItem-button:hover": {
    backgroundColor: "#204B9C",
    color: "white",
  },
  "@media only screen and (max-width: 1280px)": {
    fontSize: 14,
  },
});

export const TabContainer = styled('div')((props: StyledComponentProps & {isDrawerOpen : boolean}) => {
  let containerWidth = '';

  if (props.isDrawerOpen && window.innerWidth <= 1366) {
      containerWidth = "550px";
  } else if (props.isDrawerOpen && window.innerWidth <= 1560) {
      containerWidth = "610px";
  }

  return {
    "& .MuiTab-root": {
      minWidth: "unset",
      flexGrow: "unset",
      flexBasis: "unset",
      whiteSpace: "nowrap",
    },
    "& .MuiTabs-flexContainer": {
      gap: 4,
      width: containerWidth,
      "@media only screen and (max-width: 1024px)": {
        width: "385px"
      }
    },
  };
});


const StatusButton = styled("button")({
  padding: "4px 10px",
  background: "rgba(32, 75, 156, 0.1)",
  color: "#204B9C",
  outline: "none",
  border: "none",
  borderRadius: "6px",
  cursor: "pointer",
  fontFamily: '"Montserrat", sans-serif',
  fontSize: 13,
  fontWeight: 600,
});

export const StyledSelect = styled(Select)({
  ...webStyle.inputField,
  "& .MuiSelect-select": {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    lineHeight: "24px",
    "@media only screen and (max-width: 1280px)": {
      fontSize: 14,
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
    }
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ECECEC",
  },
  "@media only screen and (max-width: 1280px)": {
    height: "48px",
  },
  "@media only screen and (max-width: 1024px)": {
    height: "40px",
  }
});

const SelectAllContainer = styled("div")({
  gap: 16,
  marginLeft: 9,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .PrivateSwitchBase-root-58": {
    padding: 0,
  },
});

const FilterButton = styled("button")({
  padding: 10,
  minWidth: 56,
  display: "flex",
  borderRadius: 8,
  cursor: "pointer",
  alignItems: "center",
  backgroundColor: "white",
  justifyContent: "center",
  border: "1px solid #ECECEC"
});

export const CustomTypeTab = styled(Tab)({
  textTransform: "none",
  fontFamily: "Montserrat",
  fontSize: "16px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "14px",
    minHeight: "0px"
  }
});

const StyledAppBar = withStyles({
  root: {
    color: colors().darkliver,
    marginTop: "10px",
    backgroundColor: colors().white,
    borderBottom: `1px solid ${colors().lightborder}`,
    boxShadow: "none",
    "& .Mui-selected": {
      color: colors().cyancobaltblue,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${colors().cyancobaltblue} !important`,
    },
    "& .MuiPaper-elevation": {
      boxShadow: "none",
    },
  },
})(AppBar);

export const FilterBtn = styled('div')({
  position: "relative",
  cursor: "pointer",
  background: "#FFFFFF",
  height: "56px",
  color: "#4d4d4d",
  width: "56px",
  fontSize: "24px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #ECECEC",
  "&.active-border": {
    border: "1px solid #204B9C",
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%"
  },
  "@media only screen and (max-width: 1280px)": {
    height: "48px",
    width: "48px",
  },
  "@media only screen and (max-width: 1024px)": {
    height: "40px",
    width: "40px"
  }
});

const StatusDiv = styled("div")({
  padding: "4px 10px",
  outline: "none",
  border: "none",
  borderRadius: "6px",
  marginLeft: -20,
  display: 'flex',
  alignItems: 'center',
  zIndex: 0
});

const CustomRadioUnpaid = styled(Radio)({
  '& .MuiIconButton-label': {
    color: '#DC2626 !important',
    padding: 0
  },
})
const CustomRadioPaid = styled(Radio)({
  '& .MuiIconButton-label': {
    color: '#059669 !important',
    padding: 0
  },
})

const StyledWrapper = styled('div')({
  "& .orderIdColumn": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    minHeight: '163px'
  },
  "& .labelPaidUnpaid": {
    cursor: "pointer",
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 13,
    fontWeight: 500,
  },
  "& .customTabStyle": {
    ...webStyle.customTab,
    "@media only screen and (max-width: 1280px)": {
      fontSize: "14px",
      padding: "0 8px",
      minHeight: "32px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      padding: "0 5px",
      minHeight: "28px"
    }
  },
  "& .CustomTabsStyle": {
    "& .MuiTabs-scroller": {
      display:"flex"
    },
    "& .MuiTabs-scrollButtons": {
      background:"#F8FAFC",
      width: 20
    },
    "@media only screen and (max-width: 1024px)": {
      margin: "15px 0px 15px -100px",
      width:"400px"
    }
  },
  "& .head2-3rd-section": {
    display: "flex",
    gap: 24,
    "@media only screen and (max-width: 1280px)": {
      gap: 18,
    },
    "@media only screen and (max-width: 1024px)": {
      gap: 12,
    }
  },
  "& .actionSelect": {
    minWidth: 180,
    "@media only screen and (max-width: 1366px)": {
      minWidth: 170,
    },
    "@media only screen and (max-width: 1280px)": {
      minWidth: 160,
    },
    "@media only screen and (max-width: 1024px)": {
      minWidth: 140,
    }
  },
  "& .typeTabs": {
    "& .MuiTabs-indicator": {
      "@media only screen and (max-width: 1024px)": {
        height: "1px"
      }
    },
    "@media only screen and (max-width: 1024px)": {
      minHeight: "0"
    }
  },
  "& .summaryLabel": {
    ...webStyle.summaryLabel,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px"
    }
  },
  "& .detailsBtn": {
    ...webStyle.detailBtn,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "14px"
    }
  },
  "& .statusColumnAction": {
    ...webStyle.IconImgGroup,
    "@media only screen and (max-width: 1024px)": {
      gap: "8px"
    }
  },
  "& .align-item-center": {
    alignItems: "center",
  },
  "& .table-rows > div": {
    fontSize: "16px",
    textAlign: "left",
    minHeight: "168px",
    textTransform: "capitalize",
    fontWeight: "400 !important",
    "@media only screen and (max-width: 1366px)": {
      minHeight: "163px"
    },
    "@media only screen and (max-width: 1280px)": {
      minHeight: "158px"
    },
    "@media only screen and (max-width: 1024px)": {
      minHeight: "148px",
      fontSize: "12px"
    }
  },
  "& .columnContainer": {
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "space-between",
    "height": "100%",
    "paddingBottom": "8px",
    "& .total": {
      display:"inline-block",
      overflow:"hidden",
      whiteSpace:"nowrap"
     },
  },
  "& .pcsCell": {
    padding:"1rem 1rem 1rem 2rem",
    "@media only screen and (max-width: 1024px)": {
     padding:"1rem"
    }
  },
  "& .phoneNumber": {
    minWidth:"145px",
    "@media only screen and (max-width: 1024px)": {
      minWidth:"110px",
     }
  },
  "& .sendToDriverActions": {
    display: "flex",
    justifyContent:"space-between",
    gap: 20,
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "16px 0 16px 16px",
    background: colors().ghostwhite,
    boxShadow: "0px 2px 8px 0px #00000014",
    "& > div": {
      paddingTop: "0 !important"
    },
    "& .selectBox": {
      width:"300px !important"
    }
  },
  "& .credit-note-button": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& button": {
      gap: 6,
      border: 0,
      cursor: "pointer",
      display: "flex",
      padding: "6px 10px",
      textAlign: "center",
      alignItems: "center",
      borderRadius: "6px",
      color: colors().cyancobaltblue,
      backgroundColor: colors().cyancobaltblue + "1A",
      fontWeight: 600,
      "& span": {
        "@media only screen and (max-width: 1024px)": {
          fontSize: "11px"
         }
      },
    },
  },
  "& .credit-note-show-button": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& button": {
      display: "flex",
      gap: 6,
      cursor: "pointer",
      border: 0,
      padding: "6px 10px",
      textAlign: "center",
      borderRadius: "6px",
      backgroundColor: colors().cyancobaltblue + "1A",
      color: "gray",
      alignItems: "center",
      fontWeight: 600,
      "& span": {
        "@media only screen and (max-width: 1024px)": {
          fontSize: "11px"
         }
      },
    },
  },
})

const SendToDriverBody = styled("div")({
  maxHeight: "calc(100vh - 425px)",
  overflow:"auto",
  "@media only screen and (max-width: 1280)": {
    maxHeight: "calc(100vh - 410px)",
  },
  "@media only screen and (max-width: 1366px)": {
    maxHeight: "calc(100vh - 420px)",
  },
  "@media only screen and (max-width: 1024px)": {
    maxHeight: "calc(100vh - 350px)",
  },
})

// Customizable Area End
