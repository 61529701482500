// Customizable Area Start
import React from "react";
import {
  Modal,
  Box,
  Typography,
  styled,
  Grid,
  CircularProgress,
  Snackbar,
  InputAdornment
} from "@material-ui/core";
import { Formik } from "formik";
import Alert from "@material-ui/lab/Alert";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { InputField } from "../../../components/src/customComponents/CustomTags.web";
import * as Yup from "yup";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import {
  StyledButton,
  StyledCancelButton,
  StyledInputLabel,
  StyledInputLabelAutoComplete,
  StyleGrid,
} from "./B2BCustomerCreation.web";
import B2BAddCustomerModalController, {
  Props,
  configJSON,
  IB2BCompanyDropDown,
  IB2BCustomerGroupDropdown,
  IBusinessCustomerDropDown,
} from "./B2BCustomerModalController";
// Customizable Area End

export class B2BAddCustomerModal extends B2BAddCustomerModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  finalErrorMessage(error: boolean, touch: boolean) {
    if (error) {
      if (touch) {
        return (
          <div
            style={{
              color: "red",
              fontSize: "14px",
              margin: "5px 5px 5px 0",
              fontFamily: "Montserrat",
            }}
          >
            {error}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return;
    }
  }

  handleReturnPricelistName = () => {
    const { selectedPricelist } = this.state;
    return selectedPricelist ? selectedPricelist.name : "";
  };

  getValidation = () => {
    const phoneRegex: any = '^[0-9+]+$';
    return Yup.object({
      email: Yup.string()
      .trim()
      .notRequired()
      .nullable()
      .email("Invalid email"),
      name: Yup.string()
        .trim()
        .required(configJSON.customerValidation.nameValidation),
      employee_id: Yup.string()
      .trim()
      .required(configJSON.customerValidation.employeeIdValidation),
      mobile_number: Yup.string()
        .trim()
        .required(configJSON.customerValidation.mobileRequired),
      mobile_number_prefix: Yup.string()
        .matches(phoneRegex, configJSON.customerValidation.invalidMobilePrefix),
      gender: Yup.string()
        .required(configJSON.customerValidation.genderRequired),
      business_customer_name: Yup.string()
        .required(configJSON.customerValidation.customerNameRequired),
      company: Yup.string()
        .required(configJSON.customerValidation.compayNameRequired),
    });
  }

  buttonText() {
    if (this.state.buttonLoading) {
      return (
        <CircularProgress style={{ width: 16, height: 16, color: "white" }} />
      );
    } else {
      if (this.isEdit) {
        return configJSON.btnTxtSave;
      }
      return configJSON.btnTxtAdd;
    }
  }

  render() {
    
    const b2bBusinessCustomertData = this.state.b2bBusinessCustomerList.map(
      (item: IBusinessCustomerDropDown) => ({
        id: item.id,
        option: item.attributes.business_customer,
      })
    );
    
    const b2bCompanyListData = this.state.b2bCompanyList.map(
      (item: IB2BCompanyDropDown) => ({
        id: item.id,
        option: item.attributes.name,
      })
    );
    
    const b2bCustomerGrouptData = this.state.b2bCustomerGroupList.map(
      (item: IB2BCustomerGroupDropdown) => ({
        id: item.id,
        option: item.attributes.group_name,
      })
    );

    return (
      <>
        <Modal
          data-test-id='addPriceModal'
          open={true}
          onClose={() => {
            if (this.props.handleClose) {
              this.props.handleClose();
            }
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='MuiModal-root'
          BackdropProps={{ "aria-label": "backdrop-overlay" }}
        >
          <Box sx={addCustomerModalStyle}>
            <CustomLoader
              loading={this.state.isLoading || this.state.isEditLoading}
            />
            <Box sx={modalScrollbar}>
              <StyleHeaderTypography>Add Customer</StyleHeaderTypography>
              <Formik
                data-test-id='addCustomerFormik'
                initialValues={this.state.customerInitialValues}
                validationSchema={this.getValidation()}
                onSubmit={(values) => {
                  this.handleSubmit(values);
                }}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  getFieldProps,
                }: any) => (
                  <form onSubmit={handleSubmit}>
                    <StyleGrid direction='column' container>
                    <Grid container spacing={2} id='modal-content'>
                        <Grid item  sm={8} md={6} xs={12}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblName}*
                          </StyledInputLabel>
                          <InputField
                            data-test-id='txtName'
                            inputProps={{
                              "data-test-id": "field-name",
                            }}
                            error={this.checkError(errors.name, touched.name)}
                            id='name'
                            name='name'
                            variant='outlined'
                            fullWidth={true}
                            placeholder='Enter Customer Name'
                            {...getFieldProps("name")}
                          />
                          {this.finalErrorMessage(errors.name, touched.name)}
                        </Grid>
                        <Grid item sm={8} md={6} xs={12} >
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblMobileNumber}*
                          </StyledInputLabel>
                          <Grid container spacing={2}>
                            <Grid xs={4} sm={4} md={4} lg={4} item>
                              <InputField
                                inputProps={{
                                  "data-test-id": "field-prefix",
                                }}
                                id='mobile_number_prefix'
                                name='mobile_number_prefix'
                                error={this.checkError(
                                  errors.mobile_number_prefix,
                                  touched.mobile_number_prefix
                                )}
                                placeholder='Enter Prefix'
                                fullWidth={true}
                                InputProps={{
                                  startAdornment: 
                                  <InputAdornment style={{marginRight: "2px"}} position="start">
                                  <p style={{color:"black"}}>+</p>
                                  </InputAdornment>,
                                }}
                                variant='outlined'
                                {...getFieldProps("mobile_number_prefix")}
                              />
                            </Grid>
                            {this.finalErrorMessage(
                            errors.mobile_number_prefix,
                            touched.mobile_number_prefix
                          )}
                            <Grid xs={8} sm={8} md={8} lg={8} item>
                              <InputField
                                type='number'
                                inputProps={{
                                  "data-test-id": "field-mobile",
                                }}
                                onFocus={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  e.target.addEventListener(
                                    "wheel",
                                    function(e: WheelEvent) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  );
                                }}
                                name='mobile_number'
                                id='mobile_number'
                                error={this.checkError(
                                  errors.mobile_number,
                                  touched.mobile_number
                                )}
                                fullWidth={true}
                                variant='outlined'
                                placeholder='Mobile number'
                                {...getFieldProps("mobile_number")}
                              />
                            </Grid>
                          </Grid>
                          {this.finalErrorMessage(
                            errors.mobile_number,
                            touched.mobile_number
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblEmail}
                          </StyledInputLabel>
                          <InputField
                            error={this.checkError(errors.email, touched.email)}
                            inputProps={{
                              "data-test-id": "field-email",
                            }}
                            name='email'
                            id='email'
                            variant='outlined'
                            fullWidth={true}
                            placeholder='Enter Email Id'
                            {...getFieldProps("email")}
                          />
                          {this.finalErrorMessage(errors.email, touched.email)}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete>
                            {configJSON.customerFields.txtLblCompany}*
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            fieldName='company'
                            data-test-id='companyListDropdown'
                            placeholder='Type to Search Company Name'
                            value={values.company}
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleComapnySelection(value, setFieldValue)
                            }
                            listBoxStyle={{ maxHeight: 270 }}
                            options={b2bCompanyListData}
                            disableClear
                          />
                         {this.finalErrorMessage(errors.company, touched.company)}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete>
                            {configJSON.customerFields.txtLblBusinessCustomer}*
                          </StyledInputLabelAutoComplete>
                          
                           <AutocompleteSelect
                            fieldName='business_customer_name'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleBusinessCustomerSelection(value, setFieldValue)
                            }
                            data-test-id='businessCustomerDropdown'
                            disableClear
                            placeholder='Select Business Customer'
                            listBoxStyle={{ maxHeight: 270 }}
                            options={b2bBusinessCustomertData}
                            value={values.business_customer_name}
                          />
                          {this.finalErrorMessage(
                            errors.business_customer_name,
                            touched.business_customer_name
                          )}
                        </Grid>

                        <Grid xs={12} sm={8} md={6} style={{marginTop:"20px"}} item>
                          <StyledInputLabelAutoComplete>
                            {configJSON.customerFields.txtLblSelectCustomerGroup}
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            fieldName='customer_group'
                            listBoxStyle={{ maxHeight: 270 }}
                            data-test-id='customerGroupListDropdown'
                            placeholder='Select Customer Group'
                            disableClear
                            value={values.customer_group}
                            options={b2bCustomerGrouptData}
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleCustomerGroupSelection(value, setFieldValue)
                            }
                          />
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblDepartmentName}
                          </StyledInputLabel>
                          <InputField
                            error={this.checkError(
                              errors.department_name,
                              touched.department_name
                            )}
                            inputProps={{
                              "data-test-id": "field-department-name",
                            }}
                            variant='outlined'
                            name='department_name'
                            id='department_name'
                            placeholder='Enter Department Name'
                            fullWidth={true}
                            {...getFieldProps("department_name")}
                          />
                          {this.finalErrorMessage(
                            errors.department_name,
                            touched.department_name
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblDepartmentId}
                          </StyledInputLabel>
                          <InputField
                            id='department_id'
                            inputProps={{
                              "data-test-id": "field-department-id",
                            }}
                            error={this.checkError(
                              errors.department_id,
                              touched.department_id
                            )}
                            name='department_id'
                            variant='outlined'
                            fullWidth={true}
                            placeholder='Enter Department ID'
                            {...getFieldProps("department_id")}
                          />
                          {this.finalErrorMessage(
                            errors.department_id,
                            touched.department_id
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblEmployeeId}*
                          </StyledInputLabel>
                          <InputField
                            error={this.checkError(
                              errors.employee_id,
                              touched.employee_id
                            )}
                            variant='outlined'
                            inputProps={{
                              "data-test-id": "field-employee-id",
                            }}
                            name='employee_id'
                            placeholder='Enter Employee ID'
                            id='employee_id'
                            fullWidth={true}
                            {...getFieldProps("employee_id")}
                          />
                          {this.finalErrorMessage(
                            errors.employee_id,
                            touched.employee_id
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblCostCenter}
                          </StyledInputLabel>
                          <InputField
                            id='cost_center'
                            error={this.checkError(
                              errors.cost_center,
                              touched.cost_center
                            )}
                            name='cost_center'
                            placeholder='Type or Type to Select'
                            fullWidth={true}
                            variant='outlined'
                            inputProps={{
                              "data-test-id": "field-cost-center",
                            }}
                            {...getFieldProps("cost_center")}
                          />
                          {this.finalErrorMessage(
                            errors.cost_center,
                            touched.cost_center
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblSubCostCenter}
                          </StyledInputLabel>
                          <InputField
                            id='subcost_center'
                            inputProps={{
                              "data-test-id": "field-sub-cost-center",
                            }}
                            error={this.checkError(
                              errors.subcost_center,
                              touched.subcost_center
                            )}
                            fullWidth={true}
                            name='subcost_center'
                            variant='outlined'
                            placeholder='Type or Type to Select'
                            {...getFieldProps("subcost_center")}
                          />
                          {this.finalErrorMessage(
                            errors.subcost_center,
                            touched.subcost_center
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete>
                            {configJSON.customerFields.txtLblGender}*
                          </StyledInputLabelAutoComplete>
                           <AutocompleteSelect
                            fieldName='gender'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleModalGenderSelection(
                                value,
                                setFieldValue
                              )
                            }
                            placeholder='Select Gender'
                            data-test-id='genderListDropdown'
                            disableClear
                            listBoxStyle={{ maxHeight: 270 }}
                            value={values.gender}
                            options={this.state.dropdownOptions?.genderList}
                            />
                          {this.finalErrorMessage(
                            errors.gender,
                            touched.gender
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblAddress}
                          </StyledInputLabel>
                          <InputField
                            id='street_address'
                            inputProps={{
                              "data-test-id": "field-street_address",
                            }}
                            error={this.checkError(
                              errors.street_address,
                              touched.street_address
                            )}
                            variant='outlined'
                            name='street_address'
                            placeholder='Type to Select'
                            fullWidth={true}
                            {...getFieldProps("street_address")}
                          />
                          {this.finalErrorMessage(
                            errors.street_address,
                            touched.street_address
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete>
                            {configJSON.customerFields.txtLblCity}
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            fieldName='city'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleModalCitySelection(
                                value,
                                setFieldValue
                              )
                            }
                            placeholder='Select City'
                            data-test-id='cityListDropdown'
                            value={values.city}
                            listBoxStyle={{ maxHeight: 270 }}
                            options={this.state.dropdownOptions.cityList}
                            disableClear
                          />
                          {this.finalErrorMessage(errors.city, touched.city)}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblPinCode}
                          </StyledInputLabel>
                          <InputField
                            error={this.checkError(
                              errors.postal_code,
                              touched.postal_code
                            )}
                            inputProps={{
                              "data-test-id": "field-postal-code",
                            }}
                            id='postal_code'
                            name='postal_code'
                            variant='outlined'
                            fullWidth={true}
                            placeholder='Enter Pin Code'
                            {...getFieldProps("postal_code")}
                          />
                          {this.finalErrorMessage(
                            errors.postal_code,
                            touched.postal_code
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblNotes}
                          </StyledInputLabel>
                          <InputField
                            error={this.checkError(errors.notes, touched.notes)}
                            inputProps={{
                              "data-test-id": "field-notes",
                            }}
                            name='notes'
                            id='notes'
                            variant='outlined'
                            fullWidth={true}
                            placeholder='Enter Notes'
                            {...getFieldProps("notes")}
                          />
                          {this.finalErrorMessage(errors.notes, touched.notes)}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblPrivateNotes}
                          </StyledInputLabel>
                          <InputField
                            variant='outlined'
                            error={this.checkError(
                              errors.private_notes,
                              touched.private_notes
                            )}
                            inputProps={{
                              "data-test-id": "field-private-notes",
                            }}
                            name='private_notes'
                            id='private_notes'
                            placeholder='Enter Private Notes'
                            fullWidth={true}
                            {...getFieldProps("private_notes")}
                          />
                          {this.finalErrorMessage(
                            errors.private_notes,
                            touched.private_notes
                          )}
                        </Grid>

                        <Grid  xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblPriceList}
                          </StyledInputLabel>
                          <InputField
                            value={this.handleReturnPricelistName()}
                            id='price_list'
                            disabled={true}
                            name='price_list'
                            variant='outlined'
                            inputProps={{
                              "data-test-id": "field-price-list"
                            }}
                            placeholder='Enter Price List'
                            fullWidth={true}
                          />
                        </Grid>

                        <Grid xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblNoOfOrders}
                          </StyledInputLabel>
                          <InputField
                            value={this.handleCheckNullVal(this.state.noOfOrdrers)}
                            disabled={true}
                            inputProps={{
                              "data-test-id": "field-nooforder",
                            }}
                            name='no_of_orders'
                            variant='outlined'
                            id='no_of_orders'
                            fullWidth={true}
                            placeholder='Enter No Of Orders'
                            type='number'
                          />
                        </Grid>

                        <Grid xs={12} sm={8} md={6} item style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblNoOfPiecesOrders}
                          </StyledInputLabel>
                          <InputField
                            value={this.handleCheckNullVal(this.state.noOfPiecesOrders)}
                            disabled={true}
                            type='number'
                            name='no_of_pieces_per_order'
                            fullWidth={true}
                            id='no_of_pieces_per_order'
                            placeholder='Enter No. of Pieces per Order'
                            variant='outlined'
                            inputProps={{
                              "data-test-id": "field-nooforder-pieces",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </StyleGrid>
                    <StyleModalBox className={"actionButtonSection"}>
                      <StyledCancelButton
                        data-test-id='cancelBtn'
                        onClick={() => this.handleCloseModal()}
                      >
                        {configJSON.btnTxtCancel}
                      </StyledCancelButton>
                      <StyledButton data-test-id='btn-submit' type='submit'>
                        {this.buttonText()}
                      </StyledButton>
                    </StyleModalBox>
                  </form>
                )}
              </Formik>
            </Box>
            <Snackbar
              data-test-id='errorMsg'
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackbarOpen}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <Alert severity={this.state.snakcbarSeverity}>
                {this.state.snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Modal>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const addCustomerModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "750px",
  maxHeight: "92%",
  bgcolor: "#fff",
  borderRadius: 24,
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "hidden",
  "@media (max-width: 768px)": {
    padding: "20px",
    width: "75%",
  },
};

const modalScrollbar = {
  padding: "24px 36px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#d1d1d1",
    borderRadius: "10px",
  },
};

const StyleHeaderTypography = styled(Typography)({
  fontWeight: 600,
  marginBottom: "24px",
  color: "#1a1a1a",
  fontSize: "24px",
  textAlign: "center",
});

const StyleModalBox = styled(Box)({
  marginTop: "48px",
  marginBottom: "40px",
  display: "flex",
  flexWrap: "wrap-reverse",
  justifyContent:"center"
})

export default B2BAddCustomerModal;
// Customizable Area End