Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.advancedsearchApiContentType = "application/json";
exports.getAdvancedSearchApiEndPoint = "advanced_search/search";
exports.errorTitle = "Error";

exports.searchOrderApiEndPoint = "/bx_block_order_management/orders/search";
exports.generateOrderInvoiceEndPoint = "bx_block_order_management/orders/generate_order_invoice?id=";
exports.rawOrderInvoiceData = "bx_block_order_management/orders/generate_row_data_order_invoice?id="
exports.getOrderApi = "bx_block_order_management/orders/";
exports.getAllPaymentMethodEndPoint = "/bx_block_settings/payment_methods";
exports.getAllBusinessListEndPoint =
  "/bx_block_drop_down/drop_down?module_name=Business";
exports.getCitiesListEndPoint = "/bx_block_city/cities";
exports.getStoreListEndPoint =
  "/bx_block_store_management/store_managements?dropdown=true";
exports.getCreditNoteEndpoint = 
  "bx_block_refundmanagement/refund_orders/get_order_refund?order_id=";
exports.createCreditNoteEndPoint = "bx_block_refundmanagement/refund_orders";
  exports.testing="testing"
exports.testPhase="testPhase"
exports.CustomerNameBlank="Customer Name Blank"

exports.Strings = {
  reset: "Reset",
  search: "Search",
  advanceSearch: "Advance Search",
  details: "Details",
  searchResult: "Search Result",
  plantCleaningVal: "Plant Cleaning",
  carpetFurniturePlantVal: "Carpet and furniture (plant)",
  carpetFurnitureHomecleanVal: "Carpet and furniture (home cleaning)",
  credit_note_button: "Credit Note",
  credit_note_view_button: "View Credit Order",
  credit_note_button: "Credit Note",
  credit_note_view_button: "View Credit Order",
  stringsCreditNotePage_CreateCreditNoteHeader: "Credit Note",
  stringsCreditNotePage_CancelButton: "Cancel",
  stringsCreditNotePage_NextButton: "Next",
  stringsCreditNotePage_CreateCreditNoteButton: "Create Credit Note",
  stringsCreditNotePage_CreditNote_Add_Note: "Add Note",
  stringsCreditNotePage_AdjustMentReason: "Adjustment Reason",
  stringsCreditNotePage_table_heading: "Order Details",
  stringsCreditNotePage_AdjustMentDetails: "Adjustment Details",
  stringsCreditNotePage_AddedNewUpcharge: "Added New Upcharge",
  stringsCreditNotePage_ConfirmationText:
    "I confirm that the adjustments and refund details are accurate.",
  stringCreditNoteModalTable_CustomerName: "Customer Name",
  stringCreditNoteModalTable_OrderCreationDate: "Order Creation Date",
  stringCreditNoteModalTable_TotalOrderAmount: "Total Order Amount",
  stringCreditNoteModalTable_Tax: "Tax",
  stringCreditNoteModalTable_AdjustmentAmoint: "Adjustment Amount",
  stringCreditNoteModalTable_RefundAmoun: "Refund Amount",
};

exports.noDataMessageVal = "No records found!";
exports.dataFound = "Data Found"
exports.requiredField = "Choose a date or date range"
exports.pickAdate= "Pick a date"
exports.text = "text";
exports.input = "placeholder"
exports.right = "right"
exports.advancedFilter = "More Filters"
exports.advancedType = "Advanced Type"
exports.selectTypeTxt = "Select Type"

exports.noPermissionMsg = "no-permission-msg"
// Customizable Area End
