// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { apiCall, handleLogout, makeApiMessage } from "../../../components/src/common";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import {
  navigateTo
} from "../../../blocks/utilities/src/CustomBlockHelpers";
export const configJSON = require("./config");
import {
  CustomerDataType
} from "../../../components/src/Interface";
import { BusinessAccountDetail } from "./utils";


export interface BusinesCustomerDetails {
    type: string;
    id: string;
    attributes: {
      name: string;
      full_phone_number: string;
      activated: boolean;
      email: string | null;
      department_name: string | null;
      id: number;
      department_id: string | null;
      cost_center: string | null;
      sub_cost_center: string | null;
      address: string | null;
      postal_code: string | null;
      notes: string | null;
      company: {
        id: number;
        name: string;
      };
      private_notes: string | null;
      no_of_order: number | null;
      no_of_pieces: number | null;
      price_list: {
        id: number;
        name: string;
      };
      employee_id: string;
      business_account_id: number;
      gender: {
        id: number;
        label: string;
      };
      city:  {
        "id": number,
        "name_en": string
        },
        "business_customer_group": {
          "id": number,
          "group_name": string
        },
      "business_account": {
        "id": number,
        "business_customer": string
      },
    };
}

export interface ICustomerInitialValues {
    name: string;
    mobile_number_prefix: string;
    mobile_number: string;
    email: string;
    company: string;
    business_customer_name: string;
    department_name: string;
    department_id: string;
    employee_id: string;
    cost_center: string;
    subcost_center: string;
    gender: string;
    street_address: string;
    city: string;
    postal_code: string;
    notes: string;
    private_notes: string;
    price_list: string;
    no_of_orders: number;
    no_of_pieces_per_order: number;
    customer_group: string
}

export interface IB2BCompanyDropDown {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "name": string,
        "number_of_vats": string
    },
}

export interface IBusinessCustomerDropDown {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "business_customer": string,
        "contract_person": string
    }
}

export interface IB2BPriceListDropDown {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "name": string,
        "price_list_type": string,
        "is_master_price_list": boolean
    }
  }

export interface IB2BCustomerGroupDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    group_name: string;
    vip: boolean;
  };
}


interface IGenderDropDownItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    label: string;
    module_name: string;
    created_at: string;
    updated_at: string; 
    model_name: string; 
  };
}

type DropdownApiResponseData = IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[];


interface ICityDropDown {
  id: number;
  name_en: string;
  name_ar: string;
  name: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: Function
  };
  open?: boolean;
  id: string;
  customerData?: CustomerDataType | null;
  handleClose?: Function;
  handleGetCustomerPhone?: (customerData:unknown) => void;
  priceId?: string;
  isEdit?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customerInitialValues: ICustomerInitialValues
  buttonLoading: boolean
  isB2bActive: boolean
  b2bCompanyList: IB2BCompanyDropDown[]
  b2bBusinessCustomerList: IBusinessCustomerDropDown[]
  b2bCustomerGroupList: IB2BCustomerGroupDropdown[]
  b2bPriceList: IB2BPriceListDropDown[]
  errorSnackbarOpen: boolean;
  isLoading: boolean;
  dropdownOptions: {
    genderList: {
      id: string,
      option: string
    }[],
    cityList: {
      id: string,
      option: string
    }[]
  },
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  isEditLoading: boolean;
  selectedCompany: { id: string, option: string } | null
  selectedPriceListVal: { id: string, option: string } | null
  selectedGender: { id: string, option: string } | null
  errorMessage: string;
  selectedCustGroup: { id: string, option: string } | null
  selectedCity: { id: string, option: string } | null
  lastCreatedCustomer:{
    full_name: string;
    customer_id?: string;
    country_code: string;
    full_phone_number: string;
  } | undefined,
  selectedBusinessCustomer: { id: string, option: string } | null
  snackbarMessage: string;
  snackbarOpen: boolean;
  snackbarServerity: 'success' | 'error';
  editId: string
  noOfOrdrers: number | null;
  noOfPiecesOrders: number | null
  selectedPricelist: {
    id: number;
    name: string;
  } | null;
  selectedBusinessAccountData: BusinessAccountDetail | null;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BCustomerModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomerGroupDropdownApiCallId: string = "";
  authToken: string = "";
  getPriceListDropdownApiCallId: string = "";
  getCompanyDropdownApiCallId: string = "";
  addB2BCustomerApiCallId: string = "";
  getGenderDropdownOptionsApiCallId: string = ""
  getCompanyDropdownDetailsApiCallId: string = ""
  getCityDropdownOptionsApiCallId: string = ""
  isEdit: boolean = this.props.navigation?.getParam('navigationBarTitleText')? true : false;
  getBusinessCustDropdownOptionsApiCallId: string = ""
  getBusinessCustomerDetailAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccountTypeInfoMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      buttonLoading: false,
      customerInitialValues: {
        name: '',
        mobile_number_prefix: '966',
        mobile_number: '',
        email: '',
        company: "",
        business_customer_name: '',
        department_name: '',
        department_id: '',
        employee_id: '',
        cost_center: '',
        subcost_center: '',
        gender: '',
        street_address: '',
        city: '',
        postal_code: '',
        notes: '',
        private_notes: '',
        price_list: '',
        no_of_orders:0,
        no_of_pieces_per_order:0,
        customer_group: ""
      },
      isB2bActive: false,
      b2bCompanyList: [],
      b2bCustomerGroupList: [],
      dropdownOptions: {
        genderList:[],
        cityList: []
      },
      b2bBusinessCustomerList: [],
      errorSnackbarOpen: false,
      errorMessage: '',
      b2bPriceList: [],
      selectedCompany: null,
      snakcbarSeverity: 'error',
      selectedCity: null,
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarServerity: 'success',
      selectedPriceListVal: null,
      selectedGender: null,
      lastCreatedCustomer: undefined,
      selectedCustGroup:null,
      selectedBusinessCustomer: null,
      isLoading: false,
      editId: "",
      isEditLoading: false,
      noOfOrdrers: null,
      noOfPiecesOrders: null,
      selectedPricelist: null,
      selectedBusinessAccountData: null,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.addB2bCustomerApiCallResponse(apiRequestCallId, responseJson);
    this.getDropdownApiCallResponse(apiRequestCallId, responseJson);
    this.getModalCityDropdownApiCallRes(apiRequestCallId, responseJson);
    this.getModalGenderDropdownOptionApiCallResponse(apiRequestCallId,responseJson)
    this.gerBusinessAccountDetailsApiResponse(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken1 = localStorage.getItem("token");
    if (typeof (authToken1) === "string") {
      this.authToken = authToken1;
    }
    this.getModalDropDownApiCall()
    // Customizable Area End
  }
  // Customizable Area Start


  getPhoneSplit = (phone: string) => {
    if(phone){
      const prefix =  phone.slice(0, 3);
      const phoneno = phone.slice(3);
      return {prefix,phoneno}
    }
  }

  getModalDropDownApiCall = () => {
    this.getB2BBusinessCustomerDropDownApi()
    this.getB2BCompanyDropDownApi()
    this.getB2BPriceListDropDownApi()
    this.getCityList()
    this.getGenderDropdownAPI()
  }

 

  getB2BCustomerGroupDropDownApi = (id: string) => {
    let headers = {
        token: this.authToken,
        "Content-type": "application/json", 
    }

    const url = configJSON.B2BCustomerGroupDropdownAPIEndPoint + 
                "&company_id=" + id

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCustomerGroupDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getB2BCompanyDropDownApi = () => {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    const url = configJSON.B2BCompanyDropdownAPIEndPoint

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCompanyDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getB2BBusinessCustomerDropDownApi = () => {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    const url = configJSON.B2BBusinessCustomerDropdownAPIEndPoint

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getBusinessCustDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getGenderDropdownAPI() {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }
    let url = `${configJSON.getDropdownOptionApiEndPoint}?module_name=Gender`;
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getGenderDropdownOptionsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getB2BPriceListDropDownApi = () => {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    const url = configJSON.B2BPriceListDropdownAPIEndPoint

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getPriceListDropdownApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }


  getCityList() {    
    let headers = {
        token: this.authToken,
        "Content-type": "application/json", 
    }
    
    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.getCityListApiEndPoint,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCityDropdownOptionsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 


  getDropdownApiCallResponse = (apiRequestCallId: string, responseJson: { data: DropdownApiResponseData, status: number, message?: string, errors?: { message: string } }) => {
    if (apiRequestCallId === this.getCompanyDropdownApiCallId) {
      this.getModalCompanyDropdownAPiRes(responseJson)
    } else if (apiRequestCallId === this.getPriceListDropdownApiCallId){
      if(responseJson?.status == 500) {
        this.setState({
          errorMessage: "Internal server error",
          errorSnackbarOpen: true,
          snakcbarSeverity: 'error'
        })
      } 
      else {
        if (this.checkGetResponse(responseJson) && !responseJson.message) {
            this.setState({ b2bPriceList: responseJson.data as IB2BPriceListDropDown[] });
        }
      }
    } else if(apiRequestCallId === this.getCustomerGroupDropdownApiCallId) {
      this.getCustomerGroupDropdownApiCallResponse(apiRequestCallId, responseJson)
    } else if(apiRequestCallId === this.getBusinessCustDropdownOptionsApiCallId) {
      this.getModalBusinessCustApiCallResponse(apiRequestCallId, responseJson)
    }

  }

  
  getModalGenderDropdownOptionApiCallResponse(apiRequestCallId: string,responseJson:{data:IGenderDropDownItem[] ,status: number, message?: string, errors?: { message: string }}) {
    if(apiRequestCallId === this.getGenderDropdownOptionsApiCallId){ 
      if (this.checkGetResponse(responseJson)) {
          this.setState(() => ({
            dropdownOptions: {
              ...this.state.dropdownOptions,
              genderList: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.label } })
            }
          }));
      }
    }
  }

  getModalCompanyDropdownAPiRes = (responseJson: { data: IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[], status: number, message?: string, errors?: { message: string } }) => {
    if(responseJson?.status == 500) {
      this.setState({
        errorSnackbarOpen: true,
        errorMessage: "Internal server error",
        snakcbarSeverity: 'error'
      })
    } 
    else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
          this.setState({ b2bCompanyList: responseJson.data as IB2BCompanyDropDown[] });
      }
    }
  }


  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  getCustomerGroupDropdownApiCallResponse(apiRequestCallId: string, responseJson: { data: IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[], status: number, message?: string, errors?: { message: string } }) {
    if(responseJson?.status == 500) {
      this.setState({
        errorMessage: "Internal server error",
        snakcbarSeverity: 'error',
        errorSnackbarOpen: true,
      })
    } 
    else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
          this.setState({ b2bCustomerGroupList: responseJson.data as IB2BCustomerGroupDropdown[] });
      }
    }
  }
  
  checkError(error: boolean, touch: boolean) {
    if (error && touch) {
      return true
    } else {
      return false
    }
  }

  getModalBusinessCustApiCallResponse (apiRequestCallId: string, responseJson: { data:IBusinessCustomerDropDown[] | IB2BCompanyDropDown[] | IB2BPriceListDropDown[] | IGenderDropDownItem[] | IB2BCustomerGroupDropdown[], status: number, message?: string, errors?: { message: string } }) {
    if(responseJson?.status == 500) {
      this.setState({
        errorMessage: "Internal server error",
        errorSnackbarOpen: true,
        snakcbarSeverity: 'error'
      })
    } 
    else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
          this.setState({ b2bBusinessCustomerList: responseJson.data as IBusinessCustomerDropDown[] });
      }
    }
  }


  getSelectedPricelistForAdd = () => {
    const { selectedPricelist } = this.state;
    if (selectedPricelist) {
      return selectedPricelist.id;
    } else {
      return "";
    }
  };
 

  handleSubmit = (values:ICustomerInitialValues) => {
    this.setState({isLoading:true})
    let headers = {
      token: this.authToken,
      "Content-type": "application/json", 
    }

    let modalUrl = configJSON.B2BCustomerAPIEndpoint
    let modalMethodVal = configJSON.exampleAPiMethod

    const modalBody = {
      data: {
        "b2b_price_list_id": this.getSelectedPricelistForAdd(), 
        "company_id": values.company, 
        city_id :values.city,
        "business_account_id": values.business_customer_name, 
        gender_id: values.gender,
        name: values.name,
        full_phone_number: values.mobile_number_prefix + values.mobile_number,
        email: values.email,
        business_customer_group_id : values.customer_group && values.customer_group !== "undefined" ? values.customer_group : "",
        department_name:  values.department_name,
        "cost_center": values.cost_center, 
        "sub_cost_center": values.subcost_center, 
        "department_id": values.department_id,
        "postal_code": values.postal_code, 
        "notes": values.notes, 
        "private_notes":  values.private_notes, 
        "address": values.street_address , 
        "employee_id": values.employee_id,
        "activated": true, 
        "no_of_order": this.handleCheckNullVal(this.state.noOfOrdrers),
        "no_of_pieces": this.handleCheckNullVal(this.state.noOfPiecesOrders),
      }
    }

    const getAccount = apiCall({
      header: headers,
      httpBody: modalBody,
      url: modalUrl,
      httpMethod: modalMethodVal,
    });

    this.addB2BCustomerApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleModalGenderSelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedGender: value
     },() => setFieldValue("gender", value.id))
  }


  addB2bCustomerApiCallResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.addB2BCustomerApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          snackbarMessage: this.isEdit ? "Customer Updated Successfully." : "Customer Created Successfully.",
          snakcbarSeverity:"success", 
          snackbarOpen: true
         }, () => {
          setTimeout(() => {
            this.setState({isLoading:false})
            if(this.props.open){
              this.handleSetCustomer(responseJson)
            }else{
              this.handleRedirect()
            }
          }, 1000);
        });
      } else {
        this.setState({
          isLoading: false,
          snakcbarSeverity:"error", 
          snackbarOpen: true, 
          snackbarMessage: responseJson && responseJson.errors.full_messages[0] 
        })
      }
    }
  }

  handleCustomerGroupSelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedCustGroup: value,
      selectedPricelist: null
     },() => setFieldValue("customer_group", value.id))
  }

  handleBusinessCustomerSelection = (
    value: { id: string; option: string },
    setFieldValue: (field: string, fieldValue: string) => void
  ) => {
    this.setState(
      {
        selectedPricelist: null,
        selectedBusinessCustomer: value,
      },
      () => {
        setFieldValue("customer_group", "");
        setFieldValue("business_customer_name", value.id);
        this.handleFetchBusinessAccountDetails(value.id);
      }
    );
  };

  handlePriceListSelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedPriceListVal: value
     },() => setFieldValue("price_list", value.id))
  }

  handleComapnySelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    setFieldValue("company", value.id)
    this.setState({
      selectedCompany: value
     },() => {
      setFieldValue("customer_group", "")
      this.getB2BCustomerGroupDropDownApi(value.id)
    })
  }


  handleRedirect = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BCustomerList",
    })
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false })
  }

  handleCloseModal = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }  
  }

  handleSetCustomer = (responseJson:any) => {
    this.handleCloseModal()
  }

  handleModalCitySelection = (value: { id: string, option: string }, setFieldValue: (field: string, fieldValue:string) => void) => {
    this.setState({
      selectedCity: value
     },() => setFieldValue("city", value.id))
  }

  getModalCityDropdownApiCallRes(apiRequestCallId: string,responseJson:{cities: ICityDropDown[] ,status: number, message?: string, errors?: { message: string }}) {
    if(apiRequestCallId === this.getCityDropdownOptionsApiCallId){
      if (this.checkGetResponse((responseJson as {status: number, message?: string, errors?: { message: string }}))) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            cityList: responseJson.cities.map((item: any) => { return { id: item.id, option: item.name } })
          }
       }));
     }
    }
  }

  handleFetchBusinessAccountDetails = (accountId: string) => {
    const ApiUrl = configJSON.B2BBusinessAccountsAPIEndPoint + "/" + accountId;
    let requestMessage = makeApiMessage({
      url: ApiUrl,
      method: "GET",
    });
    this.getBusinessCustomerDetailAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckNullVal = (value: number | null) => {
    if(value || value == 0) {
      return value;
    } else {
      return "";
    }
  }

 

  gerBusinessAccountDetailsApiResponse = (
    apiRequestCallId1: string,
    responseJson: { data: BusinessAccountDetail }
  ) => {
    if (apiRequestCallId1 === this.getBusinessCustomerDetailAPICallId) {
      const priceList = responseJson.data.attributes.price_list;
      const { editId, selectedPricelist } = this.state;
      this.setState({
        noOfOrdrers: responseJson.data.attributes.no_of_items_per_order,
        selectedBusinessAccountData: responseJson.data,
        selectedPricelist: editId && selectedPricelist?.id ? selectedPricelist : priceList,
        noOfPiecesOrders: responseJson.data.attributes.no_of_pieces_per_order,
      });
    }
  };

  // Customizable Area End
}