// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IMyUser } from "./utils";

import {
  CustomEnums,
  getCustomEnumName,
} from "../../utilities/src/CustomBlockHelpers";
import { makeApiMessage } from "../../../components/src/common";
import {
  setStorageData,
  getStorageData
} from "framework/src/Utilities";
import { RouteComponentProps, match } from 'react-router-dom';
export const configJSON = require("./config");

interface StyledDrawerResProps {
  responsive?: {
    "1024": string,
    "1280": string,
    "1366": string
  },
  open: boolean
}

export interface Props extends RouteComponentProps {
  id: string;
  routeMap: any;
  match: match<string>;
  // Customizable Area Start
  resposniveDrawer?: { "1024": string, "1280": string, "1366": string }
  // Customizable Area End
}

// Customizable Area Start
interface S {
  drawerOpen: boolean;
  myUser?: IMyUser;
  userShortName: string;
  regionId: string;
  regionMultiId: string[];
}

interface SS { }

export default class LayoutController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMyUserCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start

    this.state = {
      drawerOpen: false,
      userShortName: '',
      regionId: '',
      regionMultiId: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.handleResForGetUser(from, message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token");
    if (!token) {
      this.handleNavigateTo("EmailAccountLoginPage");
    }
    this.getMyUser();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.state.myUser) {
        const userContext = {
          isSuperAdmin: this.state.myUser?.attributes.role?.name === "SuperAdmin",
          user: this.state.myUser,
          refreshUser: this.getMyUser,
        }
        this.sendData({ isdrawerOpen: this.state.drawerOpen, permissions: this.getPermissions(), userContext })
      }
    }
  }

  sendData = (payload: any) => {
    let msgData = new Message(getName(MessageEnum.LayoutDataMessage),)
    msgData.addData(getName(MessageEnum.LayoutMessageData), payload)
    this.send(msgData)
  }

  handleNavigateTo(route: string) {
    const navigateMsg: Message = new Message(
      getCustomEnumName(CustomEnums.PageContainerNavigateMessage)
    );
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMsg);
  }

  handleDrawerOpen = (open: boolean) => {
    this.setState({ drawerOpen: open }, () => {
      this.sendData({ isDrawerOpen: this.state.drawerOpen });
    })
  }

  handleResForGetUser = async (from: string, message: Message) => {
    if (this.getMyUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        const myUser: IMyUser = responseJson.data;
        const userShortName = (
          myUser.attributes.first_name[0] + myUser.attributes.last_name[0]
        )
          .toUpperCase()
          .substring(0, 2);
        this.setState({
          myUser,
          userShortName,
        }, () => {
          const userContext = {
            isSuperAdmin: myUser?.attributes.role?.name === "SuperAdmin",
            user: myUser,
            refreshUser: this.getMyUser,
          }
          this.sendData({ isdrawerOpen: this.state.drawerOpen, permissions: this.getPermissions(), userContext })
          this.sendScanFilesMessageForPrint(myUser?.attributes.employee_proifle.data.attributes.share_folders)
        });
        setStorageData("userId", responseJson.data.id);
        setStorageData(configJSON.userShortNameKey, userShortName);
      }
    }
  }

  getPermissions = () => {
    return this.state.myUser
      ? this.state.myUser.attributes.permission_groups
        .filter((item) => item.permitted)
        .map((item) => item.name)
      : [];
  };

  getMyUser = () => {
    const apiUrl = "account_block/employees";
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getMyUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendScanFilesMessageForPrint = (payload = [] as string[]) => {
    const message = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    message.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "SCAN_FILES")
    message.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), payload.filter(Boolean))
    this.send(message)
  }
  // Customizable Area End
}
