import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Popover,
  AppBar,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  styled
} from "@material-ui/core";
import StoreGroupSelection from "../../../components/src/StoreGroupSelection.web"
import PageConatinerSearchableMultiSelect from "../../../components/src/PageConatinerSearchableMultiSelect.web"
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import SearchIcon from "@material-ui/icons/Search";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import {
  activeUserIcon,
  passiveUserIcon,
  activeB2b,
  inActiveUser
} from "./assets";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { getEnvironment } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
import {
  IMyUser
} from "./utils";
import HeaderController, {
  Props,
  configJSON,
} from "./HeaderController.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import Notifications from "../../../blocks/notifications/src/Notifications.web";
const Strings = configJSON.Strings.pageContainer;
// Customizable Area End

export class HeaderComp extends HeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderProfilePopover = (user: IMyUser | undefined, onView: () => void) => {
    const logo =
      user && user.attributes.employee_proifle.data.attributes.profile_logo;
    const userEmail = user ? user.attributes.email : "";
    return (
      <div className={"mainContainer"} data-test-id={"profilePover"}>
        <div className={"userNameContainer"}>
          <div>
            {logo ? (
              <img
                data-test-id={"profile-logo"}
                className={"minLogo"}
                src={logo}
              />
            ) : (
              <AccountCircleSharpIcon className={"accountCircle"} />
            )}
          </div>
          <div>
            <div className={"userName"}>{this.renderUserName(user)}</div>
            <div className={"userEmail"}>{userEmail}</div>
          </div>
        </div>
        <div className={"buttonContainer"}>
          <Button
            disabled={!user}
            data-test-id={"profileBtn"}
            onClick={onView}
            className={"button"}
          >
            {Strings.viewProfileLabelValue}
          </Button>
        </div>
      </div>
    );
  };

  renderMutliRgionSelection(optionArray: { id: string; option: string; }[]) {
    const filteredData = optionArray.filter(item => this.state.regionMultiId.includes(item.id));
    let selectedRegionData
    if (this.state.selectAllRegionId !== undefined || (this.state.regionMultiId.length == this.state.regionListData.length)) {
      if (optionArray.length > 1) {
        selectedRegionData = [{ id: "-1", option: "Select All" }, ...filteredData]
      } else {
        selectedRegionData = filteredData;
      }
    } else {
      selectedRegionData = filteredData
    }
    return (
      <div style={{ minWidth: "200px" }}>

        <PageConatinerSearchableMultiSelect
          id="region"
          name="region"
          dataTestId="regionDropdown"
          data-test-id="regionDropdown"
          checkSelectAllText={() => { }}
          value={filteredData}
          optionList={optionArray}
          placeHolderText="Select Region"
          handleScroll={() => { }}
          handleClickOnSelectAll={() => { }}
          debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, () => { }) }}
          handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.onChangeMultiRegionSelect(option) }}
          emptyDataMessage="No Region Found"
          handleEmptyAutoSelectValue={() => { }}
          selectedOptionForList={selectedRegionData}
          isRadioButtonShow={true}
          isSelectAll={this.state.regionMultiId.length == this.state.regionListData.length && this.state.regionListData.length > 1}
        />
      </div>

    )
  }

  renderMultiSelectStore = () => {
    const optionArray = this.state.storeListData.map((store) => ({
      id: store.id,
      option: store.attributes.store_name
    }))
    const filteredData = optionArray.filter(item => this.state.storeId.includes(item.id));
    let selectedStoreData
    if (this.state.selectAllStoreId !== undefined) {
      if (optionArray.length > 1) {
        selectedStoreData = [{ id: "-1", option: "Select All" }, ...filteredData]
      } else {
        selectedStoreData = filteredData;
      }
    } else {
      selectedStoreData = filteredData
    }
    return (
      <div style={{ minWidth: "200px" }}>
        <PageConatinerSearchableMultiSelect
          id="store"
          name="store"
          dataTestId="storeDropdown"
          checkSelectAllText={() => { }}
          value={filteredData}
          optionList={optionArray}
          placeHolderText="Select Store"
          handleScroll={() => { }}
          handleClickOnSelectAll={() => { }}
          debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, () => { }) }}
          handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.onChangeMultiSelect(option) }}
          emptyDataMessage="No Store Found"
          handleEmptyAutoSelectValue={() => { }}
          selectedOptionForList={selectedStoreData}
          isRadioButtonShow={true}
          isSelectAll={this.state.storeId.length == this.state.storeListData.length && this.state.storeListData.length > 1}
        />
      </div>
    )
  }


  getVisibleStores = (group: {
    id: number,
    name: string,
    store_managements: {
      data: any[]
    }
  }) => {
    const { allStoreListData, recordsToShowByGroup } = this.state;
  
    const groupStoreData = Array.isArray(group.store_managements?.data)
      ? group.store_managements.data
      : [group.store_managements?.data];
  
    const combinedStores = [...groupStoreData, ...allStoreListData];
  
    const uniqueStores = combinedStores.reduce((acc, store) => {
      if (!acc.some((existingStore:{id:number}) => existingStore.id === store.id)) {
        acc.push(store);
      }
      return acc;
    }, []);
  
    return uniqueStores.slice(0, recordsToShowByGroup[group.id] || 10);
  };
  
  

  loadMore = (groupId: number) => {
    this.setState((prevState) => ({
      recordsToShowByGroup: {
        ...prevState.recordsToShowByGroup,
        [groupId]: (prevState.recordsToShowByGroup[groupId] || 10) + 10,
      },
    }));
  };

  renderMultiSelectStoreAndGroup = () => {
    const storeGroupData = this.state.groupListData.map(group => ({
      id: group.attributes.id,
      name: group.attributes.name,
    }));

    const areaData = this.state.areaListByGroupData.map(group => ({
      id: group.id,
      name: group.name,
      stores: this.getVisibleStores(group),
    }));

    return (
      <div style={{ minWidth: "200px" }}>
        <StoreGroupSelection 
          data-test-id="storeGroupDropdown"
          onSearch={this.storeGrpSearch}
          searchResult={this.state.storeGrpSearch}
          onSelectSuggestion={(value:string, type:string) => this.handleSelectSuggestion(value,type)}
          storeGroupData={storeGroupData} 
          areaData = {areaData as any}
          handleSelectGroupOptions = {(value: { id: string, name: string }) =>{ this.onChangeMulSelectStoreAndGroup(value,"","group") }}
          handleSelectAreaOptions = {(value: { id: string, name: string }) =>{ this.onChangeMulSelectStoreAndGroup(value,"", "area") }}
          handleSelectStoreOptions = {(value: { id: string, name: string },areaId:string) =>{ this.onChangeMulSelectStoreAndGroup(value,areaId, "store") }}
          currentSelectedGroupId= {this.state.groupId}
          currentSelectedAreaId = {this.state.areaId}
          currentSelectedStoreId = {this.state.storeId}
          currentSelectedStoreIdByArea = {this.state.selectedStoresByArea}
          recordsToShowByGroup = {this.state.recordsToShowByGroup}
          allStoreListData = {this.state.allStoreListData}
          handleLoadMoreData = {(value:number) =>{ this.loadMore(value) }}
          selectedGroupName = {this.state.selectedGroupName}
          handleClearAll = {this.handleClearAllStoreGroup}
          handleLoadMoreArea =  {this.handleLoadMoreAreaData}
          handleLoadMoreGroupData = {this.handleLoadMoreGroupData}
          recordsToShowArea = {this.state.recordsToShowArea}
          allAreaList = {this.state.allAreaListByGroupData}
          isStoreGroupDropdownLoading = {this.state.isStoreGroupDropdownLoading}
          selectedSearchName = {this.state.selectedSearchName}
          storeCountByArea = {this.state.storeCountByArea}
          storeGroupPageNo = {this.state.storeGroupPageNo}
          isLoadMoreGroupLoading = {this.state.isLoadMoreGroupLoading}
          totalPages = {this.state.storeGroupMeta?.total_pages}
        />
      </div>
    )
  }

  renderSingleStoreDropdown() {
    const { myUser, storeListOptions } = this.state;
    const storeManagement = myUser?.attributes?.employee_proifle?.data?.attributes?.store_management
    const dropDownId =
      storeManagement
        ?.store_id;

    return (
      <div style={{ minWidth: "200px" }}>
        <AutocompleteSelect
          data-test-id='storeDropDown'
          placeholder='Select Store'
          fieldName={"branch"}
          options={this.getStoreOptions(storeListOptions, storeManagement)}
          value={sortCondition(Boolean(this.state.singleStoreSelectionId),this.state.singleStoreSelectionId,String(dropDownId)) as string}
          disableClear={true}
          onChange={(_event: React.ChangeEvent, value: { id: string }) => {
            this.handleSingleStoreSelection(value)
          }}
          handleScroll={this.handelStoreDropdownScroll.bind(this)}
          debouncedFunction={this.debouncedStoreSearch}
          onBlur={() => this.debouncedStoreSearch("")}
          listBoxStyle={{ maxHeight: 310 }}
        />
      </div>
    );
  }

  getRegionAndStoreRenders = () => {
    return (
      <>
        {this.checkHeaderConfig('multiRegionDropdown') && this.renderMutliRgionSelection(this.state.regionListData.map((region) => ({
          id: region.id,
          option: region.attributes.branch_name
        }))
        )}

        {this.checkHeaderConfig("multiSelectStore") && this.renderMultiSelectStore()}
        {this.checkHeaderConfig('storeAndGroupChange') && this.renderMultiSelectStoreAndGroup()}
      </>

    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { searchText, popoverOpen } = this.state;
    const logo =
      this.state.myUser &&
      this.state.myUser.attributes.employee_proifle.data.attributes
        .profile_logo;
    const openPopoverAction = Boolean(this.state.isNotificationPopoverAnchor);
    const popOverId = openPopoverAction ? "simple-popover" : undefined;
    const isDev = ["dev", "uat"].includes(getEnvironment())
    return (
      <StyledAppBar position={"sticky"} className={"appBar"}>
        <Toolbar className={"toolbar"}>
          <div className={"header"} id={"headerSearch"} data-test-id={configJSON.headerSearchTest}>
            <OutlinedInput
              className={`search ${this.checkHeaderConfig("search") ? "" : "hidden"
                }`}
              placeholder={"Search"}
              value={searchText}
              data-test-id={"commonSearch"}
              onChange={this.handleSearchTextChange}
              inputProps={{
                "data-test-id": "pageContainerInput",
                "data-testid": "pageContainerInput",
              }}
              startAdornment={
                <InputAdornment position={"start"}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
            {!this.checkHeaderConfig("hideActivePassiveUsers") && (
              <div className={"userIcons"} data-test-id={configJSON.changeUserTest}>
                <div data-test-id="normalUser" onClick={() => this.toggleB2bUserChange(false)} className={sortCondition(this.state.isB2bActive, "", "activeUserIcon") as string}>
                    <img
                    src={sortCondition(this.state.isB2bActive, inActiveUser, activeUserIcon) as string}
                      width={24} height={24}
                    />
                  </div>
                <div data-test-id="b2bUser" onClick={isDev ? () => this.toggleB2bUserChange(true) : undefined} className={sortCondition(this.state.isB2bActive, "activeUserIconB2b", "") as string}>
                  <img
                    src={sortCondition(this.state.isB2bActive, activeB2b, passiveUserIcon) as string}
                    width={24} height={24} />
                </div>
              </div>
            )}
            <AfterPermissionComponent>
              {this.state.afterPermissionComponent}

              {this.checkHeaderConfig("singleSelectStore") && this.renderSingleStoreDropdown()}

              {this.getRegionAndStoreRenders()}


            </AfterPermissionComponent>

            <div className={"msgIcons"} data-test-id={configJSON.msgIconTest}>
              <div className={"msgIcon"}>
                <MessageOutlinedIcon />
              </div>
              <div className={"msgIcon"}>
                <LocalMallOutlinedIcon />
              </div>

              <div
                data-test-id={"notificationIcon"}
                className={"msgIcon"}
                onClick={this.handleClickNotification}
                aria-describedby={popOverId}
              >
                {this.state.notificationCount > 0 ? (
                  <Badge
                    classes={{ badge: "badgeColor" }}
                    className={"notificationIcon"}
                    color="secondary" 
                    overlap="circular"
                    badgeContent={this.state.notificationCount}
                    // variant='dot'
                  >
                    <NotificationsIcon
                      className={"notificationsIconStyle"}
                    />
                  </Badge>
                ) : (
                  <NotificationsIcon className={"notificationsIconStyle"} />
                )}
              </div>
              {this.state.isNotificationPopoverAnchor !== null && (
                <Notifications
                  navigation={this.props.navigation}
                  id={"notificationPopover"}
                  isOpenAnchor={this.state.isNotificationPopoverAnchor}
                  popOverId={popOverId}
                  onClose={() => {
                    this.handleCloseNotificationPopover();
                  }}
                  updateCount={() => {
                    this.getNotificationCount();
                  }}
                />
              )}

              <div
                ref={(newRef) => (this.popoverRef = newRef)}
                className={`${"accountCircle"}`}
                data-test-id={"popoverIcon"}
                onClick={this.handlePopOverOpen}
              >
                {logo ? (
                  <img
                    className={`${"logo"} ${"accountUserLogo"}`}
                    src={logo}
                  />
                ) : (
                  <div data-test-id={configJSON.userShortNameTest}>{this.state.userShortName}</div>
                )}
              </div>
              <ProfilePopover
                open={popoverOpen}
                anchorEl={this.popoverRef}
                anchorReference={"anchorEl"}
                onClose={this.handlePopOverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {this.renderProfilePopover(
                  this.state.myUser,
                  this.handleViewProfile
                )}
              </ProfilePopover>
            </div>
          </div>
        </Toolbar>
      </StyledAppBar>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledAppBar = styled(AppBar)({
  boxShadow: "none",
  padding: "0 32px 0 48px",
  marginBottom: "15px",
  background: colors().white,
  "@media only screen and (max-width: 1024px)": {
    padding: "0 16px 0 24px",
  },
  "& .toolbar": {
    width: "100%",
    margin: "0px",
    padding: "0px",
  },
  "& .msgIcons": {
    color: "grey",
    display: "flex",
    alignItems: "center",
  },
  "& .msgIcon": {
    marginRight: "32px",
  },
  badgeColor: {
    backgroundColor: colors().activeGreen,
    top: 5,
    right: 5,
    width: 14,
    height: 14,
    border: `3px solid ${colors().background}`,
    borderRadius: "50%",
  },
  "& .notificationIcon": {
    cursor: "pointer",
  },
  "& .notificationsIconStyle": {
    color: colors().cyancobaltblue,
    cursor: "pointer",
  },
  "& .search": {
    fontFamily: "Montserrat",
    width: "100%",
    maxWidth: "358px",
    marginRight: "16px",
    "& input": {
      padding: "18px 0",
    },
    "@media only screen and (max-width: 1024px)": {
      maxWidth: "280px",
      marginRight: "12px",
      height: "32px !important",
      "& input": {
        padding: "9px 0",
        paddingTop: 7,
        fontSize: 13,

        "&::placeholder": {
          fontFamily: "Montserrat !important",
          fontSize: "13px"
        },
      },
      "& .MuiSvgIcon-root": {
        height: "20px !important",
        width: "20px !important",

      }
    },
  },
  "& .hidden": {
    visibility: "hidden",
  },
  "& .logo": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 500,
    borderRadius: 100,
    backgroundColor: colors().lightborder,
    height: 70,
    width: 70,
  },
  "& .userIcons": {
    display: "flex",
    boxsizing: "borderBox",
    width: "96px",
    marginRight: "12px",
    minWidth: "96px",
    height: "48px",
    background: colors().white,
    border: `1px solid ${colors().lightsilver}`,
    borderRadius: "22.5px",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  "& .accountCircle": {
    width: "56px",
    height: "56px",
    background: colors().Cultured,
    border: `1px solid ${colors().cyancobaltblue}`,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().black,
    borderRadius: "30px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    textAlign: "center",
    "@media only screen and (max-width: 1024px)": {
      width: "32px",
      height: "32px",
    },
  },
  "& .accountUserLogo": {
    width: "100%",
    height: "100%",
  },
  "& .activeUserIcon": {
    width: "40px",
    height: "40px",
    backgroundColor: colors().Cultured,
    display:"grid",
    placeItems:"center",
    borderRadius: "20px",
  },
  "& .activeUserIconB2b": {
    backgroundColor: colors().brightgray,
    width: "40px",
    display:"grid",
    placeItems:"center",
    height: "40px",
    borderRadius: "20px",
  },
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "32px 0",
    borderBottom: `1px solid ${colors().columbiaBlue}`,
    "@media only screen and (max-width: 1024px)": {
      padding: "16px 0",
    },
  },
  "& .userEmail": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    maxWidth: "200px",
    wordBreak: "break-all",
  },
  "& .button": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "100%",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      lineHeight: "28px",
    },
  }
});

const AfterPermissionComponent = styled(Box)({
  display: "flex",
  gap: "10px",
  marginRight: 12,
  "@media only screen and (max-width:1024px)": {
    "& .MuiAutocomplete-inputRoot": {
      padding: 3,
    },
  },
});

const ProfilePopover = styled(Popover)({
  "& .mainContainer": {
    borderRadius: "12px",
  },
  "& .userNameContainer": {
    height: "92px",
    background: "rgba(32, 75, 156, 0.15)",
    padding: "24px",
    display: "flex",
    fontFamily: "Montserrat",
    alignItems: "center",
    gap: "12px",
  },
  "& .userName": {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().black,
  },
  "& .userEmail": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    maxWidth: "200px",
    wordBreak: "break-all",
  },
  "& .accountCircle": {
    width: "56px",
    height: "56px",
    background: colors().Cultured,
    border: `1px solid ${colors().cyancobaltblue}`,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().black,
    borderRadius: "30px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    textAlign: "center",
  },
  "& .minLogo": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    margin: "auto",
    lineHeight: "24px",
    borderRadius: 100,
    fontWeight: 500,
    backgroundColor: colors().lightborder,
    height: 40,
    width: 40,
  },
  "& .buttonContainer": {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: "center",
  },
  "& .button": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  },
});

export default HeaderComp;
// Customizable Area End
