// Customizable Area Start
import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { styled, Box } from "@material-ui/core";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
export const configJSON = require("./config");

import StoreAddController, { Props, TimeState } from "./StoreAddController.web";
import { colors } from "../../utilities/src/Colors";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";


const useStyles: Styles<Theme, {}, string> = () => ({
  pageContainerStore: {
    fontFamily: "Montserrat",
    background: "white",
    padding: "0 20px",
  },
  pageTitleStore: {
    fontSize: 24,
    marginTop: 32,
    fontWeight: 600,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  formContainerStore: {
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: 12,
    padding: 32,

    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "-0.3px",
      fontSize: 16,
      color: "#1A1A1A",
    },
    position: "relative",
  },
  button: {
    padding: "16px 34px",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    cursor: "pointer",
    backgroundColor: "#204B9C",
    color: "white",
    textAlign: "center",
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
      padding: "12px 34px",
      fontWeight: 600,
    }
  },
  sectionFormContainerView: {
    marginTop: 24,
    padding: 24,
    border: "1px solid #ECECEC",
    position: "relative",
    borderRadius: 12,
  },
  sectionTitleView: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: 18,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  viewSectionHeaderView: {
    padding: "19px 32px",
    background: "#204B9C",
    color: "white",
    fontWeight: 600,
    fontSize: 18,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  storeTimeTitle: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 4,
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px',
    }
  },
  pageHeader: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageHeaderEditButton: {
    marginTop: 32,
    width: 184 ,
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height:44,
    }
  },
  dataBox: {
    paddingRight: 32
  },
  dataBoxLabel: {
    marginBottom: 8
  },
  dataFormControl: {
    width: "100%" 
  },
  dataBoxContainer: {
    marginTop: 30
  },
  mt_66: {
    marginTop: 66
  },
  mt_32: {
    marginTop: 32
  },
  mt_48: {
    marginTop: 48
  },
  FlexBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  }
});
// Customizable Area End
export class StoreView extends StoreAddController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  renderTitle = () => {
    const { classes  } = this.props;
    return <div className={classes.pageTitleStore}>Store</div>;
  };

  renderRegion = () => {
    const { storeDetail, regionList } = this.state;

    return regionList.find(
      (region) => region.id == storeDetail.attributes.region_id
    )?.option;
  };

  renderArea = () => {
    const { storeDetail } = this.state;

    return storeDetail.attributes.area.area_name;
  };

  renderCompanyName = () => {
    const { storeDetail } = this.state;

    return storeDetail.attributes.company?.name;
  };

  renderStore = () => {
    const { storeDetail } = this.state;

    return storeDetail.attributes.store_name;
  };

  renderStoreId = () => {
    const { storeDetail } = this.state;

    return storeDetail.attributes.store_id;
  };

  renderMainCrNo = () => {
    const { storeDetail } = this.state;
    return storeDetail.attributes.main_cr_no || configJSON.blank;
  };

  renderStoreCrNo = () => {
    const { storeDetail } = this.state;
    return storeDetail.attributes.store_cr_number || configJSON.blank;
  };

  renderSections = () => {
    const { storeDetail } = this.state;
    if(storeDetail?.attributes?.store_configuration?.attributes?.sections) {
      return storeDetail.attributes.store_configuration.attributes.sections.map((item) => {
        return `${item.name}, `;
      })
    }
    return configJSON.blank
  };

  renderStoreKeeper = () => {
    const { storeDetail } = this.state;
    return storeDetail.attributes.store_keepers.data
      .map((keeper) => keeper.attributes.first_name)
      .join(", ");
  };

  renderPriceListName = () => {
    const { storeDetail } = this.state;
    return storeDetail.attributes.store_configuration?.attributes.price_list.data.attributes.name;
  }

  checkForActivePaymentPopup = () => {
    const { storeDetail } = this.state;
    if(storeDetail.attributes.store_configuration?.attributes.payment_method_screen) {
      return "active"
    } else {
      return "deactive"
    }
  }

  checkForActivePreferencePopup = () => {
    const { storeDetail } = this.state;
    if(storeDetail.attributes.store_configuration?.attributes.item_preference_popup) {
      return "active"
    } else {
      return "deactive"
    }
  }

  renderTimesforDays = (times: TimeState) => {
    const { classes  } = this.props;
    return (
      <Grid container>
        {times.shifts?.map((item, index) => (
          <Grid item xs={12} md={6} className={classes.mt_32}>
            <FormControl className={classes.dataFormControl}>
              <FormLabel className={classes.dataBoxLabel}>
                {item.label}
              </FormLabel>
              <Typography className={classes.storeTimeTitle}>
                {`${new Date(item.start_time).toLocaleString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })} To ${new Date(item.end_time).toLocaleString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}` || configJSON.blank}
              </Typography>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    );
  };

  renderViewForAddressFields = (field: string, arField: string) => {
    const { classes } = this.props;
    const { storeDetail: { attributes: { address, sub_cr_number = "", ar_sub_cr_number = "" } } } = this.state;
    let value;
    let value_ar;
    if (field === 'sub_cr_number') {
      value = sub_cr_number;
      value_ar = ar_sub_cr_number;
    } else {
      value = address[field as keyof typeof address]
      value_ar = address[arField as keyof typeof address]
    }
    return (
      <Grid container direction='row' className={classes.dataBoxContainer}>
        <Grid item xs={12} sm={6} className={classes.dataBox}>
          <FormControl className={classes.dataFormControl}>
            <FormLabel className={classes.dataBoxLabel}>
              {this.capitalizeFirstCharacter(field)}
            </FormLabel>
            <Typography className={classes.storeTimeTitle}>
              {value ||
                configJSON.blank}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.dataBox}>
          <FormControl className={classes.dataFormControl}>
            <FormLabel className={classes.dataBoxLabel}>
              {this.capitalizeFirstCharacter(field)} (Arabic)
            </FormLabel>
            <Typography className={classes.storeTimeTitle}>
              {value_ar ||
                configJSON.blank}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  getFieldValue = (value : string | number | undefined) => {
    return value || configJSON.blank
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes  } = this.props;
    const { storeDetail, weekendTimes, weekdaysTimes } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
       <Box>
        <CustomLoader loading={this.state.isEditLoading} />
        <Grid
          direction='column'
          className={classes.pageContainerStore}
        >
          <Grid
            direction='row'
            className={classes.pageHeader}
          >
            {this.renderTitle()}
            {
                sortCondition(
                  this.state.permissionStatus.editPermission,
                  <div
                      data-test-id={"editBtn"}
                      onClick={this.handleEditStoreClick}
                      className={`${classes.button} ${classes.pageHeaderEditButton}`}
                    >
                      Edit
                    </div>,
                  <></>
                )
              }
          </Grid>
          <Grid
            container
            direction='column'
            className={classes.formContainerStore}
          >
            <Grid container direction='row'>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>Company</FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.renderCompanyName() || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>Region</FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.renderRegion() || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>Area</FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.renderArea() || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Store Name
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.renderStore() || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Store ID
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.renderStoreId() || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Address
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {storeDetail.attributes.store_address || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                  {configJSON.addressArabicTxt}
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                  {storeDetail.attributes.store_address_arabic || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Email Address
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {storeDetail.attributes.email || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Plant Linked
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {storeDetail.attributes.plants.data.reduce((accnt, item,index) => {
                      return `${accnt}${item ?.attributes?.name}` + (index !== storeDetail.attributes.plants.data.length - 1 ? ", " : "") ;
                    }, "") || configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Service Offer
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {storeDetail.attributes.service_offers.map((item) => {
                      return `${item.label}, `;
                    })}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Store Capacity
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {`${storeDetail.attributes.store_capacity} kg` ||
                      configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    GPS Co-ordinates
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {`${storeDetail.attributes.address.latitude}, ${storeDetail.attributes.address.longitude}` ||
                      configJSON.blank}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                  <FormControl className={classes.dataFormControl}>
                    <FormLabel className={classes.dataBoxLabel}>
                      Store Keeper
                    </FormLabel>
                    <Typography className={classes.storeTimeTitle}>
                      {this.renderStoreKeeper() || configJSON.blank}
                    </Typography>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Section
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.renderSections()}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                  <FormControl className={classes.dataFormControl}>
                    <FormLabel className={classes.dataBoxLabel}>
                      Price List
                    </FormLabel>
                    <Typography className={classes.storeTimeTitle}>
                      {this.renderPriceListName() || configJSON.blank}
                    </Typography>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    Company CR No.
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                  {this.renderMainCrNo()}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    IP Phone Number
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.getFieldValue(storeDetail.attributes.full_ip_phone_number)}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                    IP Phone Number (Arabic)
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                    {this.getFieldValue(storeDetail.attributes.full_ip_phone_number_arabic)}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.dataFormControl}>
                  <FormLabel className={classes.dataBoxLabel}>
                     Store CR No.
                  </FormLabel>
                  <Typography className={classes.storeTimeTitle}>
                  {this.renderStoreCrNo()}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox} />
            </Grid>
            {this.renderViewForAddressFields('street_name', 'street_name_arabic')}
            {this.renderViewForAddressFields('additional_street_name', 'additional_street_name_arabic')}
            {this.renderViewForAddressFields('building', 'building_arabic')}
            {this.renderViewForAddressFields('plot', 'plot_arabic')}
            {this.renderViewForAddressFields('city', 'city_arabic')}
            {this.renderViewForAddressFields('city_subdivision', 'city_subdivision_arabic')}
            {this.renderViewForAddressFields('postal_code', 'postal_code_arabic')}
            {this.renderViewForAddressFields('country_sub_entity', 'country_sub_entity_arabic')}

            <Grid container direction='row' className={classes.dataBoxContainer}>
                <Grid item xs={12} sm={6} className={classes.dataBox}>
                  <FormControl className={classes.dataFormControl}>
                    <FormLabel className={classes.dataBoxLabel}>
                      GPS Location
                    </FormLabel>
                    <Typography className={classes.storeTimeTitle}>
                      {this.getFieldValue(storeDetail.attributes.gps_location)}
                    </Typography>
                  </FormControl>
                </Grid>
            </Grid>

            <Grid container direction='row' className={classes.dataBoxContainer}>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.FlexBox}>
                  <CircleBox className={this.checkForActivePaymentPopup()}/>
                  <Typography className={classes.storeTimeTitle}>
                    Payment Popup
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.dataBox}>
                <FormControl className={classes.FlexBox}>
                  <CircleBox className={this.checkForActivePreferencePopup()}/>
                  <Typography className={classes.storeTimeTitle}>
                    Item Preference Popup
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            direction='column'
            className={`${classes.formContainerStore} ${classes.mt_32}`}
          >
            <Grid item xs={12} sm={6}>
              <div className={classes.sectionTitleView}>Store Timings</div>
            </Grid>

            <Grid
              container
              direction='column'
              className={classes.sectionFormContainerView}
            >
              <Grid container direction='row'>
                <div className={classes.viewSectionHeaderView}>
                  Weekdays (
                  {`${weekdaysTimes.startDay} to ${weekdaysTimes.endDay}`})
                </div>
              </Grid>
              <Grid container direction='row' className={classes.mt_66}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.dataFormControl}>
                    <FormLabel className={classes.dataBoxLabel}>
                      Status
                    </FormLabel>
                    <Typography className={classes.storeTimeTitle}>
                      {weekdaysTimes.status || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              {this.renderTimesforDays(weekdaysTimes)}
            </Grid>

            <Grid
              container
              direction='column'
              className={classes.sectionFormContainerView}
            >
              <Grid container direction='row'>
                <div className={classes.viewSectionHeaderView}>
                  Weekend Days (
                  {`${weekendTimes.startDay} to ${weekendTimes.endDay}`})
                </div>
              </Grid>
              <Grid container direction='row' className={classes.mt_66}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.dataFormControl}>
                    <FormLabel className={classes.dataBoxLabel}>
                      Status
                    </FormLabel>
                    <Typography className={classes.storeTimeTitle}>
                    {weekendTimes.status || configJSON.blank}</Typography>
                  </FormControl>
                </Grid>
              </Grid>
              {this.renderTimesforDays(weekendTimes)}
            </Grid>
          </Grid>

          <Grid container direction='row' className={classes.mt_48}>
            <div
              data-test-id={"backBtn"}
              onClick={this.handleBackClick}
              className={classes.button}
            >
              Back
            </div>
          </Grid>
        </Grid>
      </Box>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CircleBox = styled("div")({
  height: "15px",
  width: "15px",
  marginRight: "8px",
  borderRadius: "50px",
  "&.active": {
    background: colors().activeGreen,
    border: `1px solid ${colors().black}`
  },
  "&.deactive": {
    border: `1px solid ${colors().black}`
  }
})
export default withStyles(useStyles)(StoreView);
// Customizable Area End
