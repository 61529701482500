Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions2";
exports.labelBodyText = "RolesPermissions2 Body";

exports.labelRoleHeading = "Role and Permission"
exports.btnAddRoleText = "Add Role"
exports.labelCardPermission = "Permission"
exports.labelRoleDeactivateTittle = "Role Deactivation"
exports.labelRoleDeactivateContent = "Do you want to deactivate this role?"

exports.labelCreateRoleHeading = "Create Role and Permission"
exports.labelEditRoleHeading = "Edit Role and Permission"
exports.labelViewRoleHeading = "Role and Permission"
exports.labelRoleInput = "Role Name*"
exports.labelRoleInputPh = "Enter Role Name"
exports.labelPermission= "Permission*"
exports.viewLabelRole = "Role Name"
exports.viewLabelPermission= "Permissions"
exports.btnCancelText = "Cancel"
exports.btnCreateText = "Create"
exports.btnUpdateText = "Update"
exports.btnBackText = "Back"
exports.textNoPermission = "No permission"
exports.btnViewText = "View"
exports.btnEditText = "Edit"
exports.btnDeactivateText = "Deactivate"
exports.btnActivateText = "Activate"
exports.addPermission = "Add Permissions"

exports.textPleaseLoginFirst = "Please Login First"
exports.textSomethingWentWrong = "Something went wrong."
exports.textRoleDeactivated = "Role Deactivated"
exports.textRoleActivated = "Role Activated"
exports.labelDeactivateConfirm = "Are you sure you want to deactivate?"
exports.textLoading = "Loading"
exports.textInvalidRoleName  = "Please Enter Role Name"

exports.getMethodType = 'GET'
exports.postMethodType = 'POST'
exports.putMethodType = 'PUT'
exports.deleteMethodType = 'DELETE'
exports.applicationJsonContentType = "application/json";
exports.createRoleUrl = 'bx_block_roles_permissions/roles'
exports.getRoleListUrl = 'bx_block_roles_permissions/roles'
exports.updateRoleUrl = 'bx_block_roles_permissions/roles/'
exports.showRoleUrl = 'bx_block_roles_permissions/roles/'
exports.deactivateRoleUrl = 'bx_block_roles_permissions/roles/'
exports.deactivateEndpoint = "/deactivate"
exports.getPermissionGroupListingUrl ='bx_block_roles_permissions/roles/permission_groups_listing'
exports.getIcons =  'bx_block_categories/gallery_icons/list_all_icons'
exports.rolesSuggestionApiEndPoint =  'bx_block_roles_permissions/roles/role_filter'


exports.textRoleCreatedSucc = "Role created successfully"
exports.textRoleUpdatedSucc = "Role updated successfully"
exports.icon =  "Icon"
exports.role =  "Role"
exports.permissions =  "Permissions"
exports.users =  "Users"
exports.popoverTest = "popoverTest"
exports.moreBtn = "moreButton"
exports.test = "test"
exports.Icon = "Icon"
exports.coverage = "coverage"
exports.noPermission = "No Permissions"

exports.rolesPermissionsFilterStorageKey = "roles_permissions_filter_value"

exports.roleIconImgTest = "role-icon-img-test"
// Customizable Area End