Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.httpPutMethod = "PUT";

exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.tokenKey = "token";
exports.usrLocalStorageKey = "usrLocalStorageKey";
exports.token2 = "token2";
exports.loggedInAlertMessage = "logged in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";


//login form  start ///
exports.heading="Welcome Back!";
exports.Subheading="Lorem Ipsum is simply dummy text of the printing and typesetting industry";
exports.labelheading="Employee ID / Iqama ID";
exports.labelrememberme="Remember me";
exports.labelforgetPassword="Forgot Password?";
exports.labellogin="Login";
exports.signinEndPoint="bx_block_login/signin"
exports.dynamicLogoCall="bx_block_settings/dynamic_contents"
exports.getNewTokenUrl="bx_block_login/new_token?refresh_token="
exports.getNewTokenMethod="GET"
exports.rememberMeKey="rememberMe"
exports.refreshTokenKey="refresh_token"
exports.rememberMeTestId="rememberMe"
exports.employeeIDTestId="employeeID"
exports.loginBtnTestId="loginBtn"
exports.idPlaceHolder="Enter Employee / Iqama ID"
exports.employeeDataEndpoint = "account_block/employees";
exports.ErrorMessage = "This field is required"
exports.EyeIconVisble = "Fix Eye Icon"

exports.logoTest = "logo-test"
//login form  start ///

// Customizable Area End