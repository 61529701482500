import React from "react";
// Customizable Area Start
import B2BCustomerShowModalStatusTabController, {
  Props,
  configJSON,
} from "./B2BCustomerShowModalStatusTabController";
import { Box, styled, Typography } from "@material-ui/core";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions } from "../../utilities/src/CustomBlockHelpers";
import { Skeleton } from "@material-ui/lab";
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.B2BCustomerViewModalStatusTab;
// Customizable Area End

export default class B2BCustomerShowModalStatusTab extends B2BCustomerShowModalStatusTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnValue = (value: string | number | null) => {
    if (typeof value === "number") {
      if (value || value === 0) {
        return value;
      } else {
        return "---";
      }
    } else {
      return value ? value : "---";
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isDataLoading } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <CustomerStatusTabWrapper>
        {renderBaseOnConditions(
          isDataLoading,
          <Skeleton animation='wave' variant='rect' height={79} />,
          <Box className={"status-header-wrapper"}>
            <Box className={"status-header-box"}>
              <Typography className={"status-header-title"}>
                {Strings.orders}
              </Typography>
              <Typography className={"status-header-name"}>
                {this.handleReturnValue(100)}
              </Typography>
            </Box>
            <Box className={"status-header-box"}>
              <Typography className={"status-header-title"}>
                {Strings.noOfPieces}
              </Typography>
              <Typography className={"status-header-name"}>
                {this.handleReturnValue(100)}
              </Typography>
            </Box>
            <Box className={"status-header-box"}>
              <Typography className={"status-header-title"}>
                {Strings.availableOrders}
              </Typography>
              <Typography className={"status-header-name"}>
                {this.handleReturnValue(100)}
              </Typography>
            </Box>
            <Box className={"status-header-box"}>
              <Typography className={"status-header-title"}>
                {Strings.availablePieces}
              </Typography>
              <Typography className={"status-header-name"}>
                {this.handleReturnValue(100)}
              </Typography>
            </Box>
          </Box>
        )}
        {renderBaseOnConditions(
          isDataLoading,
          <Skeleton
            animation='wave'
            variant='rect'
            height={475}
            style={{ margin: "24px 0" }}
          />,
          <Box className={"status-detail-wrapper"}>
            <Box className={"status-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.customerLabel}
              </Typography>
              <Typography className={"status-name"}>
                {this.handleReturnValue("Miya Waughn")}
              </Typography>
            </Box>
            <Box className={"status-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.companyLabel}
              </Typography>
              <Typography className={"status-name"}>
                {this.handleReturnValue("Saudi Aramco")}
              </Typography>
            </Box>
            <Box className={"status-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.businessAccountNameLabel}
              </Typography>
              <Typography className={"status-name"}>
                {this.handleReturnValue("Saudi Aramco")}
              </Typography>
            </Box>
            <Box className={"status-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.MobileNumberLabel}
              </Typography>
              <Typography className={"status-name"}>
                {this.handleReturnValue("Saudi Aramco")}
              </Typography>
            </Box>
            <Box className={"status-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.notesLabel}
              </Typography>
              <Typography className={"status-name"}>
                {this.handleReturnValue("----")}
              </Typography>
            </Box>
            <Box className={"status-detail-box"}>
              <Typography className={"customer-title"}>
                {Strings.privateNotesLabel}
              </Typography>
              <Typography className={"status-name"}>
                {this.handleReturnValue("----")}
              </Typography>
            </Box>
          </Box>
        )}
      </CustomerStatusTabWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomerStatusTabWrapper = styled(Box)({
  width: "100%",
  "& .status-header-wrapper": {
    padding: "8px 24px",
    border: `1px solid ${colors().lightborder}`,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gap: "8px",
    borderRadius: "8px",
  },
  "& .status-header-box": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .status-header-title": {
    fontSize: "19px",
    lineHeight: "22px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: colors().pendingColor,
  },
  "& .status-header-name": {
    fontSize: "19px",
    lineHeight: "23px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: colors().darkliver,
  },
  "& .status-detail-wrapper": {
    padding: "32px",
    background: colors().ghostwhite,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    rowGap: "24px",
    columnGap: "8px",
    margin: "24px 0",
  },
  "& .status-detail-box": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .customer-title": {
    fontFamily: "Montserrat",
    fontSize: "23px",
    lineHeight: "28px",
    fontWeight: 600,
    color: colors().cyancobaltblue,
  },
  "& .status-name": {
    fontSize: "19px",
    lineHeight: "23px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: colors().darkliver,
  },
});
// Customizable Area End
