import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { decodeString, encodeString } from "./utils";
import {  getLandingPageRedirection, navigateTo, sortCondition } from "../../utilities/src/CustomBlockHelpers";
import { makeApiMessage } from "../../../components/src/common";
import { IMyUser } from "../../navigationmenu/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employeeID: string;
  pwd: string;
  errors: { [key: string]: string; };
  selectedOption: string;
  language: string;
  snackbarOpen: boolean;
  loadingSignIn: boolean;
  snackbarMessage: string;
  imgLogo: string;
  dynamicLoginText: string;
  enablePasswordField: boolean;
  rememberMe: boolean;
  rememberMeLoginReady: boolean;
  rememberMeToken: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getNewTokenCallId: string = "";
  apiSignincall: string = "";
  apiDynamicLogo: string = "";
  getEmployeeDataId: string = "";
  passwordPlaceholder: string = "Enter Password";
  pwdFill: string = decodeString(
    window.localStorage.getItem(configJSON.token2),
    window.localStorage.getItem(configJSON.refreshTokenKey)
  );

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      employeeID: "",
      language: "choose",
      pwd: "",
      errors: {},
      selectedOption: "choose",
      loadingSignIn: false,
      snackbarOpen: false,
      snackbarMessage: "",
      imgLogo: "",
      dynamicLoginText: "",
      enablePasswordField: true,
      rememberMe: false,
      rememberMeToken: "",
      rememberMeLoginReady: false, 
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const refreshToken = window.localStorage.getItem(configJSON.refreshTokenKey);
    const rememberMe = window.localStorage.getItem(configJSON.rememberMeKey) === "true";
    if (refreshToken && rememberMe ) {
      this.getNewToken(refreshToken);
    }
    
    this.DynamicLogoCall();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.checkApiSignInResponse(message);
    this.checkEmployeeResponse(message);
    if (this.apiDynamicLogo === messageId) {
      if (apiResponse && apiResponse.data) {
        this.setState({
          imgLogo: apiResponse.data[0].attributes.icon,
          dynamicLoginText: apiResponse.data[0].attributes.value,
        });
      }
    } else if (this.getNewTokenCallId === messageId) {
      if (apiResponse && apiResponse.meta) {
        const localUsername = window.localStorage.getItem(configJSON.usrLocalStorageKey);
        if (apiResponse.meta.user_login_id === localUsername) {
          this.setState({
            employeeID: apiResponse.meta.user_login_id,
            rememberMeToken: apiResponse.meta.token,
            rememberMe: true,
            pwd: this.pwdFill,
          });
          this.setRememberMeReady();
        } else {
          this.setState({
            rememberMeLoginReady: false,
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkApiSignInResponse = (message: Message) => {
    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.apiSignincall === messageId) {
      if (apiResponse && apiResponse.meta) {
        window.localStorage.setItem(configJSON.tokenKey, apiResponse.meta.token);
        window.localStorage.setItem(
          configJSON.token2,
          encodeString(this.state.pwd, apiResponse.meta.refresh_token)
        );
        window.localStorage.setItem(configJSON.refreshTokenKey, apiResponse.meta.refresh_token);
        this.setState({ loadingSignIn: false });
        this.getMyUserData();
      } else {
        this.setState({ loadingSignIn: false });
        this.setState({
          snackbarOpen: true,
          snackbarMessage: apiResponse && apiResponse.errors[0].failed_login,
        });
      }
    }
  };

  checkEmployeeResponse = (message: Message) => {
    const messageId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.getEmployeeDataId === messageId) {
      if (apiResponse.data.attributes.role.name === "Store Keeper") {
        let permission = this.isPermissionCheck(apiResponse.data,"Order","create")
        let route = sortCondition(permission,"OrderCreation","AdvancedSearch")
        return this.handleNavigate(route as string);
      }
      if (apiResponse.data.attributes.role.name === "Cashier") {
        return this.handleNavigate("CfCashier");
      }
      const screenName = getLandingPageRedirection(apiResponse.data?.attributes)
      this.handleNavigate(screenName);
    }
  };

  isPermissionCheck(userData: IMyUser, permissionName: string, subGroupReqPermission: string) {
    const permissionObj = userData && userData.attributes.permission_groups.find(item => item.name === permissionName);
    if (permissionObj) {
      const subGroup = permissionObj.permission_sub_groups.find(subGroup => subGroup.sub_group_key === subGroupReqPermission);
      return subGroup ? subGroup.permitted : false;
    }
    return false;
  }

  ///signin api start///
  postSigninCall = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const httpBody = {
      data: {
        attributes: {
          user_login_id: this.state.employeeID,
          password: this.state.pwd,
        },
        type: "employee_account",
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiSignincall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signinEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  ///signin api end ///
  /// dynamic logo start///
  DynamicLogoCall = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiDynamicLogo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dynamicLogoCall
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /// dynamic logo end///
  handleInputChange = (event:  React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    } as unknown as S);
  };

  getNewToken = (refreshToken: string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getNewTokenCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNewTokenUrl + refreshToken
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNewTokenMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  hideError = (event: React.FocusEvent<HTMLInputElement>) => {
    this.state.errors[event.target.name] = "";
    this.setState({
      errors: this.state.errors,
    });
  };

  setRememberMeReady = () => {
    this.setState({
      rememberMeLoginReady: true,
    });
  };

  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };
  handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ language: event.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  
  clearRememberMe = () => {
    if (this.state.rememberMeLoginReady) {
      this.setState({
        rememberMeLoginReady: false,
        pwd: "",
      });
    }
  };
  setRememberMe = () => {
    this.setState({
      rememberMe: true,
    });
  };

  getMyUserData = () => {
    const apiUrlStr = configJSON.employeeDataEndpoint;

    const requestMessage = makeApiMessage({
      method: configJSON.validationApiMethodType,
      url: apiUrlStr,
    });

    this.getEmployeeDataId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    this.setState({
      rememberMe: checked,
      rememberMeLoginReady: false,
    });
    window.localStorage.setItem(configJSON.rememberMeKey, checked ? "true" : "false");
  };

  handleNavigate = (screenName: string) => {
    navigateTo({screenName,props:this.props})
  }

  formValidate = () => {
    const errorMsg = "Password cannot be blank";
    let isFormValid = true;
    if (this.state.employeeID === "") {
      isFormValid = false;
      this.state.errors["employeeID"] = "Employee ID / Iqama ID cannot be blank";
    }
    if (this.state.pwd === "") {
      isFormValid = false;
      this.state.errors["pwd"] = errorMsg;
    }
    return isFormValid;
  };

  handleSigninSubmit = () => {
    if (this.state.rememberMe) {
      window.localStorage.setItem(configJSON.tokenKey, this.state.rememberMeToken);
      window.localStorage.setItem(configJSON.usrLocalStorageKey, this.state.employeeID);
    }
    if (this.formValidate()) {
      this.setState({ loadingSignIn: true });
      this.postSigninCall();
    } else {
      this.setState({
        errors: this.state.errors,
      });
    }
  };

  // Customizable Area End
}
