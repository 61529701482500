import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export const configJSON = require("./config");
import { CustomerTypeInterface } from "./CustomerTypeController.web";
import { parsedAllIcon } from "../../categoriessubcategories/src/util";
import { debounce, uniqBy } from "lodash";
// Customizable Area End

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
}
export interface GetIconType {
  id: string,
  item: React.ReactNode,
  title: string
  attributes: {
      image: string;
      name: string;

  };

}
export interface GetIconsResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    image: string;
    name: string;
    second_name: string;
    image_type: string;
  };
}
interface ImageInterface {
  id:number,
  option:string,
  image:string
};
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface FormInteface {
  customer_type_name: string;
  maximum_points: string | null;
  minimum_points?: string | null;
  min_number_of_orders?: string | null;
  max_number_of_orders: string | null;
  icon_id: string | null | number;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formErrors: { [key: string]: string };
  form: FormInteface;
  isLoading: boolean;
  isSubmitting: boolean;
  formMode: FormMode;
  snackbarOpen: boolean;
  alertServerity: string;
  snackbarMessage: string;
  icons: any
  allIcons: any,
  dropdownOptions: any,
  id: any
  selectedImage: { id: string | number, option: string, image: string };
  currentIconData:GetIconsResponse[];
  iconSearchText: string;
  iconNextPage:number;
  responseIconList:GetIconsResponse[];
  iconList:ImageInterface[];

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerTypeAddController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  disableLoadMoreIcon = false;
  private formInitialValues: FormInteface = {
    "maximum_points": "",
    "minimum_points": "",
    "customer_type_name": "",
    "min_number_of_orders": "",
    "max_number_of_orders": "",
    icon_id: null
  };

  public RequestMessage = {
    CreateCustomerType: this.buildRequestMessage(Method.POST),
    UpdateCustomerType: this.buildRequestMessage(Method.PUT),
    GetCustomerTypeDetail: this.buildRequestMessage(Method.GET),
    GetIconsList: this.buildRequestMessage(Method.GET),
    Null: undefined as any,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formErrors: {},
      form: { ...this.formInitialValues },
      isLoading: false,
      isSubmitting: false,
      formMode: FormMode.Create,
      snackbarOpen: false,
      alertServerity: "",
      snackbarMessage: "",
      icons: [],
      allIcons: [],
      dropdownOptions: [],
      id: "",
      selectedImage:{} as { id: string | number, option: string, image: string },
      iconNextPage: 1,
      iconSearchText: "",
      currentIconData:[],
      responseIconList:[],
      iconList:[]

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    switch (callID) {
      case this.RequestMessage.GetCustomerTypeDetail.messageId:
        this.handleGetDetailResponse(response);
        break;
      case this.RequestMessage.GetIconsList.messageId:
        this.handleGetIconsListResponse(response);
        break;

      case this.RequestMessage.CreateCustomerType.messageId:
        this.handleCreateResponse(response);
        break;

      case this.RequestMessage.UpdateCustomerType.messageId:
        this.handleCreateResponse(response);
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  private handleGetDetailResponse(response: { data: CustomerTypeInterface }) {
    if (response) {      
      const tempData = {
        maximum_points: response.data.attributes.maximum_points,
        minimum_points: response.data.attributes.minimum_points,
        customer_type_name: response.data.attributes.customer_type_name,
        min_number_of_orders: response.data.attributes.min_number_of_orders,
        max_number_of_orders: response.data.attributes.max_number_of_orders,
        icon_id: response.data.attributes.icon?.id,
      };
      this.setState({
        form: tempData,
        isLoading: false,
        selectedImage:{
          id:response.data.attributes.icon?.id,
          image:response.data.attributes.icon?.image ?response.data.attributes.icon.image:"",
          option:response.data.attributes.icon?.name ?response.data.attributes.icon.name:""
        }
      });
    }
  }

  private handleGetIconsListResponse(response: { data: any }) {
    if (response) {
      const responseData = response.data || []
      this.disableLoadMoreIcon = responseData.length < 10
      const iconWholeData = uniqBy([...this.state.responseIconList,...responseData], "id")
    
      const parsedIconList:ImageInterface[] = iconWholeData.map((data: GetIconsResponse)=>{
        return {id:data.attributes.id,image:data.attributes.image,option:data.attributes.name}
      })
      this.setState({
        iconList:parsedIconList,
        currentIconData:response.data,
        responseIconList:iconWholeData,
      })
    }
  }

  private handleCreateResponse(response: { data: {
    type: string;
    attributes: any; id: string 
}; full_messages: string[] }) {
    if (response && response.data && response.data.type !== 'error' && response.data.id) {
      this.setState({
        isSubmitting: false,
        snackbarOpen: true,
        alertServerity: "success",
        snackbarMessage: `Customer type has been ${this.state.formMode === FormMode.Edit ? 'updated' : "added"} successfully`
      });
      setTimeout(() => this.props.navigation.navigate("CustomerType"), 1000)
      
    } else if(response.data?.attributes?.errors) {
      let errors = response.data?.attributes?.errors;
      const firstProperty = Object.keys(errors)[0]; // Get the first property name
      const firstValue = firstProperty === 'customer_type_name' ? `Customer type name ${errors[firstProperty]}` : errors[firstProperty];
      this.setState({
        isLoading: false,
        isSubmitting: false,
        alertServerity: "error",
        snackbarOpen: true,
        snackbarMessage: firstValue,
      });
    } else {
      this.setState({
        isLoading: false,
        isSubmitting: false,
        snackbarOpen: true,
        alertServerity: "error",
        snackbarMessage: response && response?.full_messages[0],
      });
    }
  }
 
  selectIcon = (id: string) => {
    this.setState({
      form: {
        ...this.state.form,
        icon_id : id,
      },
    });
}

  processPath = (id: string) => {
    if (id) {
      this.setState({ formMode: FormMode.Edit });
      this.getDetailCall(id);
    }
  };

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };
  
 
  onFormElementChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    property: string
  ) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: event.target.value,
      },
    });
  };

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  checkEntries = (formErrors: { [key: string]: string }) => {
    const newFormErrors = { ...formErrors };
    Object.entries(this.state.form).forEach(([key, value]) => {
      
      if(key === "icon_id") return;

      if (!value || value === undefined || value === "" || value === "null" || value.length === 0) {
        newFormErrors[key] = configJSON.fieldRequiredValidText;
      }
    });

    return newFormErrors;
  };

  handleOnSubmit = () => {
    let formErrors: { [key: string]: string } = {};
    if (!this.state.form || this.state.isSubmitting) {
      return;
    }

    formErrors = this.checkEntries(formErrors);

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false });
      return;
    }

    const { form, id } = this.state;
    this.setState({isLoading: true})
    if (this.state.formMode === FormMode.Edit) {
      this.updateCall(form, id as string);
    } else {
      this.createCall(form);
    }
  };

  fillFormErrors = () => {
    this.setState({
      formErrors: {
        maximum_points: "error",
        minimum_points: "error",
        customer_type_name: "error",
        min_number_of_orders: "error",
        max_number_of_orders: "error",
      },
    });
  };

  changeFormMode = (formMode: FormMode) => {
    this.setState({
      formMode,
    });
  };

  createCall = (form: FormInteface) => {
    this.setState({ isSubmitting: true, formErrors: {} });

    this.RequestMessage.CreateCustomerType.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({data: {attributes: form}})
    );

    runEngine.sendMessage(this.RequestMessage.CreateCustomerType.id, this.RequestMessage.CreateCustomerType);
  };

  updateCall = (form: FormInteface, customer_type_id: string) => {
    this.setState({ isSubmitting: true, formErrors: {} });

    this.RequestMessage.UpdateCustomerType.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerTypeEndPoint}/${customer_type_id}`
    );

    this.RequestMessage.UpdateCustomerType.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({data: {attributes: form} })
    );

    runEngine.sendMessage(this.RequestMessage.UpdateCustomerType.id, this.RequestMessage.UpdateCustomerType);
  };

  getDetailCall = (customer_type_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetCustomerTypeDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerTypeEndPoint}/${customer_type_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetCustomerTypeDetail.id,
      this.RequestMessage.GetCustomerTypeDetail
    );
  };

  getIconsList = () => {
    const { iconSearchText } = this.state;
    const searchQuery = iconSearchText ? `&filter_by[query]=${iconSearchText}` : "";

    this.RequestMessage.GetIconsList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.iconsEndpoint}?page_no=${this.state.iconNextPage}${searchQuery}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetIconsList.id,
      this.RequestMessage.GetIconsList
    );
  };

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerTypeEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMessage), configJSON.APIBaseURL);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method.toString());

    return requestMessage;
  }

  getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getIconsList();
    const id = this.props.navigation.getParam("id");
    this.setState({id: id}, () => {
      this.processPath(id as string);
    });
  }

  handleUpchargeProductScroll = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreIcon) return;
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    
    if (listboxNode.scrollHeight - position <= 1.30) {
      this.setState({ iconNextPage: this.state.iconNextPage + 1 }, () => {
        this.getIconsList()
      })
    }
  };

  handleSelectImage = (value:{ id: string | number, option: string, image: string }) => {
    this.setState({
      selectedImage:value,
      form: {
        ...this.state.form,
        icon_id : value.id,
      },

    })
  }

  debouncedFunction = debounce(
    (newInputValue: string) => this.handleIconAutoCompleteChange(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleIconAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.iconSearchText) return;
    this.setState({ iconSearchText: getValue, iconNextPage: 1 }, () => {
        if (getValue.length < 1 || getValue.length > 2) {
          this.getIconsList();
        }
    });
  };

  handleBlurIconDropdown = () => {
    this.disableLoadMoreIcon = false
    this.setState({iconSearchText: "", iconNextPage: 1})
  }
  // Customizable Area End
}
