Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderCreation";
exports.labelBodyText = "OrderCreation Body";

exports.btnExampleTitle = "CLICK ME";
exports.test1 = "test1";
exports.homeCleaningFacilityText = "Home cleaning functionality"

exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getAllIconsApi = "bx_block_categories/gallery_icons/list_all_icons";
exports.getAllImagesApi = "bx_block_categories/gallery_icons/list_all_images";
exports.createIconsApi = "bx_block_categories/gallery_icons";
exports.editIconsApi = "bx_block_categories/gallery_icons/";
exports.deleteIconImageApi = "bx_block_categories/gallery_icons/images";
exports.gallerySuggestionApi =
  "bx_block_categories/gallery_icons/gallery_icon_filter";

exports.applicationJsonContentType = "application/json";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypePost = "POST";
exports.apiMethidTypeDelete = "DELETE";

exports.getCatalogueListApi = "/bx_block_catalogue/catalogues";
exports.getCatalogueListDataApi = "/bx_block_catalogue/catalogues/products_list";
exports.bussinessGetCatalogueListDataApi = "/bx_block_catalogue/product_masters/order_product_list";
exports.updateEmployeeCurrentStoreApi =
  "/bx_block_store_management/store_managements/update_current_store";
exports.getStoreListApi = "/bx_block_store_management/store_managements";
exports.getPaymentMethodsListApi = "/bx_block_settings/payment_methods";
exports.calculateCartItemsTotal =
  "/bx_block_order_management/orders/calculate_cart_items_total";
exports.createNewOrder = "/bx_block_order_management/orders/";
exports.getProductPreferences =
  "/bx_block_profile_bio/customer_preferences/product_preferences";
exports.createPreferenceApiEndPoint =
  "/bx_block_profile_bio/customer_preferences/update_preferences";
exports.getOrderListApi = "/bx_block_order_management/orders/customer_orders";
exports.getTotalRewardPoints = "account_block/customers/total_reward_points";
exports.redeemPoints = "account_block/customers/redeem_points";
exports.customerSearch = "account_block/customers/auto_complete";
exports.buttonMap = [
  {
    title: "Cancel",
    cssProperties: {
      backgroundColor: "#F1F5F9",
      color: "#64748B",
      width: "126px",
      height: "56px",
      textTransform: "none",
      border: "0",
    },
  },
  {
    title: "Add",
    cssProperties: {
      backgroundColor: "#204B9C",
      color: "#ffff",
      width: "128px",
      height: "56px",
      marginLeft: "32px",
      textTransform: "none",
      border: "0",
    },
  },
];

exports.imageTitle = "Images";
exports.iconTitle = "Icons";
exports.ResolutionImage =
  "The resolution of the image size should be 129 x 129";
exports.cancel = "Cancel";
exports.add = "Add";
exports.addIcon = "Add Icon/Image";
exports.addIconBtn = "Add Icon/image";
exports.gallery = "Gallery";
exports.errorMsg = "something went wrong";
exports.editIcon = "Edit Icon/Image";
exports.selectBranch = "Select Branch";
exports.addProduct = "Add Product";
exports.noProductAvail = "No products available.";
exports.subtotal = "Subtotal";
exports.upcharges = "Upcharges";
exports.total = "Total";
exports.cartEmptyStr = "Your Cart is empty.";
exports.orderDetails = "Order Details";
exports.editPreference = "Edit Preference";
exports.clearAll = "Clear All";
exports.selectCategory = "Select The Category First For Laundry";
exports.selectSubproduct = "Select Sub-product";
exports.next = "Next"

exports.noOrderMsg = "Order not found.";
exports.homeCleaningGetOrderEndpoint = 'bx_block_order_management/home_cleaning_orders/';
exports.homeCleaningCartOrder = 'account_block/customers/home_cleaning_in_cart_order'
exports.homeCleaningProductList = 'bx_block_catalogue/home_cleaning_catalogues'
exports.homeCleaningOrderEndpoint = 'bx_block_order_management/home_cleaning_orders/'
exports.searchCustomerApiEndPoint =
  "/account_block/web_customers/search_customer_account";
exports.updatePreferenceApiEndPoint =
  "bx_block_profile_bio/customer_preferences/update_preferences";
exports.actionOrderByIdEndpoint = "bx_block_order_management/orders/";
exports.bussinessUpdateOrderEndPoint = "bx_block_order_management/b2b_orders/b2b_update"
exports.createNewOrderEndpoint = "bx_block_order_management/orders/new_order";
exports.bussinessCreateNewOrderEndpoint = "bx_block_order_management/b2b_orders/new_order_cart";
exports.updatePreferenceApiEndPoint =
  "bx_block_profile_bio/customer_preferences/update_preferences";
exports.searchCustomerApiMethod = "GET";

exports.Strings = {
  tax: 'Tax',
  pay: 'Pay',
  pcs: 'Pcs',
  type: "Type",
  name: 'Name',  
  paid: 'Paid',
  size: "Size",
  price: "Price",
  total: 'Total',
  width: "Width",
  weight: "Weight",
  remove: 'Remove',
  length: "Length",
  contact: 'Contact',
  payment: 'Payment',
  orderHas: "Order #",
  quantity: "Quantity",
  add_note: 'Add Note',
  subtotal: 'Subtotal',
  newOrder: "New Order",
  clearAll: 'Clear All',  
  add_to_bag: "Add To Bag",
  select_type: "Select Type",
  total_items: 'Total items',
  no_of_items: 'No. of Items', 
  total_items2: 'Total Items',
  mobile_number: "Mobile Number",
  order_details: "Order Details",
  plant_cleaning: "Plant Cleaning",
  service_charge: 'Service Charge',
  payment_method: "Payment Methods",
  save_for_future: 'Save for Future',
  plant_tab: "Carpet and furniture (plant)",
  add_another_carpet: "Add Another ",
  add_note_optional: "Add Note (Optional)",
  area_of_surface: "Area of Surface: ",
  non_refundable_amount: 'Non-refundable Amount',
  cleaning_tab: "Carpet and furniture (home cleaning)",
  home_cleaning_tab: "Home Cleaning",
  no_products_are_available: "No products are available at the moment.",
  home_cleaning_tab_change_warning_message: 'Non-refundable is part of home service, Home service fee is for checking at home before providing the service.',
  pickup_date: "Pickup Date",
  delivery_date: "Delivery Date",
  add_customer_warning_message: "This phone number already belongs to a customer"
}

exports.NewStrings = {
  select_sub_product: "Select Sub-product",
  continue: "Continue",
  save: "Save"
}
exports.minTab = "New Min Tabs Next Nhe"
exports.SetDecimal = "Set Decimal Points"
exports.TooltipPrice = "Tool Tip Price"
exports.TooltipProductName = "Tool Tip Produtc Name"
exports.permissionMessage = "Currently , You don't have permission to access this. Please contact Administrator.";

exports.orderItemPreferenceUpdateApiEndPoint = "bx_block_order_management/orders/update_order_item_preferences";


exports.placedTimeTest = "placed-time-test"
// Customizable Area End