import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { makeApiMessage } from "../../../components/src/common";
import { BusinessAccountCustomer } from "./utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

const initialBusinessAccountCustomer = {
  id: 0,
  type: "business_account_customer",
  attributes: {
      id: 0,
      activated: false,
      name: "",
      full_phone_number: "",
      email: "",
      department_name: "",
      department_id: "",
      cost_center: "",
      sub_cost_center: "",
      address: "",
      postal_code: "",
      notes: "",
      private_notes: "",
      no_of_order: 0,
      no_of_pieces: 0,
      business_account_id: 0,
      employee_id: "",
      sub_company_name: null,
      used_no_of_orders: null,
      used_no_of_pieces: null,
      company: {
          id: 0,
          name: "",
      },
      business_account: {
          id: 0,
          business_customer: "",
      },
      business_customer_group: {
          id: 0,
          group_name: "",
      },
      price_list: {
          id: 0,
          name: "",
      },
      city: null,
      gender: {
          id: 0,
          label: "",
      },
      full_name: "",
      country_code: "",
      phone_number: "",
  },
};

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  customerId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDataLoading: boolean;
  customerData: BusinessAccountCustomer;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2BCustomerShowModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomerViewDetailsCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      isDataLoading: false,
      customerData: initialBusinessAccountCustomer,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCustomerViewData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getCustomerViewDetailsCallId) {
        this.handleGetCustomerResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getCustomerViewData = () => {
    this.setState({ isDataLoading: true });

    const { customerId } = this.props;

    const ApiUrl = configJSON.B2BCustomerAPIEndpoint;
 
    let requestMessage = makeApiMessage({
      url: ApiUrl + "/" + customerId,
      method: "GET",
    });
    this.getCustomerViewDetailsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetCustomerResponse = (response: {
    data: BusinessAccountCustomer;
  }) => {
    if(response && response.data) {
      this.setState({ customerData: response.data });
    }
    this.setState({ isDataLoading: false });
  };
  // Customizable Area End
}
