import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ICustomer, IOrder } from "../../OrderCreation/src/OrderCreationController.web";
import moment from "moment";

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  order: IOrder;
  open: boolean;
  infoView?: boolean;
  customer: ICustomer;
  onClose?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedItems: Array<string>;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddCustomerModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedItems: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    // Customizable Area End
  }

  onSelectCheckbox(itemId: string, itemTag: string) {
    let items = this.state.selectedItems;
    if (items.includes(itemId + "-" + itemTag)) {
      items = items.filter((item) => item !== itemId + "-" + itemTag);
    } else {
      items.push(itemId + "-" + itemTag);
    }
    this.setState({ selectedItems: items });
  }


  onSelectAllCheckbox() {
    let isAll: boolean =
    this.state.selectedItems.length ===
    this.props.order.order_items.reduce(
      (paccumulator, currentValue) => paccumulator + currentValue.attributes.tag_numbers.length,
      0
    );
    this.setState({
       selectedItems: isAll
         ? []
         : this.props.order.order_items.reduce(
          (paccumulator: any, currentValue) => [
            ...paccumulator,
            ...currentValue.attributes.tag_numbers.map(
              (tagNum: any) => currentValue.id + "-" + tagNum
            ),
          ],
          []
        ),
     });
   }

   getCreatedDate = () => {
    let momentDate = moment.parseZone(this.props.order.placed_at);
    const createDateVal = momentDate.format("ddd DD MMM YYYY, hh:mm A");
    return createDateVal;
   }

   getPrintColsName() {
    return [
      {
        title: "Item",
        width: "22vw",
        type: "left",
      },
      {
        title: "Tag",
        width: "22vw",
        type: "middle",
      },
      {
        title: "Notes",
        width: "22vw",
        type: "middle",
      },
      {
        title: "Print",
        width: "22vw",
        type: "middle",
      },
    ];
  }

  // Customizable Area End
}
