import React, { useCallback } from "react";
import { InputField } from "../customComponents/CustomTags.web";
import { StyledCheckBox } from "../CustomTheme.web";
import { makeStyles } from "@material-ui/core/styles";
import { SelectAllButton } from "../../../blocks/CfPriceList/src/PriceListView.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { IProductItems } from "../../../blocks/CfPriceList/src/PriceListViewController.web";
import {
  Box,
  TextField,
  TextFieldProps,
  Popper,
  PaperProps,
  PopperProps,
  Paper,
  InputAdornment,
  Typography,
  styled,
  Radio,
} from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import { ref } from "yup";

interface Props {
  value: { id: string | number; option: string }[];
  optionList: { id: string | number; option: string; image: string }[];
  debouncedFunction: Function;
  handleScroll: Function;
  handleSelectOptions: Function;
  onBlur?: Function;
  style?: Object;
  isError?: boolean;
  customPaper?: boolean;
  customPopper?: boolean;
  changeInputOnClear?: boolean;
  selectedItem: { id: string | number; option: string; image: string };
  placeholder?: string;
  defaultId?: string | number;
  errorText?: string;
  listBoxHeight?: string;
  closeOnSelect?: boolean;
}
let errorBoolean: boolean = false;

const useStyles = makeStyles(() => ({
  newProductDropdown: {
    minHeight: 56,
    maxWidth: "100%",
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  IconImage: {
    width: "20px",
    height: "20px",
    margin: "0px 5px",
  },
  mainComp: {
    maxHeight: 200,
    width: "100%",
  },
}));

export const CommonIcomSearchComponent = ({
  value,
  optionList,
  debouncedFunction,
  handleScroll,
  handleSelectOptions,
  onBlur,
  style = {},
  isError = false,
  selectedItem,
  placeholder,
  defaultId,
  errorText,
  listBoxHeight = "200",
  closeOnSelect
}: Props) => {
  const classes = useStyles();
  const defaultValue = optionList.find(
    (option) => option.id.toString() === defaultId?.toString()
  );
  errorBoolean = isError;

  const selectedOption = selectedItem.id ? selectedItem : defaultValue;

  return (
    <SimpleCustomAutocomplete
      defaultValue={defaultValue}
      fullWidth
      value={selectedItem}
      data-test-id='autocompleteDropdown'
      options={[
        ...optionList.map((item) => ({
          id: item.id,
          option: item.option,
          image: item.image,
        })),
      ]}
      disableCloseOnSelect={!closeOnSelect}
      getOptionLabel={(option: { id: string | number; option: string }) =>
        option.option ? option.option : ""
      }
      className={classes.newProductDropdown}
      style={style}
      onChange={() => {}}
      onBlur={onBlur}
      renderOption={(
        option: { id: string | number; option: string; image: string },
        props: Object
      ) => (
        <>
          <Box
            component={"li"}
            {...props}
            id={`Image-${option.id}`}
            data-test-id={`Image-${option.id}`}
            className={classes.menuItems}
            onClick={() => {
              handleSelectOptions(option);
            }}
          >
            <Radio checked={option.id === selectedItem.id} />
            <img className={classes.IconImage} src={option.image} />
            <Typography>{option.option}</Typography>
          </Box>
        </>
      )}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <>
          <TextFieldComp
            {...params}
            style={{ paddingTop: "0px" }}
            data-test-id='autoCompleteInput'
            fullWidth={true}
            placeholder={placeholder ?? "Search Icon"}
            variant='outlined'
            className={`${isError && "isError"}`}
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
              debouncedFunction(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <Box>
                    {selectedOption?.image && (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={selectedOption?.image}
                      />
                    )}
                  </Box>
                </InputAdornment>
              ),
            }}
          />

          {isError && <ErrorText>{errorText}</ErrorText>}
        </>
      )}
      ListboxProps={{
        onScroll: handleScroll,
        style: {
          maxHeight: listBoxHeight,
        },
      }}
      PaperComponent={useCallback((props: PaperProps) => {
        return <Paper {...props} className={classes.mainComp} />;
      }, [])}
      PopperComponent={useCallback((props: PopperProps) => {
        return <Popper {...props} placement='bottom' />;
      }, [])}
    />
  );
};

const TextFieldComp = withStyles({
  root: {
    "&.isError .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "& label": {
      color: "#ECECEC",
    },
    "& label.Mui-focused": {
      color: "#ECECEC",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ECECEC",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ECECEC",
      },
      "&:hover fieldset": {
        borderColor: "#ECECEC",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ECECEC",
      },
    },
  },
})(TextField);

export const StyledMninDivWrapper = styled("div")({
  border: "1px solid #ececec",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignTtems: "center",
  borderRadius: "8px",
  marginTop: "8px",
  color: "#4d4d4d",
});
const ErrorText = styled("p")({
  fontSize: "12px",
  color: colors().error,
});
const SimpleCustomAutocomplete: any = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    "@media only screen and (max-width: 1024px)": {
      maxHeight: "44px",
      alignContent: "center",
      fontSize: "12px",
      height: "auto",
      padding: "9.5px 4px",
    },
  },
  "& .MuiChip-deleteIcon": {
    display: "none",
  },
});
