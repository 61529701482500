Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.token = "token";
exports.tokenData = "tokenData";
exports.test = "Test";
exports.test1 = "Test1";
exports.test3 = "Test3";

exports.employeeApi = "account_block/employees";

exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "bx_block_order_management/orders/store_orders";
exports.newOrderListAPiEndPoint = "bx_block_order_management/orders/store_orders_list";
exports.ordersPlantEndPoint = "bx_block_order_management/home_cleaning_orders"
exports.rateAPiEndPoint = "catalogue/reviews";

exports.changeText = "Change";
exports.cancelText = "Cancel";
exports.right="right";
exports.center="center";
exports.payBtnTabs = ["in_store", "cleaning", "ready"]

exports.getSectionListApiEndPoint = "/bx_block_categories/categories";
exports.getAllPaymentMethodEndPoint = "/bx_block_settings/payment_methods";
exports.getSectionListApiMethod = "GET";
exports.getPaymentListApiMethod = "GET";
exports.getServicesSuggestion = "/bx_block_categories/services";

exports.changeText = "Change";
exports.cancelText = "Cancel";

exports.getServicesSuggestion = "/bx_block_categories/services";
exports.updateEmployeeCurrentStoreApi =
  "/bx_block_store_management/store_managements/update_current_store";
exports.getCustomerNameDropdown =
  "/bx_block_order_management/orders/order_filter";

exports.patchMethod = "PATCH";
exports.getOrderApi = "bx_block_order_management/orders/";
exports.customerSearch = "account_block/customers/auto_complete";
exports.getStoreListApi = "bx_block_store_management/store_managements";
exports.employeeListApi =
  "account_block/employees/employee_list?filter_by[role_name]=Driver&dropdown=true";
exports.sendToDriverApi = "bx_block_order_management/orders/sent_to_driver";
exports.updateStatusToClean =
  "bx_block_order_management/orders/update_status_to_ready";
exports.updateStatusToReceived =
  "bx_block_order_management/orders/accepted_by_store";
exports.updateStatusToDelivered =
  "bx_block_order_management/orders/update_ready_orders_status";

exports.updateStatusToAnyStatus =
  "bx_block_order_management/orders/update_store_order_status";

exports.homeCleaningOrdersListApi =
  "bx_block_order_management/home_cleaning_orders?filter_by[status]=";

exports.homeCleaningOrderStatusApi =
  "bx_block_order_management/home_cleaning_orders/update_order_status";
  exports.homeCleaningOrdersCustomerFilterSuggestionEndPoint =
  "bx_block_order_management/home_cleaning_orders/auto_suggestions?customer_name=";

exports.homeCleaningOrderSendToDriverApi =
  "bx_block_order_management/home_cleaning_orders/sent_to_driver"
exports.getOrderDetailsApi = 
  "/bx_block_order_management/orders";

exports.getOrderItemsList = 
  "/bx_block_order_management/orders/get_order_items?id=";

exports.createRewashOrderApi = 
  "/bx_block_order_management/orders/new_order";

exports.ListingPage = {
  Strings: {
    from: "From",
    to: "To",
    orders: "Orders",
    pieces: "Pieces",
    value: "Value",
    sendToDriver: "Send To Driver",
    unpaidAmount: "Unpaid Amount",
    orderId: "Order#",
    orderCreated: "Order created",
    placedDate: "Placed Date",
    customer: "Customer",
    contact: "Contact",
    order: "Order",
    pcs: "Pcs",
    notes: "Notes",
    paid: "Paid",
    total: "Total",
    status: "Status",
    orderUnPaid: "Order Unpaid",
    orderUnpaidDiaglogPara:
      "You need to pay for the order first to change the status of the order",
    orderSinglePayment: "Please select only single unpaid order at a time!",
    acceptOrder: "Accept"
  },
};

exports.PrintDialog = {
  printTag: "Re-Print the Tag",
  selectFromBelow: "Select from below",
  printReceipt: "Re-Print the Receipt",
  printRawReceipt: "Re-Print the Receipt(Raw)",
  printDriverReceipt: "Print the Receipt",
  cancel: "Cancel",
  submit: "Submit",
};

exports.DetailsPage = {
  ApiUrls: {
    updateOrder: "/bx_block_order_management/orders/",
    updateB2bOrder: "/bx_block_order_management/b2b_orders/b2b_update",
    getServiceList: "bx_block_categories/services?dropdown=true",
    getPreferencesList: "bx_block_categories/preference_lists?dropdown=true&allow_access=true",
    createProduct: "bx_block_catalogue/catalogues/create_store_product",
    getCategoryList: "/bx_block_categories/categories?dropdown=true&token=",
    updateToReceived: "/bx_block_order_management/orders/accepted_by_store",
    getOrderItemsList: "/bx_block_order_management/orders/get_order_items?id=",
    getHomeCleaningOrderItemList : "/bx_block_order_management/home_cleaning_orders/get_order_items?id="
  },
  Strings: {
    dialogTitle: "Order Details",
    orderTitle: "Order ID - ",
    parentOrderTitle: "Parent Order ID - ",
    creditOrderNumberTitle: "Credit Order ID - ",
    customer: "Customer",
    contact: "Contact",
    orderCrated: "Order Created",
    status: "Status",
    totalItems: "Total Items",
    totalPice: "Total Pieces",
    customerComplaint: "Customer Complaint",
    products: "Products",
    qty: "Qty:",
    services: "Services",
    color: "Color",
    damage: "Damage",
    stains: "Stains",
    pattern: "Pattern",
    material: "Material",
    preference: "Preference",
    upcharge: "Upcharge",
    total: "Total",
    notes: "Notes",
    edit: "Edit",
    cancel: "Cancel",
    close: "Close",
    save: "Save",
    delete: "Delete",
    receive: "Receive",
    sar: "SAR",
    paidBy: "- PAID by",
    addProduct: "Add Product",
    table: {
      products: "Products",
      qty: "Qty",
      pcs: "Pcs",
      service: "Service",
      color: "Color",
      damage: "Damage",
      stains: "Stains",
      section: "Section",
      patterns: "Patterns",
      material: "Material",
      preference: "Preference",
      upcharge: "Upcharge",
      price: "Price",
    },
  },
};

exports.NoReceiptMessage = "Unable to fetch receipt for this order";

exports.CleaningOrders = {
  action: "Action",
  details: "Details",
  payLater: "Pay Later",
  selectBranch: "Select branch",
  homeCleaningOrders: "Home Orders",
  sendTo: "Send To",
  pleaseSelectOrders: "Please select orders",
  pleaseSelectOnlyAcceptedOrders: "Please select only accepted orders",
  pleaseSelectDriver: "Please select driver",
  cancel: "Cancel",
  submit: "Submit",
  move: "Move",
  table: {
    orderId: "Order Id",
    orderCreated: "Order Created",
    customer: "Customer",
    contact: "Contact",
    order: "Order",
    pcs: "Pcs",
    notes: "Notes",
    paid: "Paid",
    total: "Total",
    status: "Status"
  },
};

exports.RewashOrder = {
  rewashItems: "Rewash Items",
  orderId: "Order ID",
  orderCreationDate: "Order Creation Date",
  customerName: "Customer Name",
  selectProductToRewash: "Select Products to Rewash",
  addInstructions: "Add Instructions",
  customerComplaint: "Customer Complaint",
  priceToPay: "Price to Pay",
  free: "Free",
  cancel: "Cancel",
  rewash: "Rewash",
  selectItems: "Select Items",
  errorMsg: "Please select product to rewash"
}


exports.OrdersTab = {
  orders: "Orders",
  store: "Store",
  cleaning: "Cleaning",
  ready: "Ready",
  delivered: "Delivered",
  deadstock: "Deadstock",
  cancelled: "Cancelled"
}

exports.rewashErrorMsg = "You can not create rewash order from rewash order";
exports.successOrder = "Rewash order has been created successfully.";
exports.plantCleaningTxt = "Plant Cleaning"
exports.carpetAndFurnitureTxt = "Carpet and furniture"
exports.updatePlantStoreStatusEndpoint = "bx_block_order_management/home_cleaning_orders/update_plant_store_status"

exports.getPlantHomeCleaningOrder = "/bx_block_order_management/home_cleaning_orders"
exports.plantHomeCleaningOrderRewash = "bx_block_order_management/home_cleaning_orders/new_order"
exports.zatcaLogsHistoryEndPoint = "bx_block_order_management/orders/zatca_logs";
exports.rawOrderInvoiceData = "bx_block_order_management/orders/generate_row_data_order_invoice?id="

exports.stringsListPage_table_item = "Item";
exports.stringsListPage_table_type = "Type";
exports.stringsListPage_table_qty = "Qty";
exports.stringsListPage_table_size = "Size";
exports.stringsListPage_table_weight = "Weight";
exports.stringsListPage_table_price = "Price";
exports.stringsListPage_table_notes = "Notes";
exports.stringsListPage_table_storeNameVal = "Store Name"
exports.stringsListPage_table_orderId = "Order ID"
exports.stringsListPage_table_totalItems = "Total Item"
exports.stringsListPage_table_orderTypeVal = "Order Type"
exports.stringsListPage_table_totalAmt = "Total Amount"
exports.stringsListPage_table_credit = "Credit"
exports.stringsListPage_table_serviceCharge = "Service Charge"
exports.stringsListPage_table_nonRefundableAmount = "Non-refundable Amount"
exports.stringsListPage_table_cancel = "Cancel"
exports.tabOrderTypeID = "tabOrderType"
exports.checkOrders = "Check Order"
exports.storeKeeper = "Store Keeper"
exports.test = "test" 
exports.test2 = "test2"
exports.placedDateTest = "placed-date";
exports.RedirectionRestrcition = "Redirection Restrcition"
exports.UpdateRedyStatus = "Update Ready Status"
exports.OrderPermission = "Permission Order"
exports.credit_note_button = "Credit Note";
exports.credit_note_view_button = "View Credit Order";
exports.stringsCreditNotePage_CreateCreditNoteHeader = "Credit Note";
exports.stringsCreditNotePage_CancelButton = "Cancel";
exports.stringsCreditNotePage_NextButton = "Next";
exports.stringsCreditNotePage_CreateCreditNoteButton = "Create Credit Note";
exports.stringsCreditNotePage_CreditNote_Add_Note = "Add Note";
exports.stringsCreditNotePage_AdjustMentReason = "Adjustment Reason";
exports.stringsCreditNotePage_table_heading = "Order Details";
exports.stringsCreditNotePage_AdjustMentDetails = "Adjustment Details";
exports.stringsCreditNotePage_AddedNewUpcharge = "Added New Upcharge";
exports.EditProductApiLoading = "Edit Product Api Loading";
exports.stringsCreditNotePage_ConfirmationText =
  "I confirm that the adjustments and refund details are accurate.";
exports.CustomerDetails="Customer Details"

exports.createCreditNoteEndPoint = "bx_block_refundmanagement/refund_orders";
exports.getCreditNoteEndpoint =
  "bx_block_refundmanagement/refund_orders/get_order_refund?order_id=";
exports.generateOrderInvoiceEndPoint = "bx_block_order_management/orders/generate_order_invoice?id=";
exports.zatacHistory = {
  zatcaHistory: "Zatca History",
  fromDate: "From Date",
  toDate: "To Date",
  filter: "Filter",
  clear: "Clear",
  orderId: "Order ID",
  invoiceId: "Invoice ID",
  type: "Type",
  customer: "Customer",
  taxable: "Taxable",
  tax: "Tax",
  total: "Total",
  sendToZatca: "Send to zatca",
  statusCode: "Status code",
  zatcaStatus: "Zatca Status",
  noDataFound: "No Data Found",
  permissionMessage: "Currently , You don't have permission to access this. Please contact Administrator."
}

exports.orderDetailTest = "order-detail-test";
exports.dialogActionsTest = "dialog-actions-test";
// Customizable Area End