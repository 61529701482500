import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import moment from "moment";

import { IFilter } from "../../../components/src/FilterPopover";
import {
  apiCall,
  makeApiMessage as MakeApiMessage,
} from "../../../components/src/common";
import { checkedIcon, unCheckedIcon } from "./assets";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { Box, TextField } from "@material-ui/core";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import {
  ICustomer,
  IOrder,
} from "../../OrderCreation/src/OrderCreationController.web";
import { IUserContext } from "../../../components/src/PageContainer/PageContainerController";
import {
  checkForOrderPermissonStatus,
  CustomEnums,
  getCustomEnumName,
  navigateTo,
  customPermissionApiKey,
  OrderPermissionStatus,
  checkIsFilterApplied,
  sortStringCondition,
  renderBooleanConditions,
  sortCondition
} from "../../utilities/src/CustomBlockHelpers";
import { IOrderData } from "./OrderDetailsController.web";
import { IMyUser } from "./../../navigationmenu/src/utils";
import { toString, uniqBy, debounce } from "lodash";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { ReceiptPrinterSetting } from "../../navigationmenu/src/HeaderController.web";
import { globalReceiptPrinterSetting, InvoiceRawData, SalesPostedRawData } from "../../print/src/PrintController";

const TAB_STATUS_LIST = [
  "coming_to_store",
  "in_store",
  "cleaning",
  "ready",
  "delivered",
  "send_to_deadstock",
  "cancelled"
];

const TAB_SETTING_MAP = {
  2: "receipt_print_clean_page",
  3: "receipt_print_ready_page",
  4: "receipt_print_delivered_page"
} as { [key: number]: keyof ReceiptPrinterSetting }

export interface StoreOrder {
  id: string;
  type: string;
  checked?: boolean;
  attributes: {
    id: number;
    order_number: string;
    account_id: number;
    notes: string;
    created_at: string;
    internal_status: string;
    is_quick_drop: boolean;
    send_to_zatca: boolean;
    plant_status_display_name: string;
    order_items: {
      name: string;
      qty: number;
      second_name: string;
      service_short_name: string;
    }[];
    total_items: number;
    total_pieces: number;
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
    };
    paid: {
      id: number;
      name: string;
    }[];
    paid_at: string;
    currency: string;
    total: string;
    rewash_order: boolean;
  };
}

interface Config {
  maxWidth: number;
  config: string;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface Option {
  label: string;
  value: string;
  tabId: number;
}

export interface PermissionOption {
  label: string;
  value: string;
  tabId: number;
  permission: boolean
}

export interface MetaData {
  orders?: number;
  pieces?: number;
  total?: string;
  unpaid_amount?: string;
  currency?: string;
  total_pages?: number;
  total_count?: number;
  current_page?: number;
  next_page?: number;
  pervious_page?: number;
}

export interface IStoreItem {
  id: string;
  type: string;
  attributes: StoreAttributes;
}

export interface StoreAttributes {
  id: string;
  store_name: string;
}

export interface FilterNumberResponse {
  id: number;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  full_name: string;
}

export interface SectionOptionResponse {
  active: boolean;
  id: number;
  label: string;
  selected?: boolean;
}
export interface PaymentOptionResponse {
  active: boolean;
  id: number;
  name: string;
  selected?: boolean;
}

export interface SectionListResponse {
  id: number;
  attributes: { 
    section_name: string; 
    active: boolean 
  }
}

export interface EmployeeListResponse {
  attributes: {
    id: string, 
    first_name: string
  }
}

export interface SendToDriverResponse {
  use_raw_printing_receipt: boolean
  errors?: string; 
  status?: number, 
  message?: string
  data?: SalesPostedRawData
}
// Customizable Area End

export interface S {
  // Customizable Area Start
  orderToPrint: IOrderData | null;
  driverList: Array<Option>;
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  ordersList: IOrderData[];
  dropDownList: PermissionOption[];
  page: number;
  pageSize: number;
  isModalOpen: boolean;
  deactivated: boolean;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  clickedCustomerType: StoreOrder | undefined;
  tabId: number;
  query: string;
  searchText: string;
  searchResult: boolean;
  isSuperAdmin?: boolean;
  showCheckBoxAndButton: boolean;
  selectedDropdownvalue: string | null;
  selectAllChecked: boolean;
  dropdownOptions: { section: string[] };
  sectionOptions: SectionOptionResponse[];
  paymentOptions: PaymentOptionResponse[]
  driversList: {label: string, value: string}[]
  orderMetaData: MetaData;
  storeList: {id:string; option: string}[];
  userData:  IMyUser;
  orderDetailPopup: boolean;
  orderData: IOrderData;
  selectedDriver: string;
  sendToDriverError: string;
  selectedOrders: Array<IOrderData>;
  userRefresh: Function;
  customerFilterNameList: string[];
  customerFilterNumberList: FilterNumberResponse[];
  customerNumberValue: string;
  unpaidDialogPopup: boolean;
  paymentModalVisible: boolean;
  customerId: string;
  customerData?: ICustomer;
  order?: { attributes: IOrder } | null;
  sortingData: ISortingData;
  storeId: string;
  storeName: string;
  isDrawerOpen: boolean;
  paymentClicked: boolean;
  unPaidOrderError: string;
  storeListPageNo: number;
  storeSearchText: string;
  selectedTab: string;
  errorMessage: string;
  unpaidDialogDetail:IOrderData[];
  permissionStatus: OrderPermissionStatus;
  driverReceipt: string | SalesPostedRawData;
  useRawPrinting: boolean;
  showDriverReceiptConfirmation: boolean;
  selectedOrder: IOrderData | null;
  showDirectPaymentModal: boolean;
  ispaymentClicked: boolean;
  isButtonLoading: boolean;
  isLoadingOrderList: boolean
  isScanningOrder: boolean
  printerSetting: ReceiptPrinterSetting | null

  creditNoteOrder?: IOrderData;
  creditNotePresent?: boolean;
  isCreditNoteDialogOpen: boolean;
  creditNoteReceipt: string;
  hidden: boolean;
  addNote: string;
  isConfirmationChecked: boolean;
  creditLoader: boolean;
  openCrediNoteModalLoader: boolean;
  creditNoteOrderNumber: number | null;
  snackbarMessage: string;
  snackBarSeverity: string;
  isAppliedFilter: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrdersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCallId: string = "";
  getStoreCallId: string = "";
  getOrderMessageId: string = "";
  getOrderReceiptCallId: string = "";
  getCustomerNameCallId: string = "";
  getCustomerNumberCallId: string = "";
  getServicesSuggestion: string = "";
  updateEmployeeCurrentStoreApiId: string = "";
  tableRefs: HTMLElement | null = null;
  getSectionListApiCallId: string = "";
  getPaymentListApiCallId: string = "";
  employeeListMessageId: string = "";
  sendToDriverMessageId: string = "";
  getDriverReceiptMessageId: string = "";
  updateStatusToCleanMessageId: string = "";
  updateStatusToReceivedMessageId: string = "";
  updateStatusToDeliveredMessageId: string = "";
  updateStatusToAnyMessageId: string = "";
  updateStatusToDeadStocksMessageId: string = "";
  updatePlantCarpetFurnitureStatusMsgId: string = "";
  updateSendToDriverCarpetFurOrderMsgId: string = "";
  textLabelStyle: React.CSSProperties;
  textInputStyle: React.CSSProperties;
  storeListEmptyFlag = false;
  getOrderDetailMessageId: string = "";
  getOrderItemsMessageId: string = "";
  getCreditNoteMessageId: string = "";
  getCreateCreditNoteMessageId: string = "";
  generateOrderInvoiceCallId: string = "";
  getRawOrderInvoiceDataCallId: string = "";
  scanOrderApiCallId: string = "";

  scannedOrderId = "" 
  
  savedRawPrintDatasMap = new Map<string, InvoiceRawData>()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.PrintSettingsMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getCustomEnumName(CustomEnums.DataForRedirectionFromNotification),
      getCustomEnumName(CustomEnums.CustomReducerPayload),
      getCustomEnumName(CustomEnums.ReceiptPrinterSettingUpdated)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.textLabelStyle = {
      fontFamily: "Montserrat",
      color: "#000",
      lineHeight: "22px",
      fontWeight: 600,
      fontSize: "14px",
    };
    this.textInputStyle = {
      marginTop: "4px",
      height: "44px",
      width: "190px",
      fontFamily: "Montserrat !important",
      marginBottom: "8px",
    };
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      paymentClicked: false,
      storeListPageNo: 1,
      storeSearchText: "",
      orderToPrint: null,
      selectedOrders: [],
      sendToDriverError: "",
      selectedDriver: "",
      driverList: [],
      ordersList: [],
      dropDownList: [],
      userRefresh: () => {},
      page: 1,
      pageSize: 100,
      popOverItemId: "",
      popOverItemStatus: "",
      isModalOpen: false,
      deactivated: false,
      drawerWidth: 0,
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      filterAnchor: undefined,
      customerFilterNameList: [],
      customerFilterNumberList: [],
      customerNumberValue: "",
      printerSetting: {...globalReceiptPrinterSetting},
      filters: [
        {
          title: "Services",
          type: "autocompolete",
          value: "",
          apiKey: "services",
          options: [],
        },
        {
          title: "Section",
          value: "",
          type: "multipleSelect",
          options: [],
        },
        {
          title: "Customer",
          type: "custom",
          value: "",
          onClear: () => {
            return this.clearCustomerFilter();
          },
          customRender: () => {
            return this.renderfilterCustomer();
          },
        },
        {
          title: "Payment Method",
          type: "multipleSelect",
          value: "",
          options: []
        },
        {
          title: "Payment Status",
          type: "select",
          options: [
            {
              label: "Confirmed",
              value: "Paid",
            },
            {
              label: "Pending",
              value: "Unpaid",
            }
          ],
          value: "",
        },
        {
          title: "Status",
          type: "select",
          options: [
            {
              label: "Cleaning",
              value: "cleaning",
            },
            {
              label: "Ready to Deliver",
              value: "ready",
            },
          ],
          value: "",
        },
        {
          title: "Due",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          value: "",
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Today & Overdue",
              value: "over_due",
            },
            {
              label: "Tomorrow",
              value: "tomorrow",
            },
            {
              label: "Specific Dates",
              value: "specific",
            }
          ],
        },
        {
          title: "Order Type",
          type: "select",
          options: [
            {
              label: "B2C Order",
              value: "b2c_orders",
            },
            {
              label: "B2B Order",
              value: "b2b_orders",
            }
          ],
          value: "",
        },
       
      ],
      clickedCustomerType: undefined,
      tabId: 0,
      searchText: "",
      searchResult: false,
      sectionOptions: [],
      paymentOptions: [],
      query: "",
      showCheckBoxAndButton: false,
      selectedDropdownvalue: "",
      selectAllChecked: false,
      dropdownOptions: {
        section: [],
      },
      driversList: [{ label: "Driver 1", value: "value" }],
      orderMetaData: {},
      storeList: [],
      userData: {} as IMyUser,
      orderDetailPopup: false,
      orderData: {} as IOrderData,
      unpaidDialogPopup: false,
      paymentModalVisible: false,
      customerId: "",
      sortingData: {
        order_number: "",
        created_at: "",
        customer: "",
        full_phone_number: "",
        total: "",
        internal_status: "",
      },
      storeId: "",
      storeName: "",
      unPaidOrderError: "",
      isDrawerOpen: localStorage.getItem("isPinned") == "true",
      selectedTab: configJSON.plantCleaningTxt,
      errorMessage: "",
      unpaidDialogDetail:[],
      permissionStatus: {
        mainPermission: false,
        createOrderPermission: false,
        viewOrderPermission: false,
        editOrderPermission: false,
        sendToDriverAction: false,
        cleanAction: false,
        rewashAction: false,
        sendToDeadStockAction: false,
        storeDropdownAction: false,
        cleaningDropdownAction: false,
        readyDropdownAction: false,
        zatcaLogsAccess: false,
        viewCreditOrderAccess: false,
        createCreditNoteAccess: false
      },
      driverReceipt: "",
      useRawPrinting: false,
      showDriverReceiptConfirmation: false,
      selectedOrder: null,
      showDirectPaymentModal: false,
      ispaymentClicked: false,
      isButtonLoading: false,
      isLoadingOrderList: true,
      isScanningOrder: false,
      creditNoteReceipt: "",
      hidden: true,
      isCreditNoteDialogOpen: false,
      creditNoteOrder: undefined,
      creditNotePresent: false,
      addNote: "",
      creditLoader: false,
      isConfirmationChecked: true,
      openCrediNoteModalLoader: false,
      creditNoteOrderNumber: null,
      snackbarMessage: "",
      snackBarSeverity: "",
      isAppliedFilter: false
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      // Customizable Area Start
      this.getSectionListApiCallResponse(apiRequestCallId, responseJson);

      this.handlePaymentAllMethodResponse(apiRequestCallId,responseJson);

      this.sendToDriverApiCallResponse(apiRequestCallId, responseJson);

      this.getDriverReceiptApiCallResponse(apiRequestCallId, responseJson);

      this.updateStatusToCleanApiCallResponse(apiRequestCallId, responseJson);

      this.handleEmployeeListApiCallResponse(apiRequestCallId, responseJson);

      this.updateStatusToReceivedApiCallResponse(
        apiRequestCallId,
        responseJson
      );

      this.updateStatusToDeliveredApiCallResponse(
        apiRequestCallId,
        responseJson
      );

      this.updateStatusToAnyApiCallResponse(
        apiRequestCallId,
        responseJson
      );

      this.updateStatusToDeadStockApiCallResponse(
        apiRequestCallId,
        responseJson
      );

      this.handelGetOrderResponse(apiRequestCallId, responseJson);

      this.handleServiceSuggestionResponse(apiRequestCallId, responseJson);

      this.handelStoreResponse(apiRequestCallId, responseJson);

      this.handelCallIdResponse(apiRequestCallId, responseJson);

      this.handleGetOrderDetailResponse(apiRequestCallId, responseJson);

      this.handleGetOrderItemsResponse(apiRequestCallId, responseJson)

      this.handleCreditNoteResponse(message);

      this.handleReGenerateInvoiceResponse(
        apiRequestCallId,
        responseJson
      );

      this.handleRawInvoiceDataResponse(
        apiRequestCallId,
        responseJson
      )

      this.handleScanOrderResponse(
        apiRequestCallId,
        responseJson
      )

      if (apiRequestCallId === this.getCustomerNameCallId) {
        this.setState({ customerFilterNameList: responseJson.filter_names });
      } else if (apiRequestCallId === this.getCustomerNumberCallId) {
        this.setState({ customerFilterNumberList: responseJson.customer });
      } else if (apiRequestCallId === this.updateEmployeeCurrentStoreApiId) {
        this.state.userRefresh();
      }
      // Customizable Area End
    }

    // Customizable Area Start
    this.receivePrintSettings(message)
    this.fetchDataFromLayout(message)
    this.fetchSearchTextData(message)
    this.receiveDataFromHeader(message)
    if (
      message.id === getCustomEnumName(CustomEnums.DataForRedirectionFromNotification)
    ) {
      const {
        order_number,
        internal_status,
        store_id
      } = message.properties.orderData;
      
      // set tab id based on internal status
      let tabStatusList = [
        "coming_to_store",
        "in_store",
        "cleaning",
        "ready",
        "delivered",
        "send_to_deadstock",
        "cancelled"
      ];

      const findTabindex = tabStatusList.findIndex((tabStatus) => tabStatus === internal_status);

      this.setState({
        searchText: order_number,
        tabId: findTabindex,
        selectedTab: "Plant Cleaning",
        storeId: store_id
      }, () => {
        this.getOrdersList()
    })
    }

    if (message.id === getCustomEnumName(CustomEnums.ReceiptPrinterSettingUpdated)) {
      this.handleUpdatePrinterSetting()
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleStorageFilter();
    // Customizable Area End
  }

  // Customizable Area Start

  receiveDataFromHeader = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      if (recievedData.singleStoreID) {
        let NotificationNavigation = localStorage.getItem("NotificationNavigation")
        if(NotificationNavigation){
          return localStorage.removeItem("NotificationNavigation")
        }
        this.handleStoreId({ id: recievedData.singleStoreID, option: recievedData.singleStoreName });
        this.handleTabChange(1);
      }
    }
  }

  fetchSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.SearchMessageText)
      );
      if (recievedData) {
        this.handleSearch(recievedData.searchText)
      }
    }
  }

  fetchDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
            this.setState({ isDrawerOpen: recievedData.isDrawerOpen})
            this.handleUserChange(recievedData.userContext)
        }
    }
  }

  receivePrintSettings = (message: Message) => {
    if (message.id === getName(MessageEnum.PrintSettingsMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.PrintSettngsData)
        );
        if (recievedData) {
          console.log(recievedData,"recievedDatarecievedData")
            this.setState({ printerSetting: recievedData.receiptPrinterSetting})
        }
    }
  }

  setDropdownList = () => {
    const { permissionStatus } = this.state;
    
    const dropdownList = [
      {
        label: "Send To Driver",
        value: "Send to driver",
        tabId: 1,
        permission: permissionStatus.sendToDriverAction
      },
      {
        label: "Received", 
        value: "Received",
        tabId: 0,
        permission: true
      },
      {
        label: "Run Audits",
        value: "Run audits",
        tabId: 3,
        permission: true
      },
      {
        label: "View Audits",
        value: "View audits",
        tabId: 3,
        permission: true
      },
      {
        label: "Delivered",
        value: "Delivered",
        tabId: 3,
        permission: true
      },
      {
        label: "Send to Dead Stocks",
        value: "Send to dead stocks",
        tabId: 3,
        permission: permissionStatus.sendToDeadStockAction
      },
      { label: "Ready", value: "ready", tabId: 1,permission:false },
      { label: "Delivered", value: "delivered", tabId: 1,permission:false },
      { label: "Send to Dead Stocks", value: "send_to_deadstock", tabId: 1,permission:false },

      { label: "Store", value: "in_store", tabId: 2,permission:true },
      { label: "Ready", value: "ready", tabId: 2,permission:permissionStatus.cleanAction },
      { label: "Delivered", value: "delivered", tabId: 2,permission:true },
      { label: "Send to Dead Stocks", value: "send_to_deadstock", tabId: 2,permission:permissionStatus.sendToDeadStockAction },

      { label: "Store", value: "in_store", tabId: 3, permission:permissionStatus.storeDropdownAction },
      { label: "Cleaning", value: "cleaning", tabId: 3, permission:permissionStatus.cleaningDropdownAction },

      { label: "Store", value: "in_store", tabId: 4,permission:permissionStatus.storeDropdownAction },
      { label: "Ready", value: "ready", tabId: 4 ,permission:permissionStatus.readyDropdownAction },
      { label: "Send to Dead Stocks", value: "send_to_deadstock", tabId: 4 ,permission:permissionStatus.sendToDeadStockAction },
      { label: "Cleaning", value: "cleaning", tabId: 4, permission:permissionStatus.cleaningDropdownAction },

      { label: "Store", value: "in_store", tabId: 5, permission:true },
      { label: "Ready", value: "ready", tabId: 5, permission:true },
      { label: "Delivered", value: "delivered", tabId: 5, permission:true },
      { label: "Cleaning", value: "cleaning", tabId: 5, permission:true }
    ]
    this.setState({
      dropDownList: dropdownList
    })
  }

  handelCallIdResponse(
    apiRequestCallId: string,
    responseJson: { data: []; meta: {} }
  ) {
    if (apiRequestCallId !== this.getCallId) return;

    this.savedRawPrintDatasMap.clear()
    if (responseJson && responseJson.data && responseJson.data.length) {
      const array:IOrderData[] = responseJson?.data?.map((item: IOrderData) => ({
        ...item,
        checked: false,
      }));
      let tabId = sortCondition(this.state.searchResult,TAB_STATUS_LIST.indexOf(array[0].attributes.internal_status),this.state.tabId)
      this.setState({
        ordersList: array as IOrderData[],
        orderMetaData: { ...responseJson.meta },
        isLoadingOrderList: false,
        searchResult: false,
        tabId: tabId as number
      });
    } else this.setState({ ordersList: [], orderMetaData: {} });
  }

  handelStoreResponse(apiRequestCallId: string, responseJson: { data?: {id: string; attributes: {store_name: string}}[] }) {
    if (apiRequestCallId === this.getStoreCallId) {
      const { storeList, storeListPageNo, storeId, storeName } = this.state
      let currentStore = storeList.find(store => store.id === storeId)
      if (storeId && !currentStore) {
        currentStore = {
          id: storeId,
          option: storeName
        }
      }
      const array = (responseJson?.data || []).map(item => ({id: item.id, option: item.attributes.store_name}))
      const newStoreList = storeListPageNo === 1 ? array: [...storeList, ...array]
      currentStore && newStoreList.unshift(currentStore)
      this.storeListEmptyFlag = array.length < 10
      this.setState({
          storeList: uniqBy(newStoreList, "id")
      });
    }
  }

  handleServiceSuggestionResponse(
    apiRequestCallId: string,
    responseJson: { filter_names: [] }
  ) {
    if (apiRequestCallId === this.getServicesSuggestion) {
      const list =
        responseJson?.filter_names?.map((value: string) => ({
          label: value,
          value: value,
        })) || [];
      const updatedFilters = this.state.filters.map((item: IFilter) => {
        if (item.title === "Services") item.options = list;
        return item;
      });
      this.setState({ filters: updatedFilters });
    }
  }

  formatDate(stringDate: string) {
    if (!stringDate) return "--";
    let date = new Date(stringDate);
    const dayValue = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Note: Months are 0-based, so we add 1
    const year = String(date.getFullYear());

    return `${dayValue}/${month}/${year}`;
  }
  
  handelGetOrderResponse(
    requestId: string,
    response: { data: { attributes: IOrder } }
  ) {
    if (this.getOrderMessageId === requestId) {
      if (response.data) {
        this.setState({ order: response.data });
        if (this.state.paymentClicked) {
          this.setState(
            {
              selectedOrders: [response.data as unknown as IOrderData],
              paymentModalVisible: false,
              unpaidDialogPopup: false,
              paymentClicked: false,
              order: null,
            },()=> this.handleSendToDelivered([response.data as unknown as IOrderData])
          );
        }
      }
    }
  }
  
  getEmployeeList() {
    const message = MakeApiMessage({
      method: "get",
      url: configJSON.employeeListApi + "&allow_access=true",
    });
    this.employeeListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  updateEmployeeCurrentStore = (storeId: number) => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token: localStorage.getItem("token"),
    };

    const bodyData = {
      store_id: storeId,
    };

    const messageEmp = new Message(getName(MessageEnum.RestAPIRequestMessage));

    messageEmp.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateEmployeeCurrentStoreApi
    );
    messageEmp.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    messageEmp.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    messageEmp.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    this.updateEmployeeCurrentStoreApiId = messageEmp.messageId;
    runEngine.sendMessage(messageEmp.id, messageEmp);
  };

  clearCustomerFilter = () => {
    let temp = [...this.state.filters];
    const indexValue = this.state.filters.findIndex(
      (item) => item.title === "Customer"
    );
    temp[indexValue].value = "";

    this.setState({ customerNumberValue: "", filters: [...temp] });
  }

  renderfilterCustomer = () => {
    let filterName = this.state.filters.find(
      (object: {title: string}) => object.title === "Customer"
    );
    return (
      <Box>
        <div
          style={{
            marginLeft: "24px",
            marginRight: "24px",
          }}
        >
          <p style={{ marginBottom: "16px" }}>Search Customer By</p>

          <>
            <div style={this.textLabelStyle}>{"Name"}</div>
            <AutoComplete
              freeSolo
              value={filterName?.value}
              disableClearable
              options={
                this.state.customerFilterNameList?.map(
                  (option: string) => option
                ) || []
              }
              onChange={(event, value) => {
                let tempFilters = [...this.state.filters];
                let indexValue = tempFilters.findIndex(
                  (object: {title: string}) => object.title === "Customer"
                );
                tempFilters[indexValue].value = value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  style={this.textInputStyle}
                  variant='outlined'
                  type={"text"}
                  placeholder='Type to Select'
                  onChange={(event) => this.handleCustomerInputChange(event)}
                />
              )}
            />
          </>
          <p style={{ marginTop: "16px", marginBottom: "16px" }}>Or</p>
          <>
            <div
              style={{
                fontFamily: "Montserrat",
                color: "#000",
                lineHeight: "22px",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              {"Number"}
            </div>
            <AutoComplete
              freeSolo
              value={this.state.customerNumberValue}
              disableClearable
              options={
                this.state.customerFilterNumberList?.map(
                  (option: FilterNumberResponse) => option.full_phone_number
                ) || []
              }
              onChange={(event, value) => {
                this.setState({ customerNumberValue: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  style={{
                    marginTop: "4px",
                    height: "44px",
                    width: "190px",
                    fontFamily: "Montserrat !important",
                    marginBottom: "8px",
                  }}
                  variant='outlined'
                  type={"text"}
                  placeholder='Type to Select'
                  onChange={(event) => this.handleCustomerNumberInputChange(event)}
                />
              )}
            />
          </>
        </div>
      </Box>
    );
  };

  handleCustomerInputChange = (event: React.ChangeEvent<{value: string}>) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const apiUrl =
      configJSON.getCustomerNameDropdown + `?customer_name=${event.target.value}`;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomerNameCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };
  handleCustomerNumberInputChange = (event: React.ChangeEvent<{ value: string }>) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const apiUrl =
      configJSON.customerSearch + `?full_phone_number=${event.target.value}`;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomerNumberCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };
  getStoreList = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const searchQuery = this.state.storeSearchText ? `&filter_by[query]=${this.state.storeSearchText}` : "";

    const apiUrl =
      configJSON.getStoreListApi +
      "?dropdown=true&page_no=" +
      this.state.storeListPageNo + searchQuery;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreCallId = getDataMsg.messageId;
    this.storeListEmptyFlag = true;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleTabChange = (tabIndex: number) => {
    this.setState(
      {
        tabId: tabIndex,
        showCheckBoxAndButton: false,
        selectedDropdownvalue: null,
        selectAllChecked: false,
        page: 1,
        selectedOrders: [],
      },
      () => {
        this.getOrdersList();
      }
    );
  };

  handleDropDownSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    let temp = [...this.state.ordersList];
    temp = temp.map((tempValue: IOrderData) => ({
      ...tempValue,
      checked: false,
    }));

    let rewash;
    if (value === "rewash") {
      rewash = false;
    } else {
      rewash = true;
    }

    this.setState({
      showCheckBoxAndButton: rewash ? true : false,
      selectedDropdownvalue: value,
      ordersList: [...temp],
      selectAllChecked: false,
      selectedOrders: [],
    });
    value === "Send to driver" && this.getEmployeeList();
  };

  handleSelectAll = () => {
    let temp = [...this.state.ordersList];
    temp = temp.map((tempOrder: IOrderData) => ({
      ...tempOrder,
      checked: !this.state.selectAllChecked,
    }));
    this.setState({
      selectAllChecked: !this.state.selectAllChecked,
      ordersList: [...temp],
    });
  };

  handleCancel = () => {
    let temp = [...this.state.ordersList];
    temp = temp.map((tempOrder: IOrderData) => ({
      ...tempOrder,
      checked: false,
    }));
    this.setState({
      ordersList: [...temp],
      selectedDropdownvalue: "",
      showCheckBoxAndButton: false,
      selectAllChecked: false,
      selectedOrders: [],
      sendToDriverError: ""
    });
  };

  checkSelectDropdownValue = () => {
    const { selectedDropdownvalue } = this.state;
    if (selectedDropdownvalue === "Change status to clean") {
      return true;
    } else if (selectedDropdownvalue === "Received") {
      return true;
    } else if (selectedDropdownvalue === "Delivered") {
      return true;
    } else if (selectedDropdownvalue === "Send to dead stocks") {
      return true;
    } else if (selectedDropdownvalue === "rewash") {
      return true;
    }
    else if (selectedDropdownvalue === "ready" || selectedDropdownvalue === "in_store" 
    || selectedDropdownvalue === "delivered" || selectedDropdownvalue === "cleaning"
    || selectedDropdownvalue === "send_to_deadstock") {
      return true;
    } else {
      return false;
    }
  };

  renderButtonText = () => {
    const { selectedDropdownvalue } = this.state;
    if (selectedDropdownvalue === "rewash") {
      return "Rewash";
    } else {
      return "Change";
    }
  };

  handleSingleCheck = (value: string) => {
    const temp = [...this.state.ordersList];
    const indexValue = temp.findIndex((tempOrder: IOrderData) => tempOrder.id === value);
    temp[indexValue].checked = !temp[indexValue].checked;
    const isSelectAllCheckValue = temp.filter((tempOrder: IOrderData) => !tempOrder.checked);
    this.setState({ ordersList: [...temp] });
    if(isSelectAllCheckValue.length === 0){
      this.setState({ selectAllChecked: true });
    }else{
      this.setState({ selectAllChecked: false });
    }
  };

  handleSelectOrderToRewash = (value: string) => {
    const temp = [...this.state.ordersList];
    temp.forEach((tempOrder: IOrderData) => {
      if (tempOrder.id === value) {
        tempOrder.checked = !tempOrder.checked;
      } else {
        tempOrder.checked = false;
      }
    });
    this.setState({ ordersList: [...temp] });
  };

  handleIsActive = () => {
    this.setState({ popOverItemStatus: "Active" });
  };

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidth: width });
  };
  handleOrderDetail = (data: IOrderData) => {
    this.setState({
      orderDetailPopup: true,
      orderData: data,
    });
  };
  handleDateFilterParam = () => {
    
    const dateFilter = this.state. filters.find((item) => item?.title === 'Due')?.value;
    let startDate, endDate;
   
    let dateFilterQuery = '';

    if(dateFilter === 'specific'){
      const dateFilter = this.state.filters.find((item) => item?.title === 'Due')?.datevalue;
      if (dateFilter?.from && dateFilter?.to) {
        startDate = moment(dateFilter.from).format('DD-MM-YYYY');
        endDate = moment(dateFilter.to).format('DD-MM-YYYY');
      }
      dateFilterQuery = startDate && endDate 
      ? `&filter_by[cleaning_due]=specific_dates&start_date=${startDate}&end_date=${endDate}` 
      : '';
    }else{
      dateFilterQuery =  dateFilter ? `&filter_by[cleaning_due]=${dateFilter}` : '';
    }
    return {dateFilterQuery}
   
  };
  handlePaymentFilterParam = () => {
    let paymentQuery: string = "";
    const paymentFilter = this.state.filters.find(
      (item) => item.title === "Payment Method"
    );
    if (paymentFilter && paymentFilter.value && paymentFilter.value.length) {
      paymentQuery = (paymentFilter.value as string[]).reduce(
        (param: string, varc: string) => param + "&filter_by[payment_method][]=" + varc,
        ""
      );
    }
    return paymentQuery
  }
  handlePaymentStatusFilterParam = () => {
    let filterPStatus = this.state.filters.find(
      (item) => item.title === "Payment Status"
    )?.value;
    filterPStatus = filterPStatus ? `&filter_by[payment_status]=${filterPStatus}` : "";
    return filterPStatus
  }
  getOrdersList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    // customer number filters
    let filterCustomerNumber = this.state.customerNumberValue
      ? `&filter_by[customer_number]=${this.state.customerNumberValue}`
      : "";

    // customer filter
    let filterCustomer = this.state.filters.find(
      (item) => item.title === "Customer"
    )?.value;
    filterCustomer = filterCustomer
      ? `&filter_by[customer_name]=${filterCustomer}`
      : "";
    // service filter
    let filterService = this.state.filters.find(
      (item) => item.title === "Services"
    )?.value;
    filterService = filterService
      ? `&filter_by[service_name]=${filterService}`
      : "";

    // section filter
    let sectionQuery: string = "";
    const sectionFilter = this.state.filters.find(
      (item) => item.title === "Section"
    );
    if (sectionFilter && sectionFilter.value && sectionFilter.value.length) {
      sectionQuery = (sectionFilter.value as string[]).reduce(
        (param: string, varc: string) => param + "&filter_by[section_ids][]=" + varc,
        ""
      );
    }

    // order type filter
    let filterOrderType = this.state.filters.find(
      (item) => item.title === "Order Type"
    )?.value;
    filterOrderType = filterOrderType
      ? `&filter_by[order_type]=${filterOrderType}`
      : "";
    
    let paymentQueryParam = this.handlePaymentFilterParam()
   
    const { dateFilterQuery } = this.handleDateFilterParam()

    // status filter
    let filterStatus = this.state.filters.find(
      (item) => item.title === "Status"
    )?.value;
    filterStatus = filterStatus ? `&filter_by[status]=${filterStatus}` : "";

    let filterPStatus = this.handlePaymentStatusFilterParam()

    // tab change(status)
    let statusList = [
      "coming_to_store",
      "in_store",
      "cleaning",
      "ready",
      "delivered",
      "send_to_deadstock",
      "cancelled"
    ];
    let status = statusList[this.state.tabId];
    status = `&filter_by[status]=${status}`;
    const searchText = this.state.searchText
      ? `&filter_by[query]=${this.state.searchText}`
      : "";

    let apiEndPoint = this.getConditionBased(
      this.state.selectedTab == configJSON.carpetAndFurnitureTxt,
      configJSON.ordersPlantEndPoint,
      configJSON.newOrderListAPiEndPoint
    );

    const listEndPoint = `${apiEndPoint}?page_no=${this.state.page}&per_page=${
      this.state.pageSize
    }&store_id=${
      this.state.storeId ||
      this.state?.userData?.attributes?.employee_proifle?.data?.attributes
        ?.store_management_id
    }${sortStringCondition(this.state.searchResult,"",status)}${searchText}${
      this.state.query
    }${filterStatus}${sectionQuery}${paymentQueryParam}${filterPStatus}${dateFilterQuery}${filterService}${filterCustomer}${filterCustomerNumber}${this.getConditionBased(
      this.state.selectedTab == configJSON.carpetAndFurnitureTxt,
      "&order_type=PlantCleaningOrder",
      ""
    )}`;
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCallId = getDataMsg.messageId;

    this.setState({
      isLoadingOrderList: true
    })

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      listEndPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getSectionList() {
    const apiUrl = `${configJSON.getSectionListApiEndPoint
      }?token=${localStorage.getItem("token")}&dropdown=true`;

    const getAccount = apiCall({
      header: {},
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getSectionListApiMethod,
    });

    this.getSectionListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getSectionListApiCallResponse(apiRequestCallId: string, responseJson: {data: SectionListResponse[]}) {
    if (apiRequestCallId === this.getSectionListApiCallId) {
      // set date to state to avoid API call double times
      this.setState(() => ({
        sectionOptions: responseJson.data?.map(
          (item: {
            id: number;
            attributes: { section_name: string; active: boolean };
          }) => {
            return {
              id: item.id,
              label: item.attributes.section_name,
              active: item.attributes.active,
            };
          }
        ),
      }));

      // set section list for section options
      const seactionResults = responseJson.data?.map(
        (item: {
          id: number;
          attributes: { section_name: string; active: boolean };
        }) => {
          return {
            value: String(item.id),
            label: item.attributes.section_name,
          };
        }
      );
      const existingFilter = this.state.filters.find(
        (item) => item.title === "Section"
      );
      if (!existingFilter) return;
      existingFilter.options = seactionResults;
      this.setState({ filters: [...this.state.filters] });
    }
  };

  handlePaymentAllMethodResponse(
    apiRequestCallId: string,
    responseJson: { data: PaymentOptionResponse[] }
  ) {
    if (apiRequestCallId === this.getPaymentListApiCallId) {
      // set to state to avoid multiple API calls
      this.setState(() => ({
        paymentOptions: responseJson.data?.map(
          (item: PaymentOptionResponse) => {
            return {
              id: item.id,
              name: item.name,
              active: item.active,
            };
          }
        ),
      }));

      // set payment list for payment options
      const paymentResults = responseJson.data?.map(
        (item: PaymentOptionResponse) => {
          return {
            value: String(item.id),
            label: item.name,
          };
        }
      );
      const existingFilter = this.state.filters.find(
        (item) => item.title === "Payment Method"
      );
      if (!existingFilter) return;
      existingFilter.options = paymentResults;
      this.setState({ filters: [...this.state.filters] });
    }
  }
  
  getPaymentList() {
    const apiUrl = `${configJSON.getAllPaymentMethodEndPoint
      }?token=${localStorage.getItem("token")}&dropdown=true`;

    const getAccount = apiCall({
      header: {},
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getPaymentListApiMethod,
    });

    this.getPaymentListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  sendToDriverApiCallResponse(apiRequestCallId: string, responseJson: SendToDriverResponse) {
    if (this.sendToDriverMessageId === apiRequestCallId) {
      if (this.scannedOrderId) {
        this.handleReceiveMessageForScanner(responseJson)
        responseJson.status === 200 && this.getDriverReceipt(responseJson)
        return;
      }
      if (!responseJson.errors) {
        this.setState({ isButtonLoading: false, selectedDropdownvalue: "" });
        this.getDriverReceipt(responseJson);
        this.handleTabChange(1);
      } else {
        this.setState({ sendToDriverError: responseJson.errors,  isButtonLoading: false });
      }
    }
  }

  getDriverReceiptApiCallResponse = (apiRequestCallId: string, responseJson?: {latest_receipt?: string}) => {
    if (this.getDriverReceiptMessageId === apiRequestCallId) {
      this.setState({showDriverReceiptConfirmation: true, driverReceipt: toString(responseJson?.latest_receipt), useRawPrinting: false})
    }
  }

  updateStatusToCleanApiCallResponse(apiRequestCallId: string, responseJson: {errors: string}) {
    if (this.updateStatusToCleanMessageId === apiRequestCallId) {
      if (!responseJson.errors) {
        this.setState({ selectedDropdownvalue: "" });
        this.handleTabChange(2);
      } else {
        this.setState({ sendToDriverError: responseJson.errors });
      }
    }
  }

  handleEmployeeListApiCallResponse(apiRequestCallId: string, responseJson: {data: EmployeeListResponse[] }) {
    if (this.employeeListMessageId === apiRequestCallId) {
      if (responseJson) {
        this.setState({
          driverList: responseJson.data.map((item: {attributes: {id: string, first_name: string}}) => {
            const { id, first_name } = item.attributes;
            return {
              value: id,
              label: first_name,
              tabId: this.state.tabId
            };
          }),
        });
      }
    }
  }

  updateStatusToReceivedApiCallResponse(
    apiRequestCallId: string,
    responseJson: {errors: string}
  ) {
    if (apiRequestCallId === this.updateStatusToReceivedMessageId) {
      if (this.scannedOrderId) {
        this.handleReceiveMessageForScanner(responseJson)
        return;
      }
      if (!responseJson.errors) {
        this.setState({ selectedDropdownvalue: "" });
        this.handleTabChange(1);
        this.componentDidMount();
      } else {
        this.setState({ sendToDriverError: responseJson.errors });
      }
    }
  }

  updateStatusToDeliveredApiCallResponse(
    apiRequestCallId: string,
    responseJson: {errors: string}
  ) {
    if (apiRequestCallId === this.updateStatusToDeliveredMessageId) {
      if (this.scannedOrderId) {
        this.handleReceiveMessageForScanner(responseJson)
        return;
      }
      if (!responseJson.errors) {
        this.setState({ selectedDropdownvalue: "" });
        this.handleTabChange(this.state.tabId);
      } else {
        this.setState({ sendToDriverError: responseJson.errors });
      }
    }
  }
  handleTAbChangeForAnyStatus(){
    const {selectedDropdownvalue,tabId} = this.state
    switch (selectedDropdownvalue) {
      case 'in_store':
        this.handleTabChange(tabId);
        break;
      case 'cleaning':
        this.handleTabChange(tabId);
        break;
      case 'ready':
        this.handleTabChange(tabId);
        break;
      case 'send_to_deadstock':
        this.handleTabChange(tabId);
        break;
      case 'delivered':
        this.handleTabChange(tabId);
        break;
    
      default:
        break;
    }
    this.setState({ selectedDropdownvalue: "" });
  }
  updateStatusToAnyApiCallResponse(
    apiRequestCallId: string,
    responseJson: {errors: string}
  ) {
    if (apiRequestCallId === this.updateStatusToAnyMessageId) {
      if (this.scannedOrderId) {
        this.handleReceiveMessageForScanner(responseJson)
        return;
      }
      if (!responseJson.errors) {
        this.handleTAbChangeForAnyStatus()
      } else {
        this.setState({ sendToDriverError: responseJson.errors });
      }
    }
  }

  updateStatusToDeadStockApiCallResponse(
    apiRequestCallId: string,
    responseJson: {errors: string}
  ) {
    if (apiRequestCallId === this.updateStatusToDeadStocksMessageId) {
      if (!responseJson.errors) {
        this.setState({ selectedDropdownvalue: "" });
        this.handleTabChange(this.state.tabId);
      } else {
        this.setState({ sendToDriverError: responseJson.errors });
      }
    }
  }

  handlePageChange = (page: number) => {
    this.setState({ page }, () => this.getOrdersList());
  };

  handleEditClick = (rowOrderData: IOrderData) => {
    if (rowOrderData.attributes.is_quick_drop) {
      this.props.navigation.navigate("EditQuickDrop", {
        editId: rowOrderData.attributes.id,
      });
    } else {
      this.setState({ orderData: rowOrderData, orderDetailPopup: true });
    }
  };

  handleFilterTabChange() {
    let filterStatus = this.state.filters.find(
      (item) => item.title === "Status"
    )?.value;

    switch (filterStatus) {
      case "cleaning":
        this.handleTabChange(2);
        break;
      case "ready":
        this.handleTabChange(3);
        break;
    }
  }

  handleSearch = (value: string) => {
    this.setState(
      {
        page: 1,
        searchText: value,
        searchResult: sortCondition(Boolean(value),true,false) as boolean
      },
      this.getOrdersList
    );
  };

  handleFilterChange = (filters: IFilter[]) => {
    // set filter applied value set
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("order_management_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("order_management_filter_value");
    }

    this.setState(
      {
        filters,
        isAppliedFilter: checkIsFilterApplied(filters),
        page: 1,
      },
      () => {
        this.getOrdersList();
        this.handleFilterTabChange();
      }
    );
  };

  getServicesSuggestionFn = (query: string) => {
    let updated = this.state.filters.map((item: IFilter) => {
      if (item.title === "Services") item.value = query;
      return item;
    });

    this.setState({ filters: updated });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const apiUrl =
      configJSON.getCustomerNameDropdown + `?service_name=${query}`;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getServicesSuggestion = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getOrdersList());
  };

  sortingProps = {
    width: "",
    onQueryChange: (query: string) => this.handleQueryChange(`&${query}`),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  onClickSendToDriverSubmit(ordersToSend?: IOrderData[]) {
    let error = "";

    const { selectedDriver } = this.state;

    const selectedOrders = ordersToSend || this.state.selectedOrders

    if(!this.state.storeId) {
      error = "Please select a store"
    }
    this.setState({ sendToDriverError: error });
    if (error) { return; }

    if (!selectedOrders.length) {
      error = "Please select orders";
    } 

    this.setState({ sendToDriverError: error });

    if (error) {
      return;
    }

    if (!selectedDriver) {
      error = "Please select a driver";
    } 

    this.setState({ sendToDriverError: error, isScanningOrder: renderBooleanConditions(Boolean(this.scannedOrderId), !error, false) });

    if (error) {
      return;
    }
    
    this.setState({
      isButtonLoading: true
    });

      const order_ids = selectedOrders.map((item) => item.id);
      const requestMessage = MakeApiMessage({
        url: this.getConditionBased(
          this.state.selectedTab == configJSON.carpetAndFurnitureTxt,
          configJSON.homeCleaningOrderSendToDriverApi,
          configJSON.sendToDriverApi
        ) as string,
        method: configJSON.httpPostMethod,
        body: JSON.stringify({
          order_ids: order_ids,
          driver_account_id: selectedDriver,
          store_id: this.state.storeId,
        }),
      });
      this.sendToDriverMessageId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDriverReceipt = (sendToDriverApiResponse: SendToDriverResponse) => {
    if (sendToDriverApiResponse.use_raw_printing_receipt) {
      this.setState({
        showDriverReceiptConfirmation: true,
        driverReceipt: sendToDriverApiResponse.data || "",
        useRawPrinting: true
      })
      return;
    }
    const requestMessage = MakeApiMessage({
      url: `${configJSON.employeeApi}/${this.state.selectedDriver}/get_drivers_receipt?store_id=${this.state.storeId}`,
      method: configJSON.httpGetMethod,
    });
    this.getDriverReceiptMessageId = requestMessage.messageId;
    this.send(requestMessage)
  } 

  getUnpaidOrders() {
    return this.state.selectedOrders.filter(
      (item: IOrderData) => item.attributes.paid_at == null
    );
  }

  handleSendToClean = (temp: Array<IOrderData>) => {
    const requestMessage = MakeApiMessage({
      url: configJSON.updateStatusToClean,
      method: configJSON.httpPostMethod,
      body: JSON.stringify({
        order_ids: temp.map((object: IOrderData) => object.id),
      }),
    });
    this.updateStatusToCleanMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSendToRecieve = (temp: Array<IOrderData>) => {
    const paid_data = temp.filter(
      (item: IOrderData) => item.attributes.paid.length > 0
    );
    const requestMessage = MakeApiMessage({
      url: configJSON.updateStatusToReceived,
      method: configJSON.patchMethod,
      body: JSON.stringify({
        order_ids: paid_data.map((object: IOrderData) => object.id),
        store_id: this.state.storeId,
      }),
    });
    this.updateStatusToReceivedMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetDeliverUnpaid = (orderData:Array<IOrderData>) => {
    return orderData.filter(
      (item: IOrderData) => item.attributes.paid_at == null
    );
  }
  getOrderUnpaid(orderData:Array<IOrderData>) {
    
    let unpaidOrders = this.handleGetDeliverUnpaid(orderData);
    let order = unpaidOrders[0] || {};
    this.setState({ customerId: String(order.attributes.customer.id) });
    let message = MakeApiMessage({
      method: "GET",
      url: configJSON.getOrderApi + order.id,
    });
    this.getOrderMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }
  handleDeliverPaymentModalOpen = () => {
    const ordersLength = this.state.unpaidDialogDetail.length;

    if (ordersLength > 1) {
      this.setState({
        unPaidOrderError: configJSON.ListingPage.Strings.orderSinglePayment,
      });
      return;
    }

    this.setState({ paymentModalVisible: true, });
  };
  handleSendToDelivered = (temp: Array<IOrderData>) => {
    const is_unpaid_data = this.handleGetDeliverUnpaid(temp);

    if (is_unpaid_data.length > 0) {
      this.getOrderUnpaid(temp);
      this.setState({ unpaidDialogPopup: true,unpaidDialogDetail:is_unpaid_data });
    } else{
      const requestMessage = MakeApiMessage({
        url: configJSON.updateStatusToDelivered,
        method: configJSON.patchMethod,
        body: JSON.stringify({
          order_ids: temp.map((object: IOrderData) => object.id),
          store_id: this.state.storeId,
          status: "delivered",
        }),
      });
      this.updateStatusToDeliveredMessageId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleStatusChangetoAnyStatus = (temp: Array<IOrderData>, status: string) => {
    let url = configJSON.updateStatusToAnyStatus
    if(status === "ready" && this.state.tabId === 2){
      url = configJSON.updateStatusToAnyStatus + "?from_cleaning=true"
    }
      const requestMessage = MakeApiMessage({
        url: url,
        method: configJSON.httpPostMethod,
        body: JSON.stringify({
          order_ids: temp.map((object: IOrderData) => object.id),
          store_id: this.state.storeId,
          status: status,
        }),
      });
      this.updateStatusToAnyMessageId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSendToDeadStocks = (temp: Array<IOrderData>) => {
    const requestMessage = MakeApiMessage({
      url: configJSON.updateStatusToDelivered,
      method: configJSON.patchMethod,
      body: JSON.stringify({
        order_ids: temp.map((object: IOrderData) => object.id),
        store_id: this.state.storeId,
        status: "send_to_deadstock",
      }),
    });
    this.updateStatusToDeadStocksMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePlantCarpetFurnitureStatus = (
    temp: Array<IOrderData>,
    status: string
  ) => {
    const requestMessage = MakeApiMessage({
      url: configJSON.updatePlantStoreStatusEndpoint,
      method: configJSON.httpPutMethod,
      body: JSON.stringify({
        order_ids: temp.map((object: IOrderData) => object.id),
        store_id: this.state.storeId,
        status: status,
      }),
    });
    switch (status) {
      case "ready":
        this.updateStatusToCleanMessageId = requestMessage.messageId;
        break;
      case "delivered":
        this.updateStatusToDeliveredMessageId = requestMessage.messageId;
        break;
      case "send_to_deadstock":
        this.updateStatusToDeadStocksMessageId = requestMessage.messageId;
        break;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectedOrdersForSubmission = (orders?: IOrderData[]) => orders 
    || this.state.ordersList.filter(
      (order: IOrderData) => Boolean(order.checked)
    );

  onClickSendToCleanSubmit(orders?: IOrderData[]) {
    const { selectedDropdownvalue } = this.state;
    let error = "";
    let temp = this.selectedOrdersForSubmission(orders)

    if(!this.state.storeId && selectedDropdownvalue !== 'rewash') {
      error = "Please select a store"
    }

    this.setState({ sendToDriverError: error });

    if (error) {
      return;
    }

    if (!temp.length) {
      error = "Please select orders";
    }

    this.setState({ sendToDriverError: error });

    if (error) {
      return;
    }
    this.callStatusChangeAPI(selectedDropdownvalue, temp)
    switch (selectedDropdownvalue) {
      case "Change status to clean":
        if (this.state.selectedTab == configJSON.carpetAndFurnitureTxt) {
          this.updatePlantCarpetFurnitureStatus(temp, "ready");
        } else {
          this.handleSendToClean(temp);
        }
        break;
      case "Received":
        this.handleSendToRecieve(temp);
        break;
      case "Delivered":
        if (this.state.selectedTab == configJSON.carpetAndFurnitureTxt) {
          this.updatePlantCarpetFurnitureStatus(temp, "delivered");
        } else {
          this.handleSendToDelivered(temp);
        }
        break;
      case "Send to dead stocks":
        if (this.state.selectedTab == configJSON.carpetAndFurnitureTxt) {
          this.updatePlantCarpetFurnitureStatus(temp, "send_to_deadstock");
        }else {
          this.handleSendToDeadStocks(temp);
        }
        break;
      case "rewash":
        this.handleSendToRewash(temp);
        break;
    }
  }

  callStatusChangeAPI(selectedDropdownvalue: any, temp:Array<IOrderData>){
    if(selectedDropdownvalue === 'ready' || selectedDropdownvalue === 'in_store' ){
      this.handleStatusChangetoAnyStatus(temp,selectedDropdownvalue)
    }
    if(selectedDropdownvalue === 'delivered' || selectedDropdownvalue === 'cleaning'){
      this.handleStatusChangetoAnyStatus(temp,selectedDropdownvalue)
    }
    if(selectedDropdownvalue === 'send_to_deadstock'){
      this.handleStatusChangetoAnyStatus(temp,selectedDropdownvalue)
    }
  }

  getStatusOfOrder(status: string) {
    const { permissionStatus } = this.state;
    switch (status) {
      case "coming_to_store":
        return "Received";
      case "cleaning":
        return permissionStatus.cleanAction ? "Clean" : "";
      case "ready":
        return "Delivered";
      case "delivered":
        return permissionStatus.rewashAction ? "Rewash" : "";
      default:
        return "";
    }
  }

  handleCleaningStatus(order: IOrderData) {
    if (order.attributes.plant_status_display_name != null) {
      return order.attributes.plant_status_display_name;
    } else {
      return "Cleaning";
    }
  }

  showStatusOnOrder(order: IOrderData) {
    switch (order.attributes.internal_status) {
      case "coming_to_store":
        return "New";
      case "in_store":
        return "In Store";
      case "cleaning":
        return this.handleCleaningStatus(order);
      case "ready":
        return "Ready";
      case "send_to_deadstock":
        return "Deadstock";
      case "delivered":
        return "Delivered";
      case "cancelled":
        return "Cancelled";
      default:
        return "---";
    }
  }

  showEditOrderButton(order: IOrderData) {
    switch (order.attributes.internal_status) {
      case "coming_to_store":
        return true;
      case "in_store":
        return true;
      default:
        return false;
    }
  }

  handleStatusChange = (rowData: IOrderData) => {
    const temp = [];
    temp.push(rowData);

    switch (this.getStatusOfOrder(rowData.attributes?.internal_status)) {
      case "Received":
        this.handleSendToRecieve(temp);
        break;
      case "Clean":
        if (this.state.selectedTab == configJSON.carpetAndFurnitureTxt) {
          this.updatePlantCarpetFurnitureStatus(temp, "ready");
        } else {
          this.handleSendToClean(temp);
        }
        break;
      case "Delivered":
        if (this.state.selectedTab == configJSON.carpetAndFurnitureTxt) {
          this.updatePlantCarpetFurnitureStatus(temp, "delivered");
        } else {
          this.handleSendToDelivered(temp);
        }
        break;
      case "Rewash":
        this.handleSendToRewash(temp);
        break;
    }
  };
  
  onSelectOrder(data: IOrderData) {
    const { selectedOrders } = this.state;
    const hasOrderId = selectedOrders.filter(
      (item: IOrderData) => item.id == data.id
    );
    this.setState({
      selectedOrders:
        hasOrderId.length > 0
          ? selectedOrders.filter((item: IOrderData) => item.id != data.id)
          : [...selectedOrders, data],
    });
  }

  checkIsOrderSelect(data: IOrderData) {
    const { selectedOrders } = this.state;
    const hasOrderId = selectedOrders.filter(
      (item: IOrderData) => item.id == data.id
    );
    if (hasOrderId.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  closeUnpaidDialog = () => {
    this.setState({ unpaidDialogPopup: false });
  };


  hideUnpaidOrderError() {
    this.setState({ unPaidOrderError: "" });
  }

  handlePaymentModalClose = () => {
    this.setState({ paymentModalVisible: false });
  };

  getOrder() {
    let unpaidOrders = this.getUnpaidOrders();
    let order = unpaidOrders[0] || {};
    this.setState({ customerId: String(order.attributes.account_id) });
    let message = MakeApiMessage({
      method: "GET",
      url: configJSON.getOrderApi + order.id,
    });
    this.getOrderMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handlePaymentDone = () => {
    this.setState({ paymentClicked: true });
    this.getOrderUnpaid(this.state.unpaidDialogDetail);
  };

  onSelectAllSendToDriver(rows: Array<IOrderData>) {
    const hiddenOrders = rows.filter(
      (item: IOrderData) => item.attributes.is_quick_drop
    ).length;

    const isSelectedAll =
      rows.length - hiddenOrders === this.state.selectedOrders.length &&
      this.state.selectedOrders.length > 0;

    const notQuickDropOrderIds = rows.filter(
      (item: IOrderData) => !item?.attributes?.is_quick_drop
    );

    this.setState({
      selectedOrders: isSelectedAll ? [] : notQuickDropOrderIds,
    });
  }

  onCloseDetailsScreen() {
    this.scannedOrderId = ""
    this.setState({ orderDetailPopup: !this.state.orderDetailPopup });
  }

  handelStoreDropdownScroll = (event: React.SyntheticEvent) => {
    if (this.storeListEmptyFlag) return;
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1.3) {
      this.setState(
        (prev) => ({ storeListPageNo: prev.storeListPageNo + 1 }),
        () => {
          this.getStoreList();
        }
      );
    }
  };

  handleUserChange(userContext: IUserContext) {

    const apiKey = customPermissionApiKey.orderPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForOrderPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    }, () => this.setDropdownList());

    const roleValue = userContext.user?.attributes?.role?.name
    const { store_id, store_name } = userContext?.user?.attributes?.employee_proifle?.data?.attributes?.store_management || {};
    console.log("bbbb", store_id)
    this.setState({ 
      userData: userContext?.user as IMyUser,
      storeListPageNo: 1,
      storeId: toString(store_id),  
      storeName: toString(store_name)
     }, () => {
      this.getStoreList();
      if(roleValue === configJSON.storeKeeper){
        this.handleTabChange(1)
      }else{
        this.getOrdersList();
      }
    });
  }

  getConditionBased(flag: boolean, trueValue: unknown, falseValue: unknown) {
    return flag ? trueValue : falseValue;
  }

  checkTypeOfSell = () => {
    const { showCheckBoxAndButton, selectedDropdownvalue } = this.state;
    if (selectedDropdownvalue === "rewash" || showCheckBoxAndButton) {
      return "middle";
    } else {
      return "left";
    }
  };

  handleSendToRewash = (temp: Array<IOrderData>) => {
    const message = new Message(
      getCustomEnumName(CustomEnums.RewashModalOrderDataPass)
    );
    message.addData("ApiResponse", {
      temp: temp[0].attributes.id,
      handleTabChange: () =>  this.handleTabChange(this.state.tabId),
      typePlantCarpetFurnitureOrder: this.getConditionBased(
        this.state.selectedTab == configJSON.carpetAndFurnitureTxt,
        true,
        false
      ),
    });
    runEngine.sendMessage(message.id, message);

    navigateTo({
      props: this.props,
      screenName: "RewashOrderModal",
      raiseMessage: message,
    });
  };

  handleTabSeletion = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ selectedTab: newValue }, () => this.getOrdersList());
  };

  handleCallSectionListAPI() {
    if (this.state.sectionOptions.length === 0) {
      this.getSectionList();
    }
  }
  handleCallPaymentListAPI() {
    if (this.state.paymentOptions.length === 0) {
      this.getPaymentList();
    }
  }
  
  filterAutoCompleteSuggestionListByTitle = (title: string, value: string) => {
    if (title === "Services") {
        this.getServicesSuggestionFn(value);
    }
  }

  openPrinterDialog = (orderItem: IOrderData) => {
    this.setState({ orderToPrint: orderItem })
  }
  closePrinterDialog = () => {
    this.setState({ orderToPrint: null })
  }
  handleSubmitPrintDialog = async (selectedType: string) => {
    if (selectedType === configJSON.PrintDialog.printRawReceipt) {
      if (this.state.orderToPrint) {
        this.getRawInvoiceData(this.state.orderToPrint.id)
      }
      return;
    }
    if (selectedType === configJSON.PrintDialog.printReceipt) {
      if(this.state.orderToPrint) {
        if(this.state.orderToPrint?.attributes.invoice_receipt_pdf_url) {
          this.handlePrintReceipt(toString(this.state.orderToPrint.attributes.invoice_receipt_pdf_url))
        } else {
          this.handleReGenerateOrderInvoice(this.state.orderToPrint.attributes.id);
        }
      }
    } else {
      const apiMessage = MakeApiMessage({
        method: "GET",
        url: configJSON.getOrderItemsList + this.state.orderToPrint?.id
      })
      this.getOrderItemsMessageId = apiMessage.messageId
      this.send(apiMessage)
    }
  }

  handleGetOrderItemsResponse(
    apiRequestCallId: string,
    response: { data? :  Array<{attributes: {tag_image: Array<{label_string: string}>}}>}
 ) {
    if(apiRequestCallId === this.getOrderItemsMessageId) {
      const responseData = response?.data || []
      const tagImages = responseData.flatMap(item => item.attributes.tag_image)
      this.handlePrintTag(tagImages)
    }
  }
  
  clearFilterPopOver = () => {
    this.setState({
      customerNumberValue: "",
    });
  }

  openFilterPopover = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({
      filterAnchor: event.currentTarget,
    });
  }
  closeFilterPopover = () => {
    this.setState({ filterAnchor: undefined });
  }

  handleTableRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ pageSize: event.target.value as number, page: 1 }, () =>
      this.getOrdersList()
    );
  }
  handleDrawer = (open: boolean) => {
    this.setState({ isDrawerOpen: open })
  }
  handleStoreId = (value: { id: string; option: string }) => {
    this.setState({ storeId: value.id, storeName: value.option });
  }
  handleSelectedDriver = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedDriver: event.target.value as string})
  }
  closeAlertError = () => {
    this.setState({ sendToDriverError: "" })
  }

  handleWindowSize = () => {
    const width = window.innerWidth;
    switch (true) {
      case (width <= 1024):
        return 38
      case (width <= 1280):
        return 40
      case (width <= 1366):
        return 40
      default:
        return 44
    }
  }

  handleStatusScroll = (isDrawerOpen: boolean) => {
    const width = window.innerWidth;
    if (width <= 1024 || (width <= 1560 && isDrawerOpen)) {
        return 'scrollable';
    } else {
        return 'fullWidth';
    }
  }

  getColumnsDynamicWidth = () => {
    const screenWidth = window.innerWidth;
    if (this.state.showCheckBoxAndButton ||  this.state.selectedDropdownvalue === "rewash") {
      switch (true) {
        case screenWidth <= 1024:
          return "30px 100px 90px 100px 100px 130px 40px 60px 60px 90px 1fr";
        case screenWidth <= 1280:
          return "40px 140px 136px 125px 120px 150px 50px 80px 80px 100px 1fr";
        case screenWidth <= 1366:
          return "45px 120px 120px 130px 130px 170px 55px 90px 90px 120px 1fr";
        default:
          return "50px 140px 140px 145px 140px 180px 80px 90px 90px 130px 1fr";
      }

    } else {
      switch (true) {
        case screenWidth <= 1024:
          return "100px 90px 100px 100px 130px 40px 60px 60px 90px 1fr";
        case screenWidth <= 1280:
          return "140px 140px 140px 120px 150px 50px 80px 80px 100px 1fr";
        case screenWidth <= 1366:
          return "140px 130px 135px 130px 170px 70px 80px 80px 120px 1fr";
        default:
          return "140px 140px 145px 140px 180px 80px 90px 90px 130px 1fr";
      }
    }
  };

  handleRawPrint = (payload: InvoiceRawData) => {
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_RECEIPT")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), payload)
    this.send(printMessage)
    this.closePrinterDialog()
  }

  handlePrintReceipt = (receiptData: string | SalesPostedRawData) => {
    if (!receiptData) return this.setState({errorMessage : configJSON.NoReceiptMessage, orderToPrint: null})
    const {useRawPrinting} = this.state
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(
      getCustomEnumName(CustomEnums.CustomReducerAction), 
      sortStringCondition(useRawPrinting, "PRINT_SALES_POSTED", "PRINT_FILE")
    )
    printMessage.addData(
      getCustomEnumName(CustomEnums.CustomReducerPayload), 
      useRawPrinting ? receiptData : {
      format: "pdf",
      data: receiptData
     })
    this.send(printMessage)
    this.closePrinterDialog()
  }

  handlePrintTag = (tagImages: Array<{label_string?: string}> | undefined) => {
    const tags = tagImages?.map((image) => image.label_string) || []
    const tagsToPrint = tags.filter(Boolean)
    if (tagsToPrint.length) {
      const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
      printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_TAGS")
      printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), tagsToPrint)
      this.send(printMessage)
      this.closePrinterDialog()
    } else {
      this.setState({errorMessage: "No tags are available for this order."})
    }
  }

  closeCustomSnackbar = () => this.setState({errorMessage: "", snackbarMessage: "", snackBarSeverity: ""})

  handleSearchStore = (inputValue: string) => {
    if (inputValue === this.state.storeSearchText) return;
    this.setState({
      storeListPageNo: 1,
      storeSearchText: inputValue
    }, () => this.getStoreList())
  }

  debouncedStoreSearch = debounce(
    (newInputValue: string) => this.handleSearchStore(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleDriverReceiptDialog = (isContinue?: boolean) => 
    this.setState((prev) => ({
      showDriverReceiptConfirmation: false, 
      driverReceipt: isContinue ? prev.driverReceipt : ""
    }))
  
  handlePrintDriverReceipt = (selectedType: string) => {
    if (selectedType === configJSON.PrintDialog.printDriverReceipt) {
      this.handlePrintReceipt(this.state.driverReceipt)
      this.handleDriverReceiptDialog()
    }
  }

  handleDirectPaymentModalVisible = (rowData: IOrderData) => {
    this.getOrderDetail(rowData);
  }

  formatTaxPercentage = () => {
    const { selectedOrder } = this.state;

    if (!selectedOrder) return null;
    const decimalIndex = (selectedOrder.attributes as unknown as IOrder).tax_percentage.indexOf(".");
    const formattedTaxPercentage =
    (selectedOrder.attributes as unknown as IOrder).tax_percentage.slice(0, decimalIndex) + "%";
    return formattedTaxPercentage;
  };

  handleGetOrderDetailResponse(
    apiRequestCallId: string,
    response: { data : IOrderData }
 ) {
    if(apiRequestCallId === this.getOrderDetailMessageId) {
      if (response.data) {
        this.setState({
          selectedOrder: response.data,
          showDirectPaymentModal: true,
        });
        if (this.state.ispaymentClicked) {
          this.setState(
            {
              showDirectPaymentModal: false,
              ispaymentClicked: false,
              selectedOrder: null
            },
            () => { this.getOrdersList() }
          );
        }
      }
    }
  }
  getOrderDetail(rowData: IOrderData | null = null) {
    let order = rowData ? rowData : this.state.selectedOrder;
    this.setState({ customerId: String(order?.attributes.account_id) });

    let message = MakeApiMessage({
      method: "GET",
      url: configJSON.getOrderApi + order?.id,
    });
    this.getOrderDetailMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleSinglePaymentDone = () => {
    this.setState({ ispaymentClicked: true });
    this.getOrderDetail();
  };
  handleSinglePaymentClose = () => {
    this.setState({
      selectedOrder: null,
      showDirectPaymentModal: false
    })
  }

  getTableRowColumns(configType="row") {
    const screenWidth = window.innerWidth;    
    let mainBodyConfigs: Config[] = []

    if(configType === 'header') {
      mainBodyConfigs = [
      
        { maxWidth: 1024, config: "87px 96px 106px 96px 67px 88px 75px 80px 77px 120px" },
        { maxWidth: 1280, config: "105px 107px 123px 122px 125px 61px 110px 65px 112px 1px" },
        { maxWidth: 1366, config: "117px 117px 131px 131px 131px 70px 114px 80px 121px 8px" },
        { maxWidth: Infinity, config: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }
      ];
      if(this.state.showCheckBoxAndButton) {
        mainBodyConfigs = [
          { maxWidth: 1024, config: "62px 87px 96px 106px 96px 51px 78px 60px 62px 75px 120px"},
          { maxWidth: 1280, config: "60px 75px 109px 123px 122px 114px 61px 110px 65px 112px 1px" },
          { maxWidth: 1366, config: "60px 117px 117px 131px 131px 131px 70px 114px 80px 121px 8px" },
          { maxWidth: Infinity, config: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }
        ]
      }
    } else {
      mainBodyConfigs = [
      
        { maxWidth: 1024, config: "87px 96px 106px 96px 67px 88px 75px 80px 77px 120px" },
        { maxWidth: 1280, config: "105px 107px 123px 122px 102px 88px 104px 66px 114px 146px" },
        { maxWidth: 1366, config: "116px 116px 134px 131px 110px 94px 110px 81px 122px 143px" },
        { maxWidth: Infinity, config: "1fr 1fr 1fr 1fr 1fr 1.2fr 1fr 1fr 1fr 1fr" }
      ];
      if(this.state.showCheckBoxAndButton) {
        mainBodyConfigs = [
          { maxWidth: 1024, config: "62px 87px 96px 106px 96px 51px 78px 60px 62px 75px 120px"},
          { maxWidth: 1280, config: "56px 81px 107px 123px 124px 88px 88px 104px 66px 114px 146px" },
          { maxWidth: 1366, config: "60px 116px 116px 134px 131px 110px 94px 110px 81px 122px 133px" },
          { maxWidth: Infinity, config: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }
        ]
      }
    }    
    const getConfig = (configs: Config[]) => configs.find(cfg => screenWidth <= cfg.maxWidth)?.config;

    const mainBody = getConfig(mainBodyConfigs);
    return `${mainBody}`.trim();
  }

  handleUpdatePrinterSetting = () => this.setState({printerSetting: {...globalReceiptPrinterSetting}})

  showPrintButton = () => {
    const {tabId, printerSetting} = this.state
    const settingKey = TAB_SETTING_MAP[tabId]
    if (settingKey && !printerSetting?.[settingKey]) return false
    return true
  }

  toggleCreditNoteDialog = (item?: IOrderData) => {
    this.setState({
      creditNoteOrder: item,
      creditNotePresent: false,
      creditNoteReceipt: "",
      isCreditNoteDialogOpen: !this.state.isCreditNoteDialogOpen,
      hidden: true,
    });
    item && this.getCreditNoteDetailApi(item.id);
  };

  getCreditNoteDetailApi(orderId?: string) {
    this.setState({
      openCrediNoteModalLoader: true,
    });
    const message = MakeApiMessage({
      url: configJSON.getCreditNoteEndpoint + orderId,
      method: "GET",
    });
    this.getCreditNoteMessageId = message.messageId;
    this.send(message);
  }


  createCreditNoteApiCall() {
    const { addNote, creditNoteOrder } = this.state;
    this.setState({creditLoader:true})
    const message = MakeApiMessage({
      url: configJSON.createCreditNoteEndPoint,
      method: "POST",
      body: JSON.stringify({
        notes: addNote,
        order_id: creditNoteOrder?.id,
      }),
    });
    this.getCreateCreditNoteMessageId = message.messageId;
    this.send(message);
  }

  handleCreditNoteResponse(message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const creditNoteResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId == this.getCreateCreditNoteMessageId && creditNoteResponse) {
      if(creditNoteResponse?.errors?.length) {
        this.setState({
          creditLoader: false,
        })
        alert(creditNoteResponse.errors)
      } else {
        this.setState({
          snackbarMessage: "Credit note created successfully.",
          snackBarSeverity: "success",
          creditLoader:false,
          creditNoteOrder: undefined,
          isCreditNoteDialogOpen: false,
        });
        this.handleTabChange(6);
      }
    } else if (requestId == this.getCreditNoteMessageId && creditNoteResponse?.data) {
      const note = creditNoteResponse.data.attributes?.notes || "";
      this.setState({
        addNote: note,
        creditNoteOrderNumber: creditNoteResponse.data.attributes.order_number,
        creditNotePresent: true,
        creditNoteReceipt:  toString(creditNoteResponse.data.attributes.credit_note_receipt),
        openCrediNoteModalLoader: false
      });
    } 
    
    if (requestId == this.getCreditNoteMessageId && creditNoteResponse?.errors) {
      this.setState({ hidden: false, openCrediNoteModalLoader: false });
    }
  }


  hanldeClickPrintCreditNote = () => {
    const {creditNoteReceipt} = this.state
    if (creditNoteReceipt) {
      this.handlePrintReceipt(creditNoteReceipt)
    }
  }

  handleAddNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ addNote: event.target.value });
  };

  handleConfirmationCheckBox = () => {
    if (!this.state.creditNotePresent)
      this.setState({
        isConfirmationChecked: !this.state.isConfirmationChecked,
      });
  };

  getMomentFormat(dateStr: Date | string) {
    return moment(dateStr).format("DD/MM/YYYY");
  }

  handleReGenerateOrderInvoice = (order_id: number) => {
    let message = MakeApiMessage({
      url: configJSON.generateOrderInvoiceEndPoint + order_id,
      method: "GET",
    });
    this.generateOrderInvoiceCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleReGenerateInvoiceResponse = (
    apiRequestCallId: string,
    response: { status: number; invoice_receipt_pdf_url: string; order_id: number}
  ) => {
    if(apiRequestCallId === this.generateOrderInvoiceCallId) {
      if (response.status == 200) {
        if (response.invoice_receipt_pdf_url) {
          // print the receipt
          this.setState((prev) => ({ordersList: prev.ordersList.map(order => order.id === toString(response.order_id) ? 
            ({...order,
              attributes: {
                ...order.attributes,
                invoice_receipt_pdf_url: response.invoice_receipt_pdf_url
            }}) : order
          )}))
          this.handlePrintReceipt(response.invoice_receipt_pdf_url);
        } else {
          this.setState({ errorMessage: "Something wrong with priniting, Please try again" });
        }
      }
    }
  };

  getRawInvoiceData = (orderId: string) => {
    if (this.savedRawPrintDatasMap.has(orderId)) {
      const payload = this.savedRawPrintDatasMap.get(orderId)
      payload && this.handleRawPrint(payload)
      return;
    }
    const apiMessage = MakeApiMessage({
      method: "GET",
      url: configJSON.rawOrderInvoiceData + orderId
    })
    this.getRawOrderInvoiceDataCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  handleRawInvoiceDataResponse = (
    apiRequestCallId: string,
    response?: {data?: InvoiceRawData} 
  ) => {
    if(apiRequestCallId === this.getRawOrderInvoiceDataCallId) {
      if (response?.data) {
        if (this.savedRawPrintDatasMap.size > 50) {
          const firstInsertedKey = this.savedRawPrintDatasMap.keys().next().value
          this.savedRawPrintDatasMap.delete(firstInsertedKey)
        }
        this.savedRawPrintDatasMap.set(toString(response.data.order.order_id), response.data)
        this.handleRawPrint(response.data)
      } else {
        this.setState({errorMessage: "unable to fetch order data"})
      }
    }
  };

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const applied_order_management_filter = localStorage.getItem(
      "order_management_filter_value"
    );

    if (applied_order_management_filter) {
      const value = JSON.parse(applied_order_management_filter);

      let tempFilter = JSON.parse(JSON.stringify(value)) as IFilter[];

      const updateFilter = tempFilter.map((temp) => {
        if (temp.title == "Customer") {
          return {
            ...temp,
            onClear: () => {
              return this.clearCustomerFilter();
            },
            customRender: () => {
              return this.renderfilterCustomer();
            },
          };
        } else {
          return temp;
        }
      });

      this.setState(
        {
          filters: updateFilter,
          isAppliedFilter: checkIsFilterApplied(updateFilter),
        },
        () => this.handleTabChange(1)
      );
    } else {
      this.handleTabChange(1);
    }
  };

  handleSetStateFilter = (filters: IFilter[]) => {
    this.setState({ filters })
  };

  handleMomentDateFormat(date: string | Date |null, dateFormat: string) {
    const formatedDate = moment.parseZone(date).format(dateFormat);
    return formatedDate;
  }

  scanOrder = (orderNumber: string) => {
    if (this.state.isScanningOrder) return;
    if (
      this.state.orderDetailPopup
      && this.state.orderData?.id === this.scannedOrderId 
      && this.state.orderData?.attributes?.order_number === orderNumber
    ) {
      this.moveOrderToNext(this.state.orderData)
      return;
    }
    this.setState({isScanningOrder: true})
    const endPoint = configJSON.getOrderApi + `${encodeURIComponent(orderNumber)}?for_barcode=true&for_store=true`
    const message = MakeApiMessage({
      method: "GET",
      url: endPoint
    });
    this.scanOrderApiCallId = message.messageId
    this.send(message)
  }

  handleScanOrderResponse(requestId: string, response : {data?: IOrderData}) {
    if (requestId !== this.scanOrderApiCallId) return;
    const order = response?.data
    if (order && order?.attributes) {
      this.handleScanActions(order)
    }else {
      this.setState({errorMessage: "unable to find the order", isScanningOrder: false})
    }    
    
  }

  handleScanActions = (order: IOrderData) => {
    if (!order.attributes.order_items.length || order.attributes.is_quick_drop) {
      return this.setState({
        errorMessage: "This order does not have any item",
        isScanningOrder: false
      })
    }
    const {selectedDropdownvalue, tabId} = this.state
    if (selectedDropdownvalue) {
      const currentTab = TAB_STATUS_LIST[tabId]
      if (currentTab !== order.attributes.internal_status) {
        this.setState({
          errorMessage: "Cannot apply current action, the scanned order belongs to other stage",
          isScanningOrder: false 
        })
      } else {
        if (this.state.orderDetailPopup) {
          this.setState({
            orderDetailPopup: false,
            orderData: {} as IOrderData
          })
        }
        switch (selectedDropdownvalue) {
          case "Send to driver":
            this.scannedOrderId = order.id
            this.onClickSendToDriverSubmit([order])
            break;
          case "Run audits":
          case "View audits":
            const ordersList = this.getOrdersForAudits(order)
            this.setState({ordersList, isScanningOrder: false})
            break;
          default:
            this.scannedOrderId = order.id
            this.onClickSendToCleanSubmit([order])
            break;
        }
      }
    } else {
      this.setState({isScanningOrder: false})
      this.handleOpenScannedOrder(order)
    }
  }

  handleOpenScannedOrder = (order: IOrderData) => {
    if (this.state.permissionStatus.viewOrderPermission) {
      this.scannedOrderId = order.id
      this.handleOrderDetail(order)
    } else {
      this.setState({
        snackbarMessage: "You do not have permission to view this order",
        snackBarSeverity: "error"
      })
    }
  }

  handleReceiveMessageForScanner = (responseJson: {message?: string, status?: number, errors?: string}) => {
    const isSuccess = responseJson.status === 200
    this.setState((prev) => ({
      snackbarMessage : toString(responseJson.message || responseJson.errors),
      snackBarSeverity: sortStringCondition(
        isSuccess,
        "success",
        "error"
      ),
      ordersList: prev.ordersList.filter(item => item.id !== this.scannedOrderId),
      isScanningOrder: false,
      isButtonLoading: false,
      orderDetailPopup: isSuccess ? false : prev.orderDetailPopup,
      orderData: isSuccess ? ({} as IOrderData) : prev.orderData,
    }), () => {
      this.scannedOrderId = ""
    })
  }

  getOrdersForAudits = (newSelectedOrder: IOrderData) => {
    const orderIndex = this.state.ordersList.findIndex(item => item.id === newSelectedOrder.id)
    const ordersList = orderIndex > -1 ? this.state.ordersList.map(
      (item, index) => index === orderIndex ? ({ ...item, checked: true }) : item
    ) : [{ ...newSelectedOrder, checked: true }, ...this.state.ordersList]
    return ordersList
  }

  moveOrderToNext = (order: IOrderData) => {
    const {internal_status} = order.attributes
    const errorMessage = this.checkMoveToNextPermissionMessage(internal_status)
    if (errorMessage) {
      return this.setState({errorMessage, isScanningOrder: false})
    }
    const nextStatus = {
      cleaning: "ready",
      delivered: "send_to_deadstock"
    }
    switch (internal_status) {
      case "coming_to_store":
        this.handleSendToRecieve(
          [order]
        )
        break;
      case "in_store":
        break;
      case "cleaning":
      case "delivered":
        this.handleStatusChangetoAnyStatus([order],nextStatus[internal_status])
        break;
      case "ready":
        this.handleSendToDelivered([order])
        break;
      default:
        break;
    }
  }

  checkMoveToNextPermissionMessage = (internal_status: string) => {
    const {permissionStatus} = this.state
    let errorMessage = "You do not have permission to move this order to next stage"
    switch(internal_status) {
      case "in_store":
        errorMessage = "For in store order, please use Send to Driver action to move order to next stage"
        break;
      case "coming_to_store":
      case "ready":
        errorMessage = ""
        break;
      case "cleaning":
        if (permissionStatus.cleanAction) {
          errorMessage = ""
        }
        break;
      case "delivered":
        if (permissionStatus.sendToDeadStockAction) {
          errorMessage = ""
        }
        break;
      default:
        errorMessage = "This order cannot be moved"
        break;
    }
    return errorMessage
  }
  // Customizable Area End
}
