import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import {
  BackgroundImage,
  BackgroundPaper,
  AvatarImg,
}
  from "../../../components/src/customComponents/DynamicContent.web";
import { backgroundImageLogin } from "../../email-account-login/src/assets"
import { emailIcon, logo } from "./assets";
export const configJSON = require("./config");
import CustomComponentLanguage from "../../../components/src/customComponents/CustomComponentLanguage.web"


import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";


export default class EmailSucess extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <BackgroundImage imageUrl={backgroundImageLogin}>
        <BackgroundPaper>
        <CustomComponentLanguage navigation={undefined} id={""} />
          <Box style={webStyle.avatarTop}>
            <Box 
              display="flex" 
              justifyContent="center" 
              paddingTop="0.2vw" 
              paddingBottom="1vw"
            >
              <img src={logo} width="33%"/>
            </Box>
            <Box style={{ flexDirection: "column", ...webStyle.iconStyle }}>
              <Box style={{ paddingTop: '7vw', textAlign: 'center' }}>
                <img src={emailIcon} style={webStyle.programAvatarStyle} />
                <Typography style={webStyle.heading}>{configJSON.emailheading}</Typography>
                <Typography style={webStyle.Subheading}>{configJSON.emailsubheading}</Typography>
              </Box>
            </Box>
          </Box>
        </BackgroundPaper>

      </BackgroundImage>
    );
  }
}
const webStyle = {
  programAvatarStyle: {
    width: '4.861vw',
    height: '4.861vw',
  },
  avatarTop:{
     paddingTop: '5vw' 
  },
  heading: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "1.250vw",
    marginTop:'0.3vw'
  },
  Subheading: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "0.903vw",
    paddingTop: '0.667vw',
    paddingBottom: '4.111vw'
  },
  iconStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  emailpagepadding: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}
 // Customizable Area End