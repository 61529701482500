// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { apiCall,handleLogout } from "../../../components/src/common";
import {
  checkIsFilterApplied,
  navigateTo,
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  checkForImportExportPermissionStatus,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export const configJSON = require("./config");

export interface IBusinessCustomer {
  id: number;
  business_customer: string;
}

export interface IPriceListAttributes {
  id: number;
    name: string;
    price_list_type: string;
    product_currency_id: number | null;
    created_at: string;
    updated_at: string;
    tax_type: string;
    activated: boolean;
    price_list_to_copy: string;
    type: string;
    company_id: number;
    region_ids: number[];
    area_ids: number[];
    store_management_ids: number[];
    business_account_ids: number[];
    model_name: string;
    company_name: string;
    business_accounts:IBusinessCustomer[];
    is_master_price_list: boolean;
    sections: Record<string, unknown>;
    products: unknown[];
}

export interface IPriceList {
  id: string;
  type: string;
  attributes: IPriceListAttributes;
}
interface IMetaData {
  "total_pages": number,
  "total_count": number,
  "current_page": number,
  "next_page": number| null,
  "pervious_page": number | null
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  priceList: IPriceList[];
  priceListPagination: IMetaData;
  sortingData: ISortingData;
  isAppliedFilter: boolean;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  page: number;
  pageSize: number;
  isB2bActive: boolean
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  query: string;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string | boolean;
  popOverTop: number;
  popOverLeft: number;
  rowData: IPriceList;
  pricelistrQuery: string;
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean;
  openDeactiveModel: boolean;
  confirmDeactiveModalOpen: boolean;
  snackBarOpen: boolean;
  snackBarMessage: string;
  severity: "warning" | "error" | "success";
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BPriceListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  authToken: string = "";
  getPriceListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      priceList: [],
      sortingData: {
        name: "",
        company: "",
        business_account: "",
        activated: "",
        no_of_products: "",
        price_list_to_copy: "",
      },
      isAppliedFilter: false,
      filterAnchor: undefined,
      filters: [{
          title: "Name",
          type: "autocompolete",
          apiKey: "organization",
          options: [],
           value: ""
          }
      ],
      priceListPagination: {
        "total_pages": 0,
        "total_count": 0,
        "current_page": 0,
        "next_page": 0,
        "pervious_page": null
      },
      page: 0,
      pageSize: 10,
      isB2bActive: false,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      query: "",
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      popOverItemId: "",
      popOverItemStatus: "",
      rowData: {
        id: "0",
        type: "price_list",
        attributes: {
          id: 0,
          name: "",
          price_list_type: "",
          product_currency_id: null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          tax_type: "",
          activated: false,
          price_list_to_copy: "",
          type: "",
          company_id: 0,
          region_ids: [],
          area_ids: [],
          store_management_ids: [],
          business_account_ids: [],
          model_name: "",
          company_name: "",
          business_accounts: [],
          is_master_price_list: false,
          sections: {},
          products: [],
        }
      },
      pricelistrQuery: "",
      permissionStatus: {
        mainPermission: true,
        createPermission: true,
        viewPermission: true,
        editPermission: true,
        deactivatePermission: true,
        activatePermission: true,
      },
      isLoadingPermission: true,
      openDeactiveModel: false,
      confirmDeactiveModalOpen: false,
      snackBarOpen: false,
      snackBarMessage: "",
      severity: "error",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.receiveHeaerSearchText(message);
    this.receiveDataFromLayout(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.getPriceListApiCallResponse(apiRequestCallId, responseJson);
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.handleStorageFilter();
    // Customizable Area End
  }
  // Customizable Area Start

  receiveHeaerSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.onChangeValue(recievedData.searchText)
        }
    }
  }

  onChangeValue = (value: string) => {
    this.setState({pricelistrQuery: value,page: 0},()=>this.getB2BPriceList())
  }

  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  checkOpacity(activated: string | boolean) {
    if(activated) {
      return 1
    }
    return 0.6
  }

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getB2BPriceList());
  };

  handlePopoverMenuClose = () => {
    this.setState({ popOverOpened: false })
  }

  handleMoreMenu = (item: IPriceList, position: DOMRect) => {
    this.setState({
      popOverOpened: true,
      popOverItemId: item.id,
      popOverItemStatus: item.attributes.activated,
      popOverLeft: position.left + window.scrollX,
      popOverTop: position.top + window.scrollY,
      rowData: item
    });
  }

  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }

  handleFilterChangeAccount = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("b2b_customer_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("b2b_customer_filter_value");
    };

    this.setState({
      filters,
      isAppliedFilter: checkIsFilterApplied(filters),
      page: 0
    },
    this.getB2BPriceList
);
  };

  handleFilterClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }

  handleClearFilter = () => {
    let updated = this.state.filters.map((item: IFilter) => {
      item.value = "";
      item.options = [];
      return item;
    });
    this.setState({ filters: updated });
  }

  
  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    this.getB2BPriceList();
  };

  handleNavigateAddCustomer = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BPriceListCreation",
    })
  }

  handlePageChange = (page: number) => {
    this.setState({ page }, () => { this.getB2BPriceList() })
  }

  getB2BPriceList = () => {
    this.setState({ isLoading: true });

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url;

    url = configJSON.B2BPriceListAPIEndpoint +
      (`?page_no=${this.state.page + 1}&per_page=${this.state.pageSize}`) +
      (this.state.pricelistrQuery ? `&filter_by[query]=${this.state.pricelistrQuery}` : '');

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getPriceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPriceListApiCallResponse(apiRequestCallId: string, responseJson: { data: IPriceList[], status: number,meta: IMetaData, message?: string, errors?: { message: string } }) {
    if (apiRequestCallId === this.getPriceListApiCallId) {
      if(responseJson?.status == 500) {
        this.setState({
          isLoading: false,
          errorSnackbarOpen: true,
          errorMessage: "Internal server error",
          snakcbarSeverity: 'error'
        })
      } 
      else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
        this.setState({ isLoading: false, priceList: responseJson.data , priceListPagination: responseJson.meta});
      } else {
        this.setState({ isLoading: false })
      }
      }
    }
  }

  handleViewCustomerDetails = (getCustomerId: string | number) => {
    navigateTo({
      id: String(getCustomerId),
      props: this.props,
      screenName: "B2BPriceList",
    });
  }

  handleEditCustomerDetails = (getCustomerId: string | number) => {
    navigateTo({
      id: String(getCustomerId),
      props: this.props,
      screenName: "B2BPriceListEdit",
    });
  };

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext);
      }
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const userData = userContext.user?.attributes.permission_groups;
    const apiKey = customPermissionApiKey.b2BPriceList;
    const permissionVal = checkForNewPermissonStatus(
      apiKey,
      userData as Array<PermissionGroupArray>
    );

    const apiKeyImportExport =
      customPermissionApiKey.dataImportExportPermission;
    const valueImportExportPermission = checkForImportExportPermissionStatus(
      apiKeyImportExport,
      userData as Array<PermissionGroupArray>
    );
    this.setState({
      permissionStatus: {
        ...permissionVal,
        exportPermission: valueImportExportPermission.exportPermission,
      },
      isLoadingPermission: false,
    });
  };


  handleCloseConfirmModal = () => {
    this.setState({ openDeactiveModel: false });
  };


  handleSnackbarClose = () => {
    this.setState({ snackBarOpen: false, snackBarMessage: "" });
  };
  // Customizable Area End
}